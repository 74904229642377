// free routes
export const LANDING = '/';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const SEARCH = '/search';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESTORE_PASSWORD = '/restore-password/:token';
export const CREATE_PASSWORD = '/create-password/:token';
export const CONFIRM_EMAIL = '/confirm-email/:id';
export const PROFILE_DOCTORS = '/profile-doctors';
export const PROFILE_DOCTORS_ID = '/profile-doctors/:id';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const CONTACT_US = '/contact-us';
export const GUIDE = '/guide';

// doctor protected routes
export const DOCTOR_REGISTRATION = '/doctor-registration';
export const DOCTOR_DASHBOARD = '/doctor';
export const DOCTOR_CREATE_APPOINTMENTS = '/doctor-create-appointments';
export const DOCTOR_CREATE_SCHEDULE = '/doctor-create-schedule';
export const DOCTOR_CREATE_PATIENT = '/doctor-create-patient';
export const DOCTOR_MY_PATIENTS = '/doctor-my-patients';
export const DOCTOR_PATIENT_PROFILE = '/doctor-patient-profile';
export const DOCTOR_PATIENT_PROFILE_ID = '/doctor-patient-profile/:id';
export const DOCTOR_CREATE_PRESCRIPTION = '/doctor-create-prescription';
export const DOCTOR_CREATE_PRESCRIPTION_ID = '/doctor-create-prescription/:id';
export const DOCTOR_CREATE_INVOICE = '/doctor-create-invoice';
export const DOCTOR_BIG_PLANER = '/doctor-big-planer';

// patient protected routes
export const PATIENT_REGISTRATION = '/patient-registration';
export const PATIENT_DASHBOARD = '/patient';
export const PATIENT_REQUEST = '/patient-request';
export const PATIENT_REQUEST_ID = '/patient-request/:id';
export const PATIENT_MY_DOCTORS = '/patient-my-doctors';
export const PATIENT_LAB_RESULTS = '/patient-lab-result';

// doctor and patient protected routes
export const VIDEO_CALL = '/video-call';
export const VIDEO_CALL_ID = '/video-call/:appointmentId';
export const CHAT = '/chat';

export const SETTING = '/setting';
export const PRESCRIPTIONS = '/prescriptions';
export const PRESCRIPTIONS_ID = '/prescriptions/:id';
export const PRESCRIPTION_DETAILS = '/prescription-details';
export const PRESCRIPTION_DETAILS_ID = '/prescription-details/:id';
export const UPDATES_FEED = '/updates-feed';
export const UPDATES_FEED_ID = '/updates-feed/:id';
export const CREATE_LAB_RESULT = '/create-lab-result';
export const INVOICES = '/invoices';
export const INVOICE_DETAILS = '/invoice-details';
export const INVOICE_DETAILS_ID = '/invoice-details/:id';
