import React from 'react';
import { Link } from 'react-router-dom';
import style from './navigation.module.css';
import { Button } from '@material-ui/core';
import { SIGN_UP } from './../../constants/locationPath';

export const Navigation = () => {
  return (
    <nav className={style.nav}>
      <ul className={style.list}>
        <li className={style.linkItem}>
          <Link to={SIGN_UP} className={style.link}>
            Sign Up
          </Link>
        </li>
        <li className={style.linkItem}>
          <Button className={style.button} variant="contained" color="primary" href="#signIn">
            Sign In
          </Button>
        </li>
      </ul>
    </nav>
  );
};
