import { createSlice } from '@reduxjs/toolkit';

const toolkitSlice = createSlice({
  name: 'loader',
  initialState: {
    loader: false,
  },
  reducers: {
    startLoading(state) {
      state.loader = true;
    },
    endLoading(state) {
      state.loader = false;
    },
  },
});

export default toolkitSlice.reducer;
export const { startLoading, endLoading } = toolkitSlice.actions;
