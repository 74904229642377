import React, { useEffect } from 'react';
import { useAuth } from './hooks/authHook';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useTheme from './theme/theme';
import { TransitionsModal } from './components/modal/modal';
import Main from './layouts/main/main';
import {
  LANDING,
  SIGN_IN,
  SIGN_UP,
  SEARCH,
  FORGOT_PASSWORD,
  RESTORE_PASSWORD,
  CREATE_PASSWORD,
  DOCTOR_REGISTRATION,
  DOCTOR_DASHBOARD,
  CONFIRM_EMAIL,
  SETTING,
  VIDEO_CALL_ID,
  DOCTOR_CREATE_APPOINTMENTS,
  DOCTOR_CREATE_SCHEDULE,
  PATIENT_DASHBOARD,
  PATIENT_REGISTRATION,
  DOCTOR_CREATE_PATIENT,
  DOCTOR_MY_PATIENTS,
  PRESCRIPTIONS_ID,
  DOCTOR_PATIENT_PROFILE_ID,
  DOCTOR_CREATE_PRESCRIPTION_ID,
  PRESCRIPTION_DETAILS_ID,
  PROFILE_DOCTORS_ID,
  PATIENT_MY_DOCTORS,
  PATIENT_REQUEST_ID,
  UPDATES_FEED_ID,
  CREATE_LAB_RESULT,
  CHAT,
  DOCTOR_CREATE_INVOICE,
  INVOICE_DETAILS_ID,
  INVOICES,
  DOCTOR_BIG_PLANER,
  PATIENT_LAB_RESULTS,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  CONTACT_US,
  GUIDE,
} from './constants/locationPath';
import { DOCTOR_ROLE, PATIENT_ROLE } from './constants/userRole';
import LandingPage from './pages/landingPage/landingPage';
import { SignInPage } from './pages/signInPage/signInPage';
import { SignUpPage } from './pages/signUpPage/signUpPage';
import SearchPage from './pages/searchPage/searchPage';
import { ForgotPasswordPage } from './pages/forgotPasswordPage/forgotPasswordPage';
import { RestorePasswordPage } from './pages/restorePasswordPage/restorePasswordPage';
import { NotFoundPage } from './pages/notFoundPage/notFoundPage';
import ProtectedPage from './pages/protectedPage';
import DoctorRegistrationPage from './pages/doctorRegistrationPage/doctorRegistrationPage';
import DoctorDashboardPage from './pages/doctorDashboardPage/doctorDashboardPage';
import SettingPage from './pages/settingPage/settingPage';
import { ConfirmEmailPage } from './pages/confirmEmailPage/confirmEmailPage';
import DoctorCreateSchedulePage from './pages/doctorCreateSchedulePage/doctorCreateSchedulePage';
import DoctorCreateAppointmentsPage from './pages/doctorCreateAppointmentsPage/doctorCreateAppointmentsPage';
import instance from './API/configAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './store/modalSlice';
import { startLoading, endLoading } from './store/loaderSlice';
import VideoCall from './pages/videoCall';
import PatientRegistrationPage from './pages/patientRegistrationPage/patientRegistrationPage';
import PatientDashboardPage from './pages/patientDashboardPage/patientDashboardPage';
import DoctorCreatePatientPage from './pages/doctorCreatePatientPage/doctorCreatePatientPage';
import DoctorMyPatientsPage from './pages/doctorMyPatientsPage/doctorMyPatientsPage';
import PrescriptionsPage from './pages/prescriptionsPage/prescriptionsPage';
import DoctorPatientProfilePage from './pages/doctorPatientProfilePage/doctorPatientProfilePage';
import DoctorCreatePrescriptionPage from './pages/doctorCreatePrescriptionPage/doctorCreatePrescriptionPage';
import PrescriptionDetailsPage from './pages/prescriptionDetailsPage/prescriptionDetailsPage';
import ProfileDoctorsPage from './pages/profileDoctorsPage/profileDoctorsPage';
import ConversationsApp from './components/chat';
import PatientMyDoctorsPage from './pages/patientMyDoctorsPage/patientMyDoctorsPage';
import PatientCreateRequestPage from './pages/patientCreateRequestPage/patientCreateRequestPage';
import UpdatesFeedPage from './pages/updatesFeedPage/updatesFeedPage';
import CreateLabResultPage from './pages/createLabResultPage/createLabResultPage';
import DoctorCreateInvoicePage from './pages/doctorCreateInvoicePage/doctorCreateInvoicePage';
import InvoiceDetailsPage from './pages/invoiceDetailsPage/invoiceDetailsPage';
import InvoicesPage from './pages/invoicesPage/invoicesPage';
import DoctorBigPlanerPage from './pages/doctorBigPlannerPage/doctorBigPlanerPage';
import PatientLabResultPage from './pages/patientLabResultPage/patientLabResultPage';
import PrivacyPolicyPage from './pages/privacyPolicyPage/privacyPolicyPage';
import TermsAndConditionsPage from './pages/termsAndConditionsPage/termsAndConditionsPage';
import ContactUsPage from './pages/contactUs/contactUsPage';
import GuidePage from './pages/guidePage/guidePage';

const App = () => {
  const theme = useTheme('light')();
  const { login, logout, storageName } = useAuth();

  const REDIRECT_TO_SIGN_IN = '[App] redirect to sign in';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);

  const data = JSON.parse(localStorage.getItem(storageName));

  if (data && data.token) {
    login(data.token, data.user);
  }

  useEffect(() => {
    if (answerModal === REDIRECT_TO_SIGN_IN) {
      dispatch(setModalAnswer(''));
      window.location = SIGN_IN;
    }
  }, [answerModal]);

  instance.interceptors.request.use(
    (config) => {
      dispatch(startLoading());

      return config;
    },
    (error) => {
      dispatch(endLoading());

      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => {
      dispatch(endLoading());

      return response;
    },
    (error) => {
      dispatch(endLoading());

      if (error && error.response && error.response.data) {
        const { message, statusCode } = error.response.data;

        if (message === 'Card not found') {
          return { data: { id: null } };
        }

        if (message === 'FileItem not found') {
          return { data: null };
        }

        const contentModal = {
          isClosedByOverlay: true,
          title: '',
          description: message || 'Something went wrong',
          actions: [
            {
              title: statusCode === 401 ? 'go sign in' : 'ok',
              answerModal: statusCode === 401 ? REDIRECT_TO_SIGN_IN : '',
              variant: 'contained',
              color: 'primary',
            },
          ],
        };

        if (statusCode === 401) {
          logout();
        }

        dispatch(setModalContent(contentModal));
      }
      return Promise.reject(error);
    },
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        <Router>
          <Switch>
            <Route exact path={LANDING}>
              <Main>
                <LandingPage />
              </Main>
            </Route>

            <Route exact path={SIGN_IN}>
              <Main>
                <SignInPage />
              </Main>
            </Route>

            <Route exact path={SIGN_UP}>
              <Main>
                <SignUpPage />
              </Main>
            </Route>

            <Route exact path={SEARCH}>
              <Main>
                <SearchPage />
              </Main>
            </Route>

            <Route exact path={FORGOT_PASSWORD}>
              <Main>
                <ForgotPasswordPage />
              </Main>
            </Route>

            <Route path={RESTORE_PASSWORD}>
              <Main>
                <RestorePasswordPage />
              </Main>
            </Route>

            <Route path={CREATE_PASSWORD}>
              <Main>
                <RestorePasswordPage isCreatePassword />
              </Main>
            </Route>

            <Route path={CONFIRM_EMAIL}>
              <Main>
                <ConfirmEmailPage />
              </Main>
            </Route>

            <Route path={PROFILE_DOCTORS_ID}>
              <Main>
                <ProfileDoctorsPage />
              </Main>
            </Route>

            <Route exact path={PRIVACY_POLICY}>
              <Main>
                <PrivacyPolicyPage />
              </Main>
            </Route>

            <Route exact path={TERMS_AND_CONDITIONS}>
              <Main>
                <TermsAndConditionsPage />
              </Main>
            </Route>

            <Route exact path={CONTACT_US}>
              <Main>
                <ContactUsPage />
              </Main>
            </Route>

            <Route exact path={GUIDE}>
              <Main>
                <GuidePage />
              </Main>
            </Route>

            {/* Doctor protected route */}
            <ProtectedPage exact path={DOCTOR_DASHBOARD} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorDashboardPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={DOCTOR_REGISTRATION} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorRegistrationPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={DOCTOR_CREATE_SCHEDULE} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorCreateSchedulePage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={DOCTOR_CREATE_APPOINTMENTS} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorCreateAppointmentsPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={DOCTOR_CREATE_PATIENT} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorCreatePatientPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={DOCTOR_MY_PATIENTS} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorMyPatientsPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage path={DOCTOR_PATIENT_PROFILE_ID} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorPatientProfilePage />
              </Main>
            </ProtectedPage>

            <ProtectedPage path={DOCTOR_CREATE_PRESCRIPTION_ID} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorCreatePrescriptionPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={DOCTOR_CREATE_INVOICE} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorCreateInvoicePage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={DOCTOR_BIG_PLANER} accessFor={[DOCTOR_ROLE]}>
              <Main>
                <DoctorBigPlanerPage />
              </Main>
            </ProtectedPage>

            {/* Patient protected route */}
            <ProtectedPage exact path={PATIENT_DASHBOARD} accessFor={[PATIENT_ROLE]}>
              <Main>
                <PatientDashboardPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={PATIENT_REGISTRATION} accessFor={[PATIENT_ROLE]}>
              <Main>
                <PatientRegistrationPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={PATIENT_MY_DOCTORS} accessFor={[PATIENT_ROLE]}>
              <Main>
                <PatientMyDoctorsPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage path={PATIENT_REQUEST_ID} accessFor={[PATIENT_ROLE]}>
              <Main>
                <PatientCreateRequestPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={PATIENT_LAB_RESULTS} accessFor={[PATIENT_ROLE]}>
              <Main>
                <PatientLabResultPage />
              </Main>
            </ProtectedPage>

            {/* Doctor and Patient protected route  */}
            <ProtectedPage path={CHAT} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <ConversationsApp />
            </ProtectedPage>

            <ProtectedPage path={VIDEO_CALL_ID} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <VideoCall />
            </ProtectedPage>

            <ProtectedPage exact path={SETTING} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <Main>
                <SettingPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage path={PRESCRIPTIONS_ID} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <Main>
                <PrescriptionsPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage path={PRESCRIPTION_DETAILS_ID} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <Main>
                <PrescriptionDetailsPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage path={UPDATES_FEED_ID} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <Main>
                <UpdatesFeedPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={CREATE_LAB_RESULT} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <Main>
                <CreateLabResultPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage path={INVOICE_DETAILS_ID} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <Main>
                <InvoiceDetailsPage />
              </Main>
            </ProtectedPage>

            <ProtectedPage exact path={INVOICES} accessFor={[DOCTOR_ROLE, PATIENT_ROLE]}>
              <Main>
                <InvoicesPage />
              </Main>
            </ProtectedPage>

            <Route path="**">
              <Main>
                <NotFoundPage />
              </Main>
            </Route>
          </Switch>
        </Router>
        <TransitionsModal />
      </>
    </ThemeProvider>
  );
};

export default App;
