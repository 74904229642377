import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from './../store/authSlice';
import instanceAxios from './../API/configAPI';

export const useAuth = () => {
  const storageName = 'Bearer';
  const dispatch = useDispatch();

  const login = useCallback((token, user) => {
    dispatch(setToken(token));
    dispatch(setUser(user));

    instanceAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    localStorage.setItem(
      storageName,
      JSON.stringify({
        token,
        user,
      }),
    );
  }, []);

  const logout = useCallback(() => {
    dispatch(setToken(null));
    dispatch(setUser(null));

    instanceAxios.defaults.headers.common['Authorization'] = null;

    localStorage.removeItem(storageName);
  }, []);

  return { login, logout, storageName };
};
