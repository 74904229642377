import React from 'react';

export default function MicIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#1B2A4A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.1626 19.3313C30.324 18.4789 29.1865 18 28.0004 18C25.5306 18 23.5284 20.0351 23.5284 22.5455V26.1818C23.5284 28.6922 25.5306 30.7273 28.0004 30.7273C30.4703 30.7273 32.4725 28.6922 32.4725 26.1818V22.5455C32.4725 21.3399 32.0013 20.1838 31.1626 19.3313ZM21.8022 27.0909H20C20.4189 30.8896 23.3687 33.8878 27.106 34.3136V38H28.8949V34.3136C32.6318 33.8875 35.5812 30.8893 36 27.0909H34.1969C33.7489 30.2194 31.1099 32.5396 27.9996 32.5396C24.8892 32.5396 22.2502 30.2194 21.8022 27.0909Z"
        fill="white"
      />
    </svg>
  );
}
