import React from 'react';
import style from './doctorCreateSchedulePage.module.css';
import { Container } from '@material-ui/core';
import DoctorSchedule from '../../forms/settingsForm/doctorSchedule/doctorSchedule';

const DoctorCreateSchedulePage = () => {
  return (
    <Container className={style.container}>
      <h2 className={style.title}>Create schedule</h2>
      <h4 className={style.subtitle}>Choose working days:</h4>
      <DoctorSchedule />
    </Container>
  );
};

export default React.memo(DoctorCreateSchedulePage);
