import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import commonStyle from './../registration.module.css';
import style from './personalData.module.css';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import * as dayjs from 'dayjs';
import FormikControl from './../../formikControl';
import axios from './../../../API/configAPI';
import { patientPersonalDataSchema } from './../../validationSchema';
import Loader from '../../../components/loader/loader';
import getFormattedPhotoId from '../../../helpers/getFormattedPhotoId';
import getFormattedDate from '../../../helpers/getFormattedDate';
import getFormattedChips from '../../../helpers/getFormattedChips';
import formattedPhoneNumber from '../../../helpers/formattedPhoneNumber';
import { FORMAT_PHONE } from '../../../constants/formatValueInput';
import { PATIENT_ROLE } from '../../../constants/userRole';
import { useHistory } from 'react-router';

const PersonalData = ({ nextStep, viewSetting }) => {
  const options = [
    { key: 'Male', value: 'male' },
    { key: 'Female', value: 'female' },
  ];

  const history = useHistory();
  const [user, setUser] = useState(null);
  const isPatient = user?.role === PATIENT_ROLE;

  useEffect(() => {
    axios.get('auth/me').then(({ data: user }) => {
      setUser(user);
    });
  }, []);

  const initialValues = {
    firstName: isPatient ? user?.personalData?.firstName || '' : '',
    middleName: isPatient ? user?.personalData?.middleName || '' : '',
    lastName: isPatient ? user?.personalData?.lastName || '' : '',
    avatar: isPatient ? getFormattedPhotoId(user?.personalData?.avatarId) || null : null,
    gender: isPatient ? user?.personalData?.gender || '' : '',
    birth: isPatient ? getFormattedDate(user?.personalData?.birth) || null : null,
    country: 'Trinidad and Tobago',
    city: isPatient ? user?.personalData?.city || '' : '',
    state: isPatient ? user?.personalData?.state || '' : '',
    zipCode: isPatient ? user?.personalData?.zipCode || '' : '',
    address: isPatient ? user?.personalData?.address || '' : '',
    email: isPatient ? user?.email : '',
    phone: isPatient ? user?.personalData?.phone || '' : '',
    height: isPatient ? user?.personalData?.height || '' : '',
    weight: isPatient ? user?.personalData?.weight || '' : '',
    illnesses: isPatient ? getFormattedChips(user?.personalData?.illnesses, 'illness') || null : null,
    medications: isPatient ? getFormattedChips(user?.personalData?.medications, 'medication') || null : null,
    allergies: isPatient ? getFormattedChips(user?.personalData?.allergies, 'allergy') || null : null,
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formattedValue = {
      ...values,
      birth: dayjs(values.birth.toISOString()).format('YYYY-MM-DD'),
      phone: formattedPhoneNumber(values.phone),
      country: '',
      height: Number(values.height),
      weight: Number(values.weight),
    };

    const requestType = isPatient && user.personalData?.id ? 'put' : 'post';

    axios[requestType](isPatient ? 'personal-data' : 'user/patient', formattedValue)
      .then(() => {
        resetForm();
        setSubmitting(false);
        nextStep();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handlerBack = () => {
    history.goBack();
  };

  return (
    <>
      {!user && <Loader />}
      {user && (
        <div className={commonStyle.container}>
          {isPatient && (
            <div className={commonStyle.header}>
              <h3 className={commonStyle.title}>Personal data</h3>
            </div>
          )}
          <Formik initialValues={initialValues} validationSchema={patientPersonalDataSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <form autoComplete="off" className={commonStyle.form} onSubmit={handleSubmit}>
                <div className={style.formGridUp}>
                  <FormikControl control="imageUpload" name="avatar" />
                  <div className={style.gridLine}>
                    <FormikControl
                      control="textInput"
                      label="First name"
                      type="text"
                      name="firstName"
                      placeholder="First name"
                    />
                    <FormikControl
                      control="textInput"
                      label="Middle name"
                      type="text"
                      name="middleName"
                      placeholder="Middle name"
                    />
                    <FormikControl
                      control="textInput"
                      label="Last name"
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                    />
                    <FormikControl control="datepicker" label="Date of birth" name="birth" />
                    <div className={style.gridArea}>
                      <FormikControl control="radioButtonGroup" label="Gender" name="gender" options={options} />
                    </div>
                  </div>
                </div>
                <div className={style.gridLine}>
                  <FormikControl
                    control="textInput"
                    label="Country"
                    type="text"
                    name="country"
                    placeholder=""
                    disabled={true}
                  />
                  <FormikControl control="textInput" label="City" type="text" name="city" placeholder="City" />
                  <FormikControl control="textInput" label="State" type="text" name="state" placeholder="State" />
                  <FormikControl
                    control="textInput"
                    label="Zip code"
                    type="text"
                    name="zipCode"
                    placeholder="Zip code"
                  />
                  <FormikControl control="textInput" label="Address" type="text" name="address" placeholder="Address" />
                  <FormikControl
                    control="textInput"
                    label="Phone Number"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    format={FORMAT_PHONE}
                  />
                  <FormikControl
                    control="textInput"
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    disabled={isPatient}
                  />
                  <FormikControl control="textInput" label="Height" type="text" name="height" placeholder="***Ft" />
                  <FormikControl control="textInput" label="Weight" type="text" name="weight" placeholder="***Ib" />
                </div>
                <div>
                  <FormikControl
                    control="chipsInput"
                    label="Add your illness or symptoms"
                    type="chips"
                    name="illnesses"
                    searchRoutePath="personal-data/illnesses"
                    addRoutePath="personal-data/illness"
                    placeholder="Add your illness or symptoms"
                  />
                </div>
                <div>
                  <FormikControl
                    control="chipsInput"
                    label="Add your medications"
                    type="chips"
                    name="medications"
                    searchRoutePath="personal-data/medication"
                    addRoutePath="personal-data/medication"
                    placeholder="Add your medications"
                  />
                </div>
                <div>
                  <FormikControl
                    control="chipsInput"
                    label="Add your allergies"
                    type="chips"
                    name="allergies"
                    searchRoutePath="personal-data/allergy"
                    addRoutePath="personal-data/allergy"
                    placeholder="Add your allergies"
                  />
                </div>
                <div className={[commonStyle.actions, viewSetting && commonStyle.actionsSettings].join(' ')}>
                  {!viewSetting && (
                    <Button
                      onClick={handlerBack}
                      disabled={isPatient}
                      className={commonStyle.button}
                      variant="outlined"
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className={commonStyle.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isPatient ? (viewSetting ? 'Save' : 'Registration') : 'Create'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

PersonalData.propTypes = {
  nextStep: PropTypes.func.isRequired,
  viewSetting: PropTypes.bool,
};

export default React.memo(PersonalData);
