import React, { useState, useEffect } from 'react';
import style from './appointments.module.css';
import axios from '../../API/configAPI';
import Loader from '../../components/loader/loader';
import BlockedTime from '../blocketTime/blockedTime';
import ScheduleTable from '../scheduleTable/scheduleTable';
import { useSelector } from 'react-redux';

const Appointments = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      {!user && <Loader />}
      {user && (
        <div className={style.wrapper}>
          <div className={style.header}>
            <h3 className={style.title}>Appointments</h3>
          </div>
          <div className={style.content}>
            <ScheduleTable scheduleId={user?.scheduleId} />
            <BlockedTime scheduleId={user?.scheduleId} />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Appointments);
