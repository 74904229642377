import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './searchBy.module.css';
import { Button } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import dayjs from './../../helpers/getDayJsWithTimezone';
import { debounce } from './../../helpers/debounce';

// icon
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';

const SearchBy = ({ handleSearch, searchByValues }) => {
  const [value, setValue] = useState('');
  const [searchBy, setSearchBy] = useState(searchByValues[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());

  const searchValue = () => {
    handleSearch(value, searchBy);
    setValue('');
  };

  const handleChange = (data) => {
    setDateValue(data);
    const value = dayjs(data).format('YYYY-MM-DD');
    if (isOpen) setIsOpen(false);
    handleSearch(value, searchBy);
  };

  const onChange = debounce(handleChange, 500);

  return (
    <div className={style.search}>
      {searchByValues.length !== 0 && (
        <div className={style.searchHeader}>
          <span className={style.searchLabel}>Search by:</span>
          <div className={style.searchToggleButtons}>
            {searchByValues.map((searchByValue, index) => (
              <Button
                key={index}
                className={style.searchToggleButton}
                color={searchBy === searchByValue ? 'secondary' : 'default'}
                variant={searchBy === searchByValue ? 'contained' : 'text'}
                onClick={() => setSearchBy(searchByValue)}
              >
                {searchByValue}
              </Button>
            ))}
          </div>
        </div>
      )}
      {searchBy !== 'date' && (
        <div className={style.searchInputWrapper}>
          <PersonOutlineIcon className={style.searchIconStart} />
          <input
            className={style.searchInput}
            type="text"
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                searchValue();
              }
            }}
            placeholder={'Enter the search value...'}
          />
          <SearchIcon className={style.searchIconEnd} onClick={searchValue} />
        </div>
      )}
      {searchBy === 'date' && (
        <div className={style.searchInputWrapper}>
          <DatePicker
            className={style.searchInput}
            selected={dateValue}
            onChange={onChange}
            onClickOutside={() => setIsOpen(false)}
            open={isOpen}
            dateFormat="yyyy/MM/dd"
            autoComplete="false"
            placeholderText="yyyy/mm/dd"
            showPopperArrow={false}
          />
          <span className={[style.searchIconStart, style.actionIcon].join(' ')} onClick={() => setIsOpen(!isOpen)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M17.8333 3.66667H14.5V2H12.8333V3.66667H7.83333V2H6.16667V3.66667H2.83333C2.3731 3.66667 2 4.03976 2 4.5V17.8333C2 18.2936 2.3731 18.6667 2.83333 18.6667H17.8333C18.2936 18.6667 18.6667 18.2936 18.6667 17.8333V4.5C18.6667 4.03976 18.2936 3.66667 17.8333 3.66667ZM3.66667 10.3333H17V17H3.66667V10.3333ZM12.8333 5.33333H7.83333V7H6.16667V5.33333H3.66667V8.66667H17V5.33333H14.5V7H12.8333V5.33333ZM7 13.6667V12H5.33333V13.6667H7ZM9.5 12H11.1667V13.6667H9.5V12ZM15.3333 13.6667V12H13.6667V13.6667H15.3333Z"
                fill="#BBBCC3"
              />
            </svg>
          </span>
        </div>
      )}
    </div>
  );
};

SearchBy.defaultProps = {
  searchByValues: [],
};

SearchBy.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  searchByValues: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(SearchBy);
