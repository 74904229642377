import React, { useState, useRef } from 'react';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { ButtonBase } from '@material-ui/core';
import AboutDialog from '../../aboutDialog/aboutDialog';
import DeviceSelectionDialog from '../../deviceSelectionDialog/deviceSelectionDialog';
import SettingsIcon from '../../../../icons/settingsIcon';
import ExpandIcon from '../../../../icons/expandIcon';
import { useAppState } from '../../../../state';

export default function Menu(props: { buttonClassName?: string }) {
  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { chatOpen, setChatOpen } = useAppState();

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <ButtonBase onClick={() => setMenuOpen((isOpen) => !isOpen)} ref={anchorRef} className={props.buttonClassName}>
        <SettingsIcon />
      </ButtonBase>
      <ButtonBase onClick={() => setChatOpen(!chatOpen)} className={props.buttonClassName}>
        <ExpandIcon />
      </ButtonBase>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -50,
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => setAboutOpen(true)}>
          <Typography variant="body1">About</Typography>
        </MenuItem>
        <MenuItem onClick={() => setSettingsOpen(true)}>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
