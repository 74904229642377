/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import style from './authorization.module.css';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import { signUpSchema } from '../validationSchema';
import axios from './../../API/configAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import FormikControl from '../formikControl';

const SignUpForm = ({ role }) => {
  const RESEND_LINK = '[SignUpForm] Resend link';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const [emailResend, setEmailResend] = useState();

  useEffect(() => {
    if (answerModal === RESEND_LINK) {
      dispatch(setModalAnswer(''));
      axios.get(`auth/resend-verification/${emailResend}`).then(() => {
        dispatch(setModalContent(contentModal));
      });
    }
  }, [answerModal]);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Check your inbox',
    description: `
      The email confirmation link was sent to your address. 
      Check it, please.
    `,
    actions: [
      {
        title: 'Resend link',
        answerModal: RESEND_LINK,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2 className={style.title}>Welcome to Ibis</h2>
        <p className={style.subtitle}>Enter your Email, Password and Repeat Password to continue.</p>
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
          passwordConfirm: '',
          role: role,
        }}
        validationSchema={signUpSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const { email, password, role } = values;
          setEmailResend(email);

          axios
            .post('auth/register', { email, password, role })
            .then(() => {
              resetForm();
              setSubmitting(false);
              dispatch(setModalContent(contentModal));
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <FormikControl control="textInput" label="Email" type="email" name="email" placeholder="Email" />
            <FormikControl
              control="passwordInput"
              label="Password"
              type="password"
              name="password"
              placeholder="8+ characters"
              forgot={false}
            />
            <FormikControl
              control="passwordInput"
              label="Repeat password"
              type="password"
              name="passwordConfirm"
              placeholder="8+ characters"
              forgot={false}
            />
            <Button
              className={style.button}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={isSubmitting}
            >
              Continue
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
