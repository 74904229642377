import React, { useState } from 'react';
import style from './signUpPage.module.css';
import SignUpForm from '../../forms/authorization/signUpForm';
import { Single } from '../../layouts/single/single';
import { DOCTOR_ROLE, PATIENT_ROLE } from '../../constants/userRole';

// images
import doctor from './../../assets/images/signUp/doctor.png';
import patient from './../../assets/images/signUp/patient.png';

export const SignUpPage = () => {
  const [role, setRole] = useState(null);

  return (
    <Single>
      {!role && (
        <div className={style.cardGrid}>
          <div onClick={() => setRole(DOCTOR_ROLE)} className={style.card}>
            <div className={style.cardWrapperImg}>
              <img src={doctor} alt="doctor" />
            </div>
            <h4 className={style.cardTitle}>Doctor</h4>
          </div>
          <div onClick={() => setRole(PATIENT_ROLE)} className={style.card}>
            <div className={style.cardWrapperImg}>
              <img src={patient} alt="patient" />
            </div>
            <h4 className={style.cardTitle}>Patient</h4>
          </div>
        </div>
      )}
      {role && <SignUpForm role={role} />}
    </Single>
  );
};
