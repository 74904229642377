import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './fileUpload.module.css';
import { useField } from 'formik';
import attachment from './../../../assets/icons/attachment.svg';
import axios from './../../../API/configAPI';
import { Chip, LinearProgress } from '@material-ui/core';

// icon
import GetAppIcon from '@material-ui/icons/GetApp';

const FileUpload = ({ name, label = '' }) => {
  const FILE_NAME = 'file';
  const [field, meta, helpers] = useField(name);
  const [fileId, setFileId] = useState(field.value?.id);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [base64, setBase64] = useState(null);

  useEffect(() => {
    if (fileId) {
      setIsFileLoading(true);
      axios
        .get(`files/${fileId}`)
        .then(({ data: { encodedFile, originalname } }) => {
          setBase64(encodedFile);
          setFileName(originalname || FILE_NAME);
          setIsFileLoading(false);
        })
        .catch((error) => {
          setIsFileLoading(false);
        });
    }
  }, [fileId]);

  useEffect(() => {
    if (selectedFile) {
      setIsFileLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      axios
        .post('files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data: { id } }) => {
          setFileId(id);
          helpers.setValue({ id });
          setBase64(null);
          setFileName(selectedFile.name);
          setIsFileLoading(false);
        })
        .catch((err) => {
          setIsFileLoading(false);
        });
    }
  }, [selectedFile]);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file && file.name) {
      setSelectedFile(file);
    }
  };

  const handleDelete = () => {
    setFileName('');
    setSelectedFile(null);
    setFileId(null);
    setBase64(null);
    helpers.setValue(null);
  };

  return (
    <div className={style.wrapper}>
      {base64 && (
        <a className={style.downloadLink} href={base64} download={fileName} target="_blank">
          <GetAppIcon fontSize="small" />
        </a>
      )}
      {fileName && <Chip variant="outlined" className={style.chip} label={fileName} onDelete={handleDelete} />}
      <label className={style.wrapperField}>
        <input
          className={style.input}
          type="file"
          onChange={handleChange}
          accept=".doc, .pdf, .jpg, .jpeg .png, .dcm, .zip, application/msword, application/pdf, image/jpg, image/jpeg, image/png, image/dicom, application/zip"
        />
        <div className={style.wrapperIcon}>
          <img className={style.icon} src={attachment} alt="icon" />
        </div>
        <span className={style.label}>{label}</span>
      </label>
      {isFileLoading && <LinearProgress color="secondary" className={style.progressBar} />}
    </div>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default React.memo(FileUpload);
