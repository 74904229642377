import * as dayjs from 'dayjs';

const isValidDate = (date) => {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
};

const getFormattedDate = (dateInString) => {
  if (!dateInString) return;
  if (typeof dateInString !== 'string') return;
  const date = dayjs(dateInString).toDate();
  if (!isValidDate(date)) return;
  return date;
};

export default getFormattedDate;
