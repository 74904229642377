import React from 'react';
import ForgotPasswordForm from '../../forms/authorization/forgotPasswordForm';
import { Single } from '../../layouts/single/single';

export const ForgotPasswordPage = () => {
  return (
    <Single>
      <ForgotPasswordForm />
    </Single>
  );
};
