import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { SIGN_IN } from './../constants/locationPath';
import { useAuth } from './../hooks/authHook';

const ProtectedPage = ({ children, accessFor, ...props }) => {
  const { storageName } = useAuth();
  const data = JSON.parse(localStorage.getItem(storageName));

  return (
    <Route
      {...props}
      render={({ location }) =>
        accessFor.includes(data?.user?.role) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: SIGN_IN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

ProtectedPage.defaultProps = {
  accessFor: [],
};

ProtectedPage.propTypes = {
  children: PropTypes.element.isRequired,
  accessFor: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(ProtectedPage);
