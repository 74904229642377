import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import modalSlice from './modalSlice';
import loaderSlice from './loaderSlice';
import imageCacheSlice from './imageCacheSlice';
import scheduleSlice from './scheduleSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  modal: modalSlice,
  loader: loaderSlice,
  imageCache: imageCacheSlice,
  schedule: scheduleSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});
