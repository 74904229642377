import React from 'react';
import PropTypes from 'prop-types';
import style from './createLabCategory.module.css';
import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import { createLabCategorySchema } from '../validationSchema';
import FormikControl from '../formikControl';
import axios from './../../API/configAPI';
import DialogBlockedTime from '../../components/dialogBlockedTime/dialogBlockedTime';

const CreateLabCategory = ({ handleClose, isOpen, handleSave }) => {
  const options = [
    { displayName: '%', value: 'percentage' },
    { displayName: '0.00', value: 'absolute' },
  ];

  const initialValues = {
    title: '',
    unit: options[0].value,
    description: '',
  };

  const onSubmit = (values, { setSubmitting }) => {
    axios
      .post('result-value-category', values)
      .then(({ data }) => {
        setSubmitting(false);
        handleSave(data);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Formik initialValues={initialValues} validationSchema={createLabCategorySchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
            <h4 className={style.subtitle}>View/Edit Report</h4>
            <div className={style.lineGrid}>
              <FormikControl
                control="textInput"
                label="Component name"
                type="text"
                name="title"
                placeholder="Add name"
              />
              <FormikControl control="selectInput" label="Unit" name="unit" options={options} />
            </div>
            <FormikControl
              control="textarea"
              label="Standard range"
              type="text"
              name="description"
              placeholder="Add range"
            />
            <div className={style.actions}>
              <Button className={style.button} variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className={style.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

DialogBlockedTime.defaultProps = {
  handleSave: () => {},
};

DialogBlockedTime.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

export default React.memo(CreateLabCategory);
