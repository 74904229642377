import React, { Component, PureComponent } from 'react';
import styles from './assets/MessageBubble.module.css';
import PropTypes from 'prop-types';
import { Spin, Modal } from 'antd';
import Icon from '@ant-design/icons';
import Loader from '../../components/loader/loader';
import FileIcon from '../../icons/fileIcon'
import { ButtonBase } from '@material-ui/core';

class MessageBubble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMedia: this.props.message.type === 'media',
      mediaDownloadFailed: false,
      mediaUrl: null,
    };
  }

  componentDidMount = async () => {
    this.setState({
      ...this.state,
      type: (await this.props.message.getParticipant()).type,
    });
    if (this.state.hasMedia) {
      this.props.message.media
        .getContentTemporaryUrl()
        .then((url) => {
          this.setState({ mediaUrl: url });
        })
        .catch((e) => this.setState({ mediaDownloadFailed: true }));
    }
    document.getElementById(this.props.message.sid).scrollIntoView({ behavior: 'smooth' });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    document.getElementById(this.props.message.sid).scrollIntoView({ behavior: 'smooth' });
  }

  render = () => {
    const { itemStyle, divStyle } =
      this.props.direction === 'incoming'
        ? { itemStyle: styles.outgoing_msg, divStyle: styles.sent_msg }
        : {
          itemStyle: styles.received_msg,
          divStyle: styles.received_withd_msg,
        };

    const m = this.props.message;
    const type = this.state.type;

    if (this.state.hasMedia) {
      console.log('Message is media message');
      // log media properties
      console.log('Media properties', m.media);
    }

    return (
      <li id={m.sid} className={itemStyle}>
        <div className={divStyle}>
          <div>
            <div style={{ display: 'flex', direction: 'row' }}>
              <p>{` ${m.author.split(' ').slice(0, 2).join(' ')}`}</p>
              <div style={{ flexGrow: 1 }}></div>
              <p>{m.state.timestamp.toLocaleTimeString()}</p>
            </div>
            <div className={styles.medias}>
              {this.state.hasMedia && <Media hasFailed={this.state.mediaDownloadFailed} url={this.state.mediaUrl} media={m.media} direction={this.props.direction} />}
            </div>
            {m.body}
          </div>
        </div>
      </li>
    );
  };
}

class Media extends PureComponent {
  render = () => {
    const { hasFailed, url, direction, media } = this.props;
    return (
      <div
        className={`${styles.media}${!url ? ' ' + styles.placeholder : ''}`}
      >
        {!url && !hasFailed && <Loader />}

        {hasFailed && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Icon type={'warning'} style={{ fontSize: '5em' }} />
            <p>Failed to load media</p>
          </div>
        )}

        {!hasFailed && url && (
          <div>
            <ButtonBase href={url} download target="_blank">
              <FileIcon className={direction === 'incoming' ? '' : styles.media_icon_white} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                <p>
                  {media.state.contentType.split('/').slice(1, 2)}{' '}
                  {(media.state.size / 1000000).toFixed(1)}mb
                </p>
                Download
              </div>
            </ButtonBase>
          </div>
        )}
      </div>
    );
  };
}

Media.propTypes = {
  hasFailed: PropTypes.bool.isRequired,
  url: PropTypes.string,
};

export default MessageBubble;
