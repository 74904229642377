/* eslint-disable react/prop-types */
import React from 'react';
import TextInput from './formikComponents/textInput';
import PasswordInput from './formikComponents/passwordInput';
import CheckboxInput from './formikComponents/checkboxInput';
import RadioButtonGroup from './formikComponents/radioButtonGroup';
import DatePicker from './formikComponents/datepicker/datepicker';
import ImgUpload from './formikComponents/imgUpload/imgUpload';
import ChipsInput from './formikComponents/chipsInput/chipsInput';
import TimePicker from './formikComponents/timePicker/timePicker';
import FileUpload from './formikComponents/fileUpload/fileUpload';
import Textarea from './formikComponents/textarea';
import SelectInput from './formikComponents/selectInput';
import Signature from './formikComponents/signature/signature';

const FormikControl = ({ control, ...rest }) => {
  switch (control) {
    case 'textInput':
      return <TextInput {...rest} />;
    case 'passwordInput':
      return <PasswordInput {...rest} />;
    case 'checkboxInput':
      return <CheckboxInput {...rest} />;
    case 'radioButtonGroup':
      return <RadioButtonGroup {...rest} />;
    case 'datepicker':
      return <DatePicker {...rest} />;
    case 'imageUpload':
      return <ImgUpload {...rest} />;
    case 'chipsInput':
      return <ChipsInput {...rest} />;
    case 'timePicker':
      return <TimePicker {...rest} />;
    case 'fileUpload':
      return <FileUpload {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;
    case 'selectInput':
      return <SelectInput {...rest} />;
    case 'signature':
      return <Signature {...rest} />;
    default:
      return null;
  }
};

export default React.memo(FormikControl);
