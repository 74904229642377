import React, { useEffect, useState } from 'react';
import style from './patientDashboardPage.module.css';
import axios from './../../API/configAPI';
import { useHistory } from 'react-router-dom';
import {
  INVOICES,
  PATIENT_LAB_RESULTS,
  PATIENT_REGISTRATION,
  PRESCRIPTIONS,
  SEARCH,
} from '../../constants/locationPath';
import Loader from '../../components/loader/loader';
import { Button, Container, MenuItem, Select } from '@material-ui/core';
import ViewImage from './../../components/viewImage/viewImage';
import UpdatesFeed from './../../components/updatesFeed/updatesFeed';
import Pagination from '@material-ui/lab/Pagination';
import AppointmentListItem from '../../components/appointmentListItem/appointmentListItem';
import { setUser } from '../../store/authSlice';
import { useDispatch } from 'react-redux';
import SearchBy from './../../components/searchBy/searchBy';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PrescriptionPreview from './../../components/prescriptionPreview/prescriptionPreview';
import BootstrapInput from '../../components/bootstrapComponent/bootstrapInput';
import { REQUESTS, UPCOMING, PAST } from './../../constants/appointmentType';
import { CONFIRMED, UNCONFIRMED } from './../../constants/appointmentStatus';
import InvoicePreview from '../../components/invoicePreview/invoicePreview';
import dayJs from './../../helpers/getDayJsWithTimezone';

// icon
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const PatientDashboardPage = () => {
  const COUNT_SHOW_ON_PAGE = 6;
  const KEY_SEARCH_NAME = 'name';
  const KEY_SEARCH_DATE = 'date';
  const sortingAppointments = [
    { displayName: 'Name', value: 'name' },
    { displayName: 'Date', value: 'date' },
    { displayName: 'Duration', value: 'duration' },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:576px)');

  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState(null);
  const [activeTab, setActiveTab] = useState(REQUESTS);
  const [page, setPage] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const [totalPagesAppointments, setTotalPagesAppointments] = useState(0);
  const [activeSorting, setActiveSorting] = useState(sortingAppointments[1].value);
  const [direction, setDirection] = useState(true);
  const [isLoadingPrescriptions, setIsLoadingPrescriptions] = useState(false);
  const [prescriptions, setPrescriptions] = useState([]);
  const [typeAppointments, setTypeAppointments] = useState(REQUESTS);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [isLoadingLabResults, setIsLoadingLabResults] = useState(false);
  const [labResults, setLabResults] = useState([]);

  useEffect(() => {
    axios
      .get('auth/me')
      .then(({ data: patient }) => {
        setPatient(patient);
        dispatch(setUser(patient));
        if (!patient?.personalData?.id) {
          history.push(PATIENT_REGISTRATION);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (patient && patient.id) {
      setIsLoadingInvoices(true);
      axios
        .get(`invoice?&take=2&skip=0`)
        .then(({ data: { results } }) => {
          setInvoices(results);
          setIsLoadingInvoices(false);
        })
        .catch((error) => {
          setIsLoadingInvoices(false);
        });
    }
  }, [patient]);

  useEffect(() => {
    if (patient && patient.id) {
      setIsLoadingPrescriptions(true);
      axios
        .get(`prescription?patientId=${patient.id}&take=2&skip=0`)
        .then(({ data: { results } }) => {
          setPrescriptions(results);
          setIsLoadingPrescriptions(false);
        })
        .catch((error) => {
          setIsLoadingPrescriptions(false);
        });
    }
  }, [patient]);

  useEffect(() => {
    if (patient && patient.id) {
      setIsLoadingLabResults(true);
      axios
        .get(`lab-result?patientId=${patient.id}&take=2&skip=0`)
        .then(({ data: { results } }) => {
          setLabResults(results);
          setIsLoadingLabResults(false);
        })
        .catch((error) => {
          setIsLoadingLabResults(false);
        });
    }
  }, [patient]);

  const getAppointment = (value, searchBy) => {
    setLoading(true);
    setAppointments([]);
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    const range = activeTab !== REQUESTS ? activeTab : 'all';
    const status = activeTab !== REQUESTS ? CONFIRMED : UNCONFIRMED;
    const name = searchBy === KEY_SEARCH_NAME ? value : '';
    const date = searchBy === KEY_SEARCH_DATE ? value : '';
    const order = direction ? 'ASC' : 'DESC';
    axios
      .get(
        `appointment/search?take=${COUNT_SHOW_ON_PAGE}&skip=${skip}&range=${range}&status=${status}&name=${name}&date=${date}&sortField=${activeSorting}&order=${order}`,
      )
      .then(({ data: { results, total } }) => {
        setAppointments(results);
        setTypeAppointments(activeTab);
        if (total <= COUNT_SHOW_ON_PAGE) {
          setTotalPagesAppointments(0);
        } else {
          setTotalPagesAppointments(Math.ceil(total / COUNT_SHOW_ON_PAGE));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAppointment();
  }, [page, activeTab, activeSorting, direction]);

  const handleSearch = (value = '', searchBy = '') => {
    getAppointment(value, searchBy);
  };

  const handleNewAppointment = () => {
    history.push(SEARCH);
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const handlerChangeTab = (tab) => {
    setPage(1);
    setActiveTab(tab);
  };

  const handlerSorting = (value) => {
    if (value === activeSorting) {
      setDirection(!direction);
    } else {
      setActiveSorting(value);
    }
  };

  const handleSeeAllPrescriptions = () => {
    if (patient && patient.id) {
      history.push(`${PRESCRIPTIONS}/${patient.id}`);
    }
  };

  const handleSeeAllInvoices = () => {
    history.push(INVOICES);
  };

  const handleSeeAllLabResults = () => {
    history.push(PATIENT_LAB_RESULTS);
  };

  const handlerChangeStatus = () => {
    getAppointment();
  };

  return (
    <Container className={style.container}>
      {!patient && <Loader />}
      {patient && (
        <div className={style.grid}>
          <div className={style.sidebar}>
            <div className={style.activity}>
              <div className={style.activityHeader}>
                <h4 className={style.activityTitle}>Invoices</h4>
                <span className={style.activityButton} onClick={handleSeeAllInvoices}>
                  See all
                </span>
              </div>
              <div className={style.activityContent}>
                {isLoadingInvoices && <Loader />}
                {!isLoadingInvoices && invoices.length !== 0 && (
                  <ul className={style.activityList}>
                    {invoices.map((invoice) => (
                      <li key={invoice.id} className={style.activityListItem}>
                        <InvoicePreview invoiceData={invoice} />
                      </li>
                    ))}
                  </ul>
                )}
                {!isLoadingInvoices && invoices.length === 0 && (
                  <div className={style.notFoundBlock}>
                    <h3 className={style.notFoundBlockTitle}>No invoices</h3>
                  </div>
                )}
              </div>
            </div>
            <div className={style.activity}>
              <div className={style.activityHeader}>
                <h4 className={style.activityTitle}>Prescriptions</h4>
                <span className={style.activityButton} onClick={handleSeeAllPrescriptions}>
                  See all
                </span>
              </div>
              <div className={style.activityContent}>
                {isLoadingPrescriptions && <Loader />}
                {!isLoadingPrescriptions && prescriptions.length !== 0 && (
                  <ul className={style.activityList}>
                    {prescriptions.map(
                      ({ id, creationDate, description, avatarId, firstName, lastName, specialities }) => (
                        <li key={id} className={style.activityListItem}>
                          <PrescriptionPreview
                            id={id}
                            creationDate={creationDate}
                            description={description}
                            avatarId={avatarId}
                            firstName={firstName}
                            lastName={lastName}
                            specialities={specialities}
                            isMobileView
                          />
                        </li>
                      ),
                    )}
                  </ul>
                )}
                {!isLoadingPrescriptions && prescriptions.length === 0 && (
                  <div className={style.notFoundBlock}>
                    <h3 className={style.notFoundBlockTitle}>No prescriptions</h3>
                  </div>
                )}
              </div>
            </div>
            <div className={style.activity}>
              <div className={style.activityHeader}>
                <h4 className={style.activityTitle}>Medical reports</h4>
                <span className={style.activityButton} onClick={handleSeeAllLabResults}>
                  See all
                </span>
              </div>
              <div className={style.activityContent}>
                {isLoadingLabResults && <Loader />}
                {!isLoadingLabResults && labResults.length !== 0 && (
                  <ul className={style.activityList}>
                    {labResults.map(({ id, title, creationDate }) => (
                      <li key={id} className={style.activityListItem}>
                        <div className={style.labPreview}>
                          <span className={style.labPreviewTitle}>{title}</span>
                          <span className={style.labPreviewDate}>{dayJs(creationDate).tz().format('DD.MM.YYYY')}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {!isLoadingLabResults && labResults.length === 0 && (
                  <div className={style.notFoundBlock}>
                    <h3 className={style.notFoundBlockTitle}>No medical reports</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={style.patientInfo}>
            <div className={style.patientPersonalData}>
              <ViewImage imageId={patient?.personalData?.avatarId} size="142px" />
              <div className={style.patientPersonalGrid}>
                <h4 className={style.patientName}>
                  <span>{patient?.personalData?.firstName}</span>
                  &ensp;
                  <span>{patient?.personalData?.lastName}</span>
                </h4>
                <div className={style.specialInfo}>
                  <h5 className={style.specialInfoLabel}>Illnesses</h5>
                  {patient?.personalData?.illnesses?.map(({ id, illness }) => (
                    <span key={id} className={style.specialName}>
                      {illness.name}
                    </span>
                  ))}
                  {!patient?.personalData?.illnesses?.length && (
                    <span className={style.specialName}>No information</span>
                  )}
                </div>
                <div className={style.specialInfo}>
                  <h5 className={style.specialInfoLabel}>Medications</h5>
                  {patient?.personalData?.medications?.map(({ id, medication }) => (
                    <span key={id} className={style.specialName}>
                      {medication.name}
                    </span>
                  ))}
                  {!patient?.personalData?.medications?.length && (
                    <span className={style.specialName}>No information</span>
                  )}
                </div>
                <div className={style.specialInfo}>
                  <h5 className={style.specialInfoLabel}>Allergies</h5>
                  {patient?.personalData?.allergies?.map(({ id, allergy }) => (
                    <span key={id} className={style.specialName}>
                      {allergy.name}
                    </span>
                  ))}
                  {!patient?.personalData?.allergies?.length && (
                    <span className={style.specialName}>No information</span>
                  )}
                </div>
              </div>
            </div>
            {/* <UpdatesFeed /> */}
          </div>
          <div className={style.pageWrapper}>
            <h2 className={style.pageTitle}>Appointments</h2>
            <div className={style.actions}>
              <SearchBy handleSearch={handleSearch} searchByValues={[KEY_SEARCH_NAME, KEY_SEARCH_DATE]} />
              <Button className={style.addButton} variant="contained" color="primary" onClick={handleNewAppointment}>
                + New appointment
              </Button>
            </div>
            <div className={style.appointments}>
              <div className={style.appointmentsTabs}>
                <Button
                  className={style.appointmentsTabsButton}
                  color={activeTab === REQUESTS ? 'secondary' : 'default'}
                  variant={activeTab === REQUESTS ? 'contained' : 'text'}
                  onClick={() => handlerChangeTab(REQUESTS)}
                >
                  appointments
                </Button>
                <Button
                  className={style.appointmentsTabsButton}
                  color={activeTab === UPCOMING ? 'secondary' : 'default'}
                  variant={activeTab === UPCOMING ? 'contained' : 'text'}
                  onClick={() => handlerChangeTab(UPCOMING)}
                >
                  {UPCOMING}
                </Button>
                <Button
                  className={style.appointmentsTabsButton}
                  color={activeTab === PAST ? 'secondary' : 'default'}
                  variant={activeTab === PAST ? 'contained' : 'text'}
                  onClick={() => handlerChangeTab(PAST)}
                >
                  {PAST}
                </Button>
              </div>
              <div className={style.sorting}>
                {sortingAppointments.map(({ displayName, value }, index) => (
                  <span key={index} className={style.sortingBy} onClick={() => handlerSorting(value)}>
                    {displayName}
                    <UnfoldMoreIcon className={style.sortingIcon} />
                  </span>
                ))}
              </div>
              <div className={style.sortingMobile}>
                <span className={style.sortingLabel}>Sort by</span>
                <Select
                  fullWidth
                  className={style.selectList}
                  value={activeSorting}
                  onChange={({ target: { value } }) => handlerSorting(value)}
                  variant="outlined"
                  color="secondary"
                  input={<BootstrapInput />}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {sortingAppointments.map(({ displayName, value }, index) => (
                    <MenuItem key={index} value={value} className={style.selectListItem}>
                      {displayName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={style.tabsContainer}>
                {loading && <Loader />}
                {!loading && appointments.length !== 0 && (
                  <ul className={style.appointmentsList}>
                    {appointments.map((appointment) => (
                      <li key={appointment.id}>
                        <AppointmentListItem
                          type={typeAppointments}
                          appointment={appointment}
                          onChangeStatus={handlerChangeStatus}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {totalPagesAppointments !== 0 && (
                <div className={style.paginationWrapper}>
                  <Pagination
                    siblingCount={Number(!matches)}
                    count={totalPagesAppointments}
                    color="secondary"
                    onChange={handleChangePagination}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default React.memo(PatientDashboardPage);
