import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './categorySearch.module.css';
import BaseTextInput from '../../forms/baseFormControl/baseTextInput/baseTextInput';
import axios from './../../API/configAPI';
import { LinearProgress } from '@material-ui/core';
import { useField } from 'formik';

const CategorySearch = ({ disabled, nameField, selectCategory }) => {
  const [field, meta, helpers] = useField(nameField);
  const [valueInput, setValueInput] = useState('');
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValueInput(field.value);
  }, [field.value]);

  const handlerChange = (value) => {
    setValueInput(value);
    if (value === '') {
      return setAutoCompleteValue([]);
    }
    setLoading(true);
    axios
      .get(`result-value-category?name=${value}`)
      .then(({ data: { results } }) => {
        setAutoCompleteValue(results);
        setLoading(false);
      })
      .catch(() => {
        setAutoCompleteValue([]);
        setLoading(false);
      });
  };

  const handlerOnBlur = () => {
    helpers.setTouched(true);
    setTimeout(() => {
      setAutoCompleteValue([]);
    }, 300);
  };

  const handleSelected = (category) => {
    selectCategory(category);
    helpers.setValue(category.title);
    setValueInput(category.title);
    setAutoCompleteValue([]);
  };

  return (
    <div className={style.grid}>
      <div className={style.primaryField}>
        <span className={style.label}>Component</span>
        <BaseTextInput
          name={nameField}
          onBlur={handlerOnBlur}
          error={meta.error}
          touched={meta.touched}
          onChange={({ target: { value } }) => handlerChange(value)}
          autoComplete="off"
          value={valueInput}
          disabled={disabled}
        />
        {!!autoCompleteValue.length && (
          <ul className={style.listAutoComplete}>
            {autoCompleteValue.map((category) => {
              return (
                <li
                  className={style.itemAutoComplete}
                  key={category.id}
                  onClick={() => {
                    handleSelected(category);
                  }}
                >
                  {category.title}
                </li>
              );
            })}
          </ul>
        )}
        {loading && <LinearProgress color="secondary" className={style.progressBar} />}
      </div>
    </div>
  );
};

CategorySearch.propTypes = {
  disabled: PropTypes.bool.isRequired,
  nameField: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
};

export default React.memo(CategorySearch);
