import React, { useState, useEffect } from 'react';
import style from './patientCreateRequestPage.module.css';
import { Container } from '@material-ui/core';
import ViewImage from '../../components/viewImage/viewImage';
import Loader from '../../components/loader/loader';
import axios from './../../API/configAPI';
import { useParams } from 'react-router';
import CreateRequest from '../../forms/createRequest/createRequest';
import LinkToProfile from '../../components/linkToProfile/linkToProfile';
import { DOCTOR_ROLE } from '../../constants/userRole';

const PatientCreateRequestPage = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);

  useEffect(() => {
    if (id) {
      axios
        .get(`user/doctor/${id}`)
        .then(({ data: doctor }) => {
          setDoctor(doctor);
        })
        .catch((error) => {});
    }
  }, []);

  return (
    <>
      {!doctor && <Loader />}
      {doctor && (
        <Container>
          <div className={style.wrapper}>
            <h3 className={style.title}>Create request</h3>
            <div className={style.content}>
              <div className={style.doctor}>
                <ViewImage imageId={doctor?.personalData?.avatarId} size="142px" borderRadius="14px" />
                <div className={style.doctorInfo}>
                  <div className={style.doctorName}>
                    <LinkToProfile
                      firstName={doctor?.personalData?.firstName}
                      lastName={doctor?.personalData?.lastName}
                      role={DOCTOR_ROLE}
                      userId={doctor?.id}
                    />
                  </div>
                  <div className={style.doctorValues}>
                    <span className={style.label}>Specialities</span>
                    <div className={style.values}>
                      {doctor?.doctorData?.specialities?.map(({ id, speciality }) => (
                        <span key={id} className={style.value}>
                          {speciality.name}
                        </span>
                      ))}
                    </div>
                    <span className={style.label}>Qualifications</span>
                    <div className={style.values}>
                      {doctor?.doctorData?.qualifications?.map(({ id, qualification }) => (
                        <span key={id} className={style.value}>
                          {qualification.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <CreateRequest doctorScheduleId={doctor.scheduleId} doctorId={doctor.id} />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default React.memo(PatientCreateRequestPage);
