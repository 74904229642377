import React from 'react';
import style from './updatesFeedListItem.module.css';
import ViewImage from '../../components/viewImage/viewImage';
import { CONFIRMED, REJECTED, CANCELED } from './../../constants/appointmentStatus';

const UpdatesFeedListItem = ({ updateEvent }) => {
  const getStatus = () => {
    switch ('confirmed') {
      case CONFIRMED:
        return 'confirmed';
      case REJECTED:
        return 'rejected';
      case CANCELED:
        return 'canceled';
      default:
        return '';
    }
  };
  return (
    <div className={style.wrapper}>
      <div className={style.user}>
        <ViewImage imageId={updateEvent.avatarId} size="51px" borderRadius="8px" />
        <div className={style.userInfo}>
          <h4 className={style.userName}>
            <span>{updateEvent.firstName}</span>
            &ensp;
            <span>{updateEvent.lastName}</span>
          </h4>
        </div>
      </div>
      <div className={style.event}>
        <span className={[style.eventType, style[getStatus()]].join(' ')}>Request was accepted</span>
        <span className={style.eventPerson}>By doctor Jeferson</span>
      </div>
    </div>
  );
};

export default React.memo(UpdatesFeedListItem);
