import React from 'react';

export default function VideoOnIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#1B2A4A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.2845 22.3563L32.5455 25.55V21.875C32.5455 21.3918 32.1384 21 31.6364 21H18.9091C18.407 21 18 21.3918 18 21.875V34.125C18 34.6082 18.407 35 18.9091 35H31.6364C32.1384 35 32.5455 34.6082 32.5455 34.125V30.45L37.2845 33.6438C37.4235 33.7375 37.605 33.7489 37.7555 33.6735C37.9059 33.5981 38.0002 33.4483 38 33.285V22.715C38.0002 22.5517 37.9059 22.4019 37.7555 22.3265C37.605 22.2511 37.4235 22.2625 37.2845 22.3563ZM21.6364 26.25V24.5H23.4545V26.25H21.6364Z"
        fill="white"
      />
    </svg>
  );
}
