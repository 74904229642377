import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './labResultsDetails.module.css';
import SearchBy from '../../components/searchBy/searchBy';
import { Button, Menu, MenuItem, LinearProgress } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '../../components/bootstrapComponent/bootstrapAccordion';
import axios from './../../API/configAPI';
import Pagination from '@material-ui/lab/Pagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import { useHistory } from 'react-router';
import { CREATE_LAB_RESULT } from '../../constants/locationPath';
import LabValueListItem from '../../components/labValueListItem/labValueListItem';
import downloadFile from '../../helpers/downloadFile';

// icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';

const LabResultsDetails = ({ patientId, countShowOnPage }) => {
  const DELETE = '[LabResultsDetails] Delete';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const COUNT_SHOW_ON_PAGE = countShowOnPage || 4;
  const LAB_RESULT = 'Medical reports';
  const LAB_VALUE = 'Lab value';
  const ADD_LAB_VALUE = 'View/Edit report';
  const DELETE_LAB_RESULT = 'Delete report';
  const EDIT_LAB_VALUE = 'Edit component';
  const DELETE_LAB_VALUE = 'Delete component';
  const DOWNLOAD = 'Attached file';
  const optionsResult = [ADD_LAB_VALUE, DELETE_LAB_RESULT];
  const optionsValue = [EDIT_LAB_VALUE, DELETE_LAB_VALUE];
  const matches = useMediaQuery('(max-width:576px)');
  const history = useHistory();

  const [expanded, setExpanded] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuName, setOpenMenuName] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [currentPanel, setCurrentPanel] = useState(null);
  const open = Boolean(anchorEl);
  const [searchValue, setSearchValue] = useState(null);
  // group
  const [isLoading, setIsLoading] = useState(false);
  const [labResultList, setLabResultList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // results
  const [isLoadingValues, setIsLoadingValues] = useState(false);
  const [labValueList, setLabValueList] = useState([]);
  const [pageResults, setPageResults] = useState(1);
  const [totalPagesResults, setTotalPagesResults] = useState(0);

  useEffect(() => {
    getLabResults(searchValue);
  }, [patientId, page, searchValue]);

  const removeResultInfo = () => {
    setLabValueList([]);
    setPageResults(1);
    setExpanded(null);
    setAnchorEl(null);
    setCurrentPanel(null);
  };

  const getLabResults = (valueSearch) => {
    setIsLoading(true);
    setLabResultList([]);
    removeResultInfo();
    const queryValue = valueSearch || '';
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    if (patientId) {
      axios
        .get(`lab-result?patientId=${patientId}&take=${COUNT_SHOW_ON_PAGE}&skip=${skip}&query=${queryValue}`)
        .then(({ data: { results, total } }) => {
          setLabResultList(results);
          if (total <= COUNT_SHOW_ON_PAGE) {
            setTotalPages(0);
          } else {
            setTotalPages(Math.ceil(total / COUNT_SHOW_ON_PAGE));
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getLabValues(currentPanel);
  }, [currentPanel, pageResults]);

  const getLabValues = (labValueId) => {
    setIsLoadingValues(true);
    setLabValueList([]);
    const skip = pageResults > 1 ? (pageResults - 1) * COUNT_SHOW_ON_PAGE : 0;
    if (labValueId) {
      axios
        .get(`result-value?labResultId=${labValueId}&take=${COUNT_SHOW_ON_PAGE}&skip=${skip}`)
        .then(({ data: { results, total } }) => {
          setLabValueList(results);
          if (total <= COUNT_SHOW_ON_PAGE) {
            setTotalPagesResults(0);
          } else {
            setTotalPagesResults(Math.ceil(total / COUNT_SHOW_ON_PAGE));
          }
          setIsLoadingValues(false);
        })
        .catch((error) => {
          setIsLoadingValues(false);
        });
    }
  };

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Delete',
    description: `
      Do you want to delete ${openMenuName}?
    `,
    actions: [
      {
        title: 'Cancel',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Ok',
        answerModal: DELETE,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  useEffect(() => {
    if (answerModal === DELETE) {
      dispatch(setModalAnswer(''));

      const url = openMenuName === LAB_RESULT ? 'lab-result' : 'result-value';

      axios
        .delete(`${url}/${openMenuId}`)
        .then(({ data }) => {
          openMenuName === LAB_RESULT ? getLabResults() : getLabValues(currentPanel);
        })
        .catch((error) => {});
    }
  }, [answerModal]);

  const handleSearch = (value, searchBy) => {
    setPage(1);
    setSearchValue(value);
  };

  const handleAddLabResult = () => {
    history.push(CREATE_LAB_RESULT, { patientId });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      setCurrentPanel(panel);
    } else {
      setCurrentPanel(null);
    }
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (event, menuName, id) => {
    setOpenMenuName(menuName);
    setOpenMenuId(id);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = (option) => {
    switch (option) {
      case ADD_LAB_VALUE:
        history.push(CREATE_LAB_RESULT, { patientId, resultId: openMenuId });
        break;
      case EDIT_LAB_VALUE:
        history.push(CREATE_LAB_RESULT, { patientId, resultId: currentPanel, valueId: openMenuId });
        break;
      case DELETE_LAB_RESULT:
        dispatch(setModalContent(contentModal));
        break;
      case DELETE_LAB_VALUE:
        dispatch(setModalContent(contentModal));
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const handleChangePaginationResults = (event, value) => {
    setPageResults(value);
  };

  const menuProps = {
    style: {
      background: '#FFFFFF',
      border: '1px solid #F7F7F9',
      boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.085)',
      borderRadius: '8px',
      padding: '12px 14px',
    },
  };

  const handleDownload = (event, fileId, title) => {
    if (event) {
      event.stopPropagation();
    }

    axios.get(`files/${fileId}`).then(({ data: { encodedFile: base64code, originalname } }) => {
      downloadFile(base64code, originalname || `file-${title || 'medical-report'}`);
    });
    setAnchorEl(null);
  };

  const isHaveAttachedFile = () => {
    const labResult = labResultList.find((labRes) => labRes.id === openMenuId);
    if (labResult?.fileId0 || labResult?.fileId1 || labResult?.fileId2) {
      return (
        <>
          {labResult?.fileId0 && (
            <MenuItem
              key={DOWNLOAD}
              onClick={() => handleDownload(null, labResult?.fileId0, labResult?.title)}
              className={[style.menuItemStyle, style.menuItem, style.menuItemMarkAccent].join(' ')}
            >
              <GetAppIcon fontSize="small" />
              {DOWNLOAD}
            </MenuItem>
          )}
          {labResult?.fileId1 && (
            <MenuItem
              key={DOWNLOAD}
              onClick={() => handleDownload(null, labResult?.fileId1, labResult?.title)}
              className={[style.menuItemStyle, style.menuItem, style.menuItemMarkAccent].join(' ')}
            >
              <GetAppIcon fontSize="small" />
              {DOWNLOAD}
            </MenuItem>
          )}
          {labResult?.fileId2 && (
            <MenuItem
              key={DOWNLOAD}
              onClick={() => handleDownload(null, labResult?.fileId2, labResult?.title)}
              className={[style.menuItemStyle, style.menuItem, style.menuItemMarkAccent].join(' ')}
            >
              <GetAppIcon fontSize="small" />
              {DOWNLOAD}
            </MenuItem>
          )}
        </>
      );
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <h3 className={style.title}>Medical report details</h3>
        <div className={style.actions}>
          <SearchBy handleSearch={handleSearch} />
          <Button className={style.addButton} variant="contained" color="primary" onClick={handleAddLabResult}>
            + Add medical report
          </Button>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.labHeader}>
          <span>Component</span>
          <span>Value</span>
          <span>Unit</span>
          <span>Flag</span>
          <span>Standard range</span>
          <span>Comment</span>
          <span>Date</span>
        </div>
        {isLoading && <LinearProgress />}
        {!isLoading && !!labResultList.length && (
          <div className={style.widthFull}>
            {labResultList.map(({ id, title, fileId0, fileId1, fileId2, description }) => (
              <Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
                <AccordionSummary aria-controls={title} id={id}>
                  {fileId0 && (
                    <Button
                      className={style.downloadLink}
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleDownload(e, fileId0, title)}
                    >
                      <GetAppIcon fontSize="small" />
                    </Button>
                  )}
                  {fileId1 && (
                    <Button
                      className={style.downloadLink}
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleDownload(e, fileId1, title)}
                    >
                      <GetAppIcon fontSize="small" />
                    </Button>
                  )}
                  {fileId2 && (
                    <Button
                      className={style.downloadLink}
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleDownload(e, fileId2, title)}
                    >
                      <GetAppIcon fontSize="small" />
                    </Button>
                  )}
                  <h4 className={style.blockTitle}>{title}</h4>
                  <MoreVertIcon
                    className={style.menuButton}
                    aria-label="more"
                    aria-controls="menu-lab"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, LAB_RESULT, id)}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className={style.widthFull}>
                    {description && (
                      <div className={style.descriptionWrapper}>
                        <p className={style.description}>
                          {description}
                        </p>
                      </div>
                    )}
                    {expanded === id && isLoadingValues && <LinearProgress />}
                    {expanded === id && !isLoadingValues && !!labValueList.length && (
                      <div className={style.widthFull}>
                        {labValueList.map((labValueItem) => (
                          <div key={labValueItem.id} className={style.labValueWrapper}>
                            <LabValueListItem labValueItem={labValueItem} />
                            <MoreVertIcon
                              className={style.menuButton}
                              aria-label="more"
                              aria-controls="menu-lab"
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, LAB_VALUE, labValueItem.id)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {expanded === id && !isLoadingValues && !labValueList.length && (
                      <div className={style.notFoundBlock}>
                        <h3 className={style.notFoundBlockTitle}>No information</h3>
                      </div>
                    )}
                    {expanded === id && !!totalPagesResults && (
                      <div className={style.paginationWrapper}>
                        <Pagination
                          siblingCount={Number(!matches)}
                          count={totalPagesResults}
                          color="secondary"
                          onChange={handleChangePaginationResults}
                        />
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
        {!isLoading && !labResultList.length && (
          <div className={style.notFoundBlock}>
            <h3 className={style.notFoundBlockTitle}>No medical report</h3>
          </div>
        )}
        {!!totalPages && (
          <div className={style.paginationWrapper}>
            <Pagination
              siblingCount={Number(!matches)}
              count={totalPages}
              color="secondary"
              onChange={handleChangePagination}
            />
          </div>
        )}
      </div>
      <Menu id="menu-lab" anchorEl={anchorEl} keepMounted open={open} onClose={handleCloseMenu} PaperProps={menuProps}>
        {openMenuName === LAB_RESULT &&
          optionsResult.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handleClose(option)}
              className={[style.menuItemStyle, style.menuItem, option === DELETE_LAB_RESULT && style.menuItemMark].join(
                ' ',
              )}
            >
              {option}
            </MenuItem>
          ))}
        {openMenuName === LAB_VALUE &&
          optionsValue.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handleClose(option)}
              className={[style.menuItemStyle, style.menuItem, option === DELETE_LAB_VALUE && style.menuItemMark].join(
                ' ',
              )}
            >
              {option}
            </MenuItem>
          ))}
        {openMenuName === LAB_RESULT && isHaveAttachedFile()}
      </Menu>
    </div>
  );
};

LabResultsDetails.propTypes = {
  patientId: PropTypes.string.isRequired,
  countShowOnPage: PropTypes.number,
};

export default React.memo(LabResultsDetails);
