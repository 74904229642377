import React from 'react';
import style from './doctorBigPlannerPage.module.css';
import { Container } from '@material-ui/core';
import BigPlannerPage from '../../layouts/bigPlanner/bigPlanner';

const DoctorBigPlannerPage = () => {
  return (
    <Container className={style.container}>
      <div className={style.wrapper}>
        <div className={style.header}>
          <h4 className={style.title}>Planner</h4>
        </div>
        <div className={style.scrollContainer}>
          <div className={style.content}>
            <BigPlannerPage />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DoctorBigPlannerPage;
