const formattedPhoneNumber = (initNumber) => {

  const indexNumber = initNumber.lastIndexOf('+');

  if(indexNumber === -1) {
    return `+${initNumber}`;
  } else if (indexNumber > 0) {
    return initNumber.slice(indexNumber);
  } else {
    return initNumber;
  }

};

export default formattedPhoneNumber;
