import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './patientSearch.module.css';
import BaseTextInput from '../../forms/baseFormControl/baseTextInput/baseTextInput';
import axios from './../../API/configAPI';
import { LinearProgress } from '@material-ui/core';
import { useField } from 'formik';
import { useLocation } from 'react-router';

const PatientSearch = ({ namePrimaryField, nameSecondaryField, disabled }) => {
  const [fieldPrimary, metaPrimary, helpersPrimary] = useField(namePrimaryField);
  const [fieldSecondary, metaSecondary, helpersSecondary] = useField(nameSecondaryField);
  const [valueInput, setValueInput] = useState('');
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    if (state && state.patientId) {
      axios.get(`user/${state.patientId}`).then(({ data: patient }) => {
        helpersSecondary.setValue(patient.email, true);
        helpersPrimary.setValue(patient.id, true);
        setValueInput(patient.personalData.firstName);
      });
    }
  }, []);

  const handlerChange = (value) => {
    setValueInput(value);
    if (value === '') {
      return setAutoCompleteValue([]);
    }
    setLoading(true);
    axios
      .get(`user/search/patients?query=${value}`)
      .then(({ data: { results } }) => {
        setAutoCompleteValue(results);
        setLoading(false);
      })
      .catch(() => {
        setAutoCompleteValue([]);
        setLoading(false);
      });
  };

  const handlerOnBlur = () => {
    helpersPrimary.setTouched(true);
    setTimeout(() => {
      setAutoCompleteValue([]);
    }, 300);
  };

  const handleSelected = (patient) => {
    helpersSecondary.setValue(patient.email, true);
    helpersPrimary.setValue(patient.id, true);
    setValueInput(patient.firstName);
    setAutoCompleteValue([]);
  };

  return (
    <div className={style.grid}>
      <div className={style.primaryField}>
        <span className={style.label}>Name/Patient ID</span>
        <BaseTextInput
          name={namePrimaryField}
          onBlur={handlerOnBlur}
          error={metaPrimary.error}
          touched={metaPrimary.touched}
          onChange={({ target: { value } }) => handlerChange(value)}
          autoComplete="off"
          value={valueInput}
          disabled={disabled}
        />
        {!!autoCompleteValue.length && (
          <ul className={style.listAutoComplete}>
            {autoCompleteValue.map((patient) => {
              return (
                <li
                  className={style.itemAutoComplete}
                  key={patient.id}
                  onClick={() => {
                    handleSelected(patient);
                  }}
                >
                  {patient.firstName}
                </li>
              );
            })}
          </ul>
        )}
        {loading && <LinearProgress color="secondary" className={style.progressBar} />}
      </div>
      <div className={style.secondaryField}>
        <span className={style.label}>Patient email</span>
        <BaseTextInput
          name={nameSecondaryField}
          disabled
          value={fieldSecondary.value}
          error={metaSecondary.error}
          touched={metaSecondary.touched}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

PatientSearch.defaultProps = {
  disabled: false,
};

PatientSearch.propTypes = {
  namePrimaryField: PropTypes.string.isRequired,
  nameSecondaryField: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(PatientSearch);
