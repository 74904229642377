/* eslint-disable react/prop-types */
import React from 'react';
import style from './editButton.module.css';

const EditButton = ({ handlerClick, size = '44px' }) => {
  return (
    <div
      className={style.editButton}
      style={{
        height: size,
        width: size,
      }}
      onClick={handlerClick}
    >
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.748 0.234229L9.0504 1.93183H9.0512L12.4448 5.32543L14.1424 3.62863C14.4547 3.31623 14.4547 2.80983 14.1424 2.49743L11.8792 0.234229C11.5668 -0.0780764 11.0604 -0.0780764 10.748 0.234229ZM14.4 12.7766H4.9944H4.9936L11.3136 6.45743L7.92 3.06223L0 10.9822V14.3766H14.4V12.7766Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default React.memo(EditButton);
