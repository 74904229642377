import React, { useEffect, useState } from 'react';
import style from './searchPage.module.css';
import { useLocation } from 'react-router';
import { Button, Container } from '@material-ui/core';
import Loader from '../../components/loader/loader';
import SearchGroup from '../../forms/searchGroup/searchGroup';
import axios from './../../API/configAPI';
import Pagination from '@material-ui/lab/Pagination';
import ViewImage from '../../components/viewImage/viewImage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router';
import { PATIENT_REQUEST, PROFILE_DOCTORS } from '../../constants/locationPath';
import LinkToProfile from '../../components/linkToProfile/linkToProfile';
import { DOCTOR_ROLE } from '../../constants/userRole';
import NumberFormat from 'react-number-format';
import { FORMAT_PHONE } from '../../constants/formatValueInput';

const SearchPage = () => {
  const COUNT_SHOW_ON_PAGE = 8;
  const history = useHistory();
  const { state } = useLocation();
  const [initValues, setInitValues] = useState(state);
  const [searchValues, setSearchValues] = useState(state);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const matches = useMediaQuery('(max-width:576px)');

  const handlerSearchDoctor = (values) => {
    setSearchValues(values);
    if (page === 1) {
      getDoctors(values);
    } else {
      setPage(1);
    }
  };

  useEffect(() => {
    if (initValues?.name || initValues?.speciality || initValues?.location) {
      getDoctors(state);
      setInitValues({});
    } else if (searchValues?.name || searchValues?.speciality || searchValues?.location) {
      getDoctors(searchValues);
    } else {
      getDoctors();
    }
  }, [page]);

  const getDoctors = (searchValue) => {
    setIsLoading(true);
    setDoctorList([]);
    const name = searchValue?.name || '';
    const speciality = searchValue?.speciality || '';
    const location = searchValue?.location || '';
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    axios
      .get(
        `user/search/doctors?take=${COUNT_SHOW_ON_PAGE}&skip=${skip}&name=${name}&speciality=${speciality}&location=${location}`,
      )
      .then(({ data: { results, total } }) => {
        setDoctorList(results);
        if (total <= COUNT_SHOW_ON_PAGE) {
          setTotalPages(0);
        } else {
          setTotalPages(Math.ceil(total / COUNT_SHOW_ON_PAGE));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const handlerViewProfile = (id) => {
    history.push(`${PROFILE_DOCTORS}/${id}`);
  };

  const handlerAppointMeeting = (id) => {
    history.push(`${PATIENT_REQUEST}/${id}`);
  };

  const handlerGetAll = () => {
    getDoctors();
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <Container>
          <h3 className={style.title}>Search results</h3>
          <div className={style.actions}>
            <SearchGroup handleSubmitValues={handlerSearchDoctor} searchValues={initValues} />
          </div>
        </Container>
      </div>
      <div className={style.content}>
        <Container className={style.container}>
          {isLoading && <Loader />}
          {!isLoading && doctorList.length !== 0 && (
            <ul className={style.doctorList}>
              {doctorList.map(({ id, firstName, lastName, avatarId, phone, speciality, city, address }) => (
                <li key={id} className={style.grid}>
                  <div className={style.gridCell}>
                    <ViewImage imageId={avatarId} size="51px" borderRadius="8px" />
                    <div className={style.doctorInfo}>
                      <LinkToProfile
                        firstName={firstName}
                        lastName={lastName}
                        role={DOCTOR_ROLE}
                        userId={id}
                        fontSize="large"
                      />
                      <span className={style.doctorSpecialities}>{speciality}</span>
                    </div>
                  </div>
                  <div className={style.gridCell}>
                    <span className={style.doctorPhone}>
                      <NumberFormat value={phone} displayType={'text'} format={FORMAT_PHONE} />
                    </span>
                  </div>
                  <div className={style.gridCell}>
                    <span className={style.doctorAddress}>{city}</span>
                  </div>
                  <div className={style.gridCell}>
                    <span className={style.doctorAddress}>{address}</span>
                  </div>
                  <div className={style.gridCell}>
                    <Button className={style.button} variant="outlined" onClick={() => handlerViewProfile(id)}>
                      View profile
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!isLoading && doctorList.length === 0 && (
            <div className={style.notFoundBlock}>
              <h3 className={style.notFoundBlockTitle}>Not found</h3>
              <Button className={style.button} variant="contained" color="primary" onClick={handlerGetAll}>
                Get all
              </Button>
            </div>
          )}
          {totalPages !== 0 && (
            <div className={style.paginationWrapper}>
              <Pagination
                siblingCount={Number(!matches)}
                count={totalPages}
                color="secondary"
                onChange={handleChangePagination}
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default React.memo(SearchPage);
