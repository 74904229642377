import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import FlipCameraButton from './flipCameraButton/flipCameraButton';
import { Grid } from '@material-ui/core';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import EndCallButton from '../buttons/endCallButton/endCallButton';
import ToggleAudioButton from '../buttons/toggleAudioButton/toggleAudioButton';
import ToggleVideoButton from '../buttons/toggleVideoButton/toggleVideoButton';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'transparent',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    containerChatOpen: {
      right: '30%',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    button: {
      borderRadius: '4rem',
      width: '4rem',
      height: '4rem',
    },
  }),
);

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { chatOpen } = useAppState();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <>
      <footer className={[classes.container, chatOpen ? classes.containerChatOpen : ''].join(' ')}>
        <Grid container justify="space-around" alignItems="center">
          <Grid container justify="center">
            <ToggleAudioButton className={classes.button} disabled={isReconnecting} />
            <ToggleVideoButton className={classes.button} disabled={isReconnecting} />
            <FlipCameraButton />
            {/* <Menu /> */}
            <EndCallButton />
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
