import React, { useEffect } from 'react';
import style from './bidPlannerToolbar.module.css';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import dayJs from './../../../helpers/getDayJsWithTimezone';

const BigPlannerToolbar = (toolbar) => {
  const MONTH = 'month';
  const WEEK = 'week';
  const DAY = 'day';
  const AGENDA = 'agenda';
  const ADD = 'add';
  const SUBTRACT = 'subtract';

  useEffect(() => {
    switch (toolbar.view) {
      case MONTH:
      case AGENDA:
        return toolbar.onNavigate('goToStartMonth', dayJs(toolbar.date).startOf(MONTH).toDate());
      case WEEK:
        return toolbar.onNavigate('goToStartWeek', dayJs(toolbar.date).startOf(WEEK).toDate());
      default:
        break;
    }
  }, [toolbar.view]);

  const goToNextOrBack = (action) => {
    let mDate = dayJs(toolbar.date);
    let newDate = null;
    switch (toolbar.view) {
      case MONTH:
      case AGENDA:
        newDate = mDate.startOf(MONTH)[action](1, MONTH);
        break;
      case WEEK:
        newDate = mDate[action](1, WEEK);
        break;
      case DAY:
        newDate = mDate[action](1, DAY);
        break;
      default:
        break;
    }
    toolbar.onNavigate(action === ADD ? 'next' : 'prev', newDate.toDate());
  };

  const goToCurrent = () => {
    toolbar.onNavigate('current', new Date());
  };

  const labelDisplay = () => {
    const mDate = dayJs(toolbar.date);
    return (
      <>
        {(toolbar.view === MONTH || toolbar.view === AGENDA) && (
          <span>
            <b>{mDate.format('MMMM')}</b>
            <span> {mDate.format('YYYY')}</span>
          </span>
        )}
        {toolbar.view === WEEK && (
          <span>
            <b>{mDate.startOf(WEEK).format('DD')}</b>
            <span> {mDate.startOf(WEEK).format('MMMM')}</span>
            <span> - </span>
            <b>{mDate.endOf(WEEK).format('DD')}</b>
            <span> {mDate.endOf(WEEK).format('MMMM')}</span>
            <span> {mDate.endOf(WEEK).format('YYYY')}</span>
          </span>
        )}
        {toolbar.view === DAY && (
          <span>
            <b>{mDate.format('DD')}</b>
            <span> {mDate.format('MMMM')}</span>
            <span> {mDate.format('YYYY')}</span>
          </span>
        )}
      </>
    );
  };

  const goToView = (view) => {
    toolbar.onView(view);
  };

  return (
    <div className={style.toolbarWrapper}>
      <ButtonGroup variant="contained" color="secondary" aria-label="contained secondary button group">
        <Button onClick={() => goToNextOrBack(SUBTRACT)}>&#10092;</Button>
        <Button onClick={goToCurrent}>Today</Button>
        <Button onClick={() => goToNextOrBack(ADD)}>&#10093;</Button>
      </ButtonGroup>
      <label className={style.label}>{labelDisplay()}</label>
      <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
        {toolbar.views.map((view, index) => (
          <Button className={style.button} key={index} disabled={toolbar.view === view} onClick={() => goToView(view)}>
            {view}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default BigPlannerToolbar;
