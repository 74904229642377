import { createMuiTheme } from '@material-ui/core/styles';

const getPalette = (nameTheme: string) => {
  switch (nameTheme) {
    case 'light':
      return {
        primary: {
          main: '#D13B2A',
        },
        secondary: {
          main: '#1B2A4A',
        },
        background: {
          default: '#fff',
        },
        text: {
          primary: '#1D2239',
          secondary: '#8E909C',
        },
      };
    case 'dark':
      return {
        primary: {
          main: '#FE5D26',
        },
        secondary: {
          main: '#19857b',
        },
        background: {
          default: '#1a191e',
          paper: '#1a191e',
        },
        text: {
          primary: '#fff',
          secondary: 'rgba(255, 255, 255, 0.7)',
          disabled: 'rgba(255, 255, 255, 0.5)',
          hint: 'rgba(255, 255, 255, 0.5)',
        },
      };
  }
};

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
  }
}

const useTheme = (nameTheme: string) => () => {
  const paletteOptions = getPalette(nameTheme);
  return createMuiTheme({
    palette: paletteOptions,
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 16,
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 100,
          textTransform: 'none',
        },
      },
      MuiButtonGroup: {
        root: {
          borderRadius: 100,
        },
      },
      MuiFormControl: {
        root: {
          '& .MuiInputBase-root': {
            '& .MuiInputBase-input': {
              fontSize: 'inherit',
              padding: '12px 16px',
            },
            '& .MuiFormLabel-root': {
              fontSize: 'inherit',
            },
          },
          '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 15px) scale(1)',
          },
          '& .MuiFormLabel-root': {
            fontSize: 'inherit',
          },
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
    brand: '#E22525',
    footerHeight: 112,
    mobileFooterHeight: 110,
    sidebarWidth: 355,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
    mobileTopBarHeight: 52,
  });
};

export default useTheme;
