import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './addLabResult.module.css';
import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import FormikControl from './../formikControl';
import { createLabResultSchema } from './../validationSchema';
import axios from './../../API/configAPI';
import dayjs from './../../helpers/getDayJsWithTimezone';
import getFormattedDate from './../../helpers/getFormattedDate';
import Loader from '../../components/loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import { useHistory } from 'react-router';
import getFormattedId from '../../helpers/getFormattedPhotoId';

const AddLabResult = ({ labResultId, patientId, handleSave }) => {
  const DELETE = '[AddLabResult] Delete medical report';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [labResult, setLabResult] = useState(null);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Delete ',
    description: `
      Do you want to delete Medical Report?
    `,
    actions: [
      {
        title: 'Cancel',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Ok',
        answerModal: DELETE,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  useEffect(() => {
    if (labResultId) {
      getLabResult(labResultId);
    }
  }, [labResultId]);

  const getLabResult = (id) => {
    setIsLoading(true);
    axios
      .get(`lab-result/${id}`)
      .then(({ data: labResult }) => {
        setLabResult(labResult);
        handleSave(labResult);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (answerModal === DELETE) {
      dispatch(setModalAnswer(''));

      axios
        .delete(`lab-result/${labResult.id || labResultId}`)
        .then(({ data }) => {
          handlerBack();
        })
        .catch((error) => {});
    }
  }, [answerModal]);

  const initialValues = {
    title: labResult?.title || '',
    creationDate: labResult?.creationDate ? getFormattedDate(labResult.creationDate) : new Date(),
    reference: labResult?.reference || '',
    description: labResult?.description || '',
    patientId: patientId,
    file0: getFormattedId(labResult?.fileId0) || null,
    file1: getFormattedId(labResult?.fileId1) || null,
    file2: getFormattedId(labResult?.fileId2) || null,
  };

  const onSubmit = (values, { setSubmitting }) => {
    const requestType = labResult?.id ? 'patch' : 'post';
    const requestUrl = labResult?.id ? `lab-result/${labResult?.id}` : 'lab-result';
    axios[requestType](requestUrl, {
      ...values,
      creationDate: dayjs(values.creationDate).format('YYYY-MM-DD'),
    })
      .then(({ data: labResult }) => {
        setSubmitting(false);
        setLabResult(labResult);
        handleSave(labResult);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const handlerBack = () => {
    history.goBack();
  };

  const handlerDelete = () => {
    dispatch(setModalContent(contentModal));
  };

  return (
    <div className={style.wrapper}>
      {isLoading && <Loader />}
      {!isLoading && (
        <Formik initialValues={initialValues} validationSchema={createLabResultSchema} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting, values }) => (
            <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
              <div className={style.filesGrid}>
                <FormikControl control="fileUpload" name="file0" label="Attach medical reports" />
                {values.file0 && <FormikControl control="fileUpload" name="file1" label="Attach medical reports" />}
                {values.file1 && <FormikControl control="fileUpload" name="file2" label="Attach medical reports" />}
              </div>
              <div className={style.formGrid}>
                <FormikControl
                  control="textInput"
                  label="Medical report title"
                  type="text"
                  name="title"
                  placeholder="Add title"
                />
                <FormikControl control="datepicker" label="Date of analysis" name="creationDate" />
              </div>
              <FormikControl
                control="textarea"
                label="Reference"
                type="text"
                name="reference"
                placeholder="Add reference"
              />
              <FormikControl
                control="textarea"
                label="Medical report notes"
                type="text"
                name="description"
                placeholder="Add notes"
                styleInput={{ minHeight: '350px' }}
              />
              <div className={style.actions}>
                <Button className={style.button} variant="outlined" color="secondary" onClick={handlerBack}>
                  Back
                </Button>
                <Button
                  className={style.button}
                  variant="contained"
                  color="secondary"
                  disabled={!labResult?.id}
                  onClick={handlerDelete}
                >
                  Delete
                </Button>
                <Button
                  className={style.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {labResult?.id ? 'Save' : 'Create'}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

AddLabResult.defaultProps = {
  labResultId: '',
};

AddLabResult.propTypes = {
  labResultId: PropTypes.string,
  patientId: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default React.memo(AddLabResult);
