/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './formComponents.module.css';
import { useField } from 'formik';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD } from '../../constants/locationPath';

const PasswordInput = ({ label, description, forgot, ...props }) => {
  const [field, meta] = useField(props);
  const [isShow, setIsShow] = useState(false);

  const handleClickShowPassword = () => {
    setIsShow(!isShow);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={style.wrapper}>
      {label && (
        <label className={style.label} htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      {forgot && (
        <Link to={FORGOT_PASSWORD} className={style.forgotPassword}>
          Forgot password?
        </Link>
      )}
      <div className={[style.wrapperInput, meta.touched && meta.error ? style.errorInput : null].join(' ')}>
        <input
          style={props.styleInput}
          type={isShow ? 'text' : 'password'}
          className={style.textInput}
          {...field}
          placeholder={props.placeholder}
        />
        <span className={style.icon} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 4C5.90909 4 2.41545 6.488 1 10C2.41545 13.512 5.90909 16 10 16C14.0909 16 17.5845 13.512 19 10C17.5845 6.488 14.0909 4 10 4ZM10 14C7.74182 14 5.90909 12.208 5.90909 10C5.90909 7.792 7.74182 6 10 6C12.2582 6 14.0909 7.792 14.0909 10C14.0909 12.208 12.2582 14 10 14ZM10 7.6C8.64182 7.6 7.54545 8.672 7.54545 10C7.54545 11.328 8.64182 12.4 10 12.4C11.3582 12.4 12.4545 11.328 12.4545 10C12.4545 8.672 11.3582 7.6 10 7.6Z"
              fill={isShow ? '#1b2a4a' : '#BBBCC3'}
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="4" width="18" height="12">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 4C5.90909 4 2.41545 6.488 1 10C2.41545 13.512 5.90909 16 10 16C14.0909 16 17.5845 13.512 19 10C17.5845 6.488 14.0909 4 10 4ZM10 14C7.74182 14 5.90909 12.208 5.90909 10C5.90909 7.792 7.74182 6 10 6C12.2582 6 14.0909 7.792 14.0909 10C14.0909 12.208 12.2582 14 10 14ZM10 7.6C8.64182 7.6 7.54545 8.672 7.54545 10C7.54545 11.328 8.64182 12.4 10 12.4C11.3582 12.4 12.4545 11.328 12.4545 10C12.4545 8.672 11.3582 7.6 10 7.6Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0)"></g>
          </svg>
        </span>
      </div>
      {meta.touched && meta.error ? (
        <span className={[style.message, style.error].join(' ')}>{meta.error}</span>
      ) : (
        <span className={[style.message, style.description].join(' ')}>{description}</span>
      )}
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  styleInput: PropTypes.object,
  placeholder: PropTypes.string,
  forgot: PropTypes.bool,
};

export default React.memo(PasswordInput);
