import React, { useEffect, useState } from 'react';
import style from './createAppointment.module.css';
import { Formik } from 'formik';
import FormikControl from '../formikControl';
import Loader from '../../components/loader/loader';
import { createAppointmentSchema } from './../validationSchema';
import { Button } from '@material-ui/core';
import axios from '../../API/configAPI';
import BaseCheckbox from './../baseFormControl/baseCheckbox/baseCheckbox';
import { useHistory, useLocation } from 'react-router';
import { DOCTOR_CREATE_PATIENT, DOCTOR_DASHBOARD } from '../../constants/locationPath';
import dayJs from './../../helpers/getDayJsWithTimezone';
import getFormattedId from './../../helpers/getFormattedPhotoId';
import PatientSearch from '../../components/patientSearch/patientSearch';
import { CONFIRMED } from './../../constants/appointmentStatus';
import { useSelector } from 'react-redux';
import dayjs from './../../helpers/getDayJsWithTimezone';

const CreateAppointment = () => {
  const doctor = useSelector((state) => state.auth.user);
  const [appointment, setAppointment] = useState(null);
  const [scheduleDoctor, setScheduleDoctor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [telehealthCheckbox, setTelehealthCheckbox] = useState(true);
  const [locationCheckbox, setLocationCheckbox] = useState(false);
  const history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.appointmentId) {
      setIsLoading(true);
      axios
        .get(`appointment/${state.appointmentId}`)
        .then(({ data: appointment }) => {
          setAppointment(appointment);
          if (appointment.location) {
            handlerChangeCheckbox();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [state]);

  useEffect(() => {
    if (doctor.scheduleId) {
      axios.get(`schedule/${doctor.scheduleId}`).then(({ data: schedule }) => {
        setScheduleDoctor(schedule);
      });
    }
  }, [doctor.scheduleId]);

  const initialValues = {
    status: CONFIRMED,
    date: appointment ? dayjs(appointment.start).tz().toDate() : new Date(),
    start: appointment ? dayjs(appointment.start).tz().format('HH:mm') : '09:00',
    end: appointment ? dayjs(appointment.end).tz().format('HH:mm') : '09:30',
    patientId: '',
    email: '',
    scheduleId: doctor?.scheduleId || '',
    telehealth: appointment?.id || '',
    location: appointment?.location || '',
    note: appointment?.note || '',
    labResults: getFormattedId(appointment?.labResultsId) || null,
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (!locationCheckbox) {
      values.location = '';
    }

    const { date, start, end } = values;
    const eventDate = dayJs(date).format('YYYY-MM-DD');
    const startSplit = start.split(':');
    const endSplit = end.split(':');
    const formattedStart = dayJs.tz(eventDate).hour(startSplit[0]).minute(startSplit[1]).format();
    const formattedEnd = dayJs.tz(eventDate).hour(endSplit[0]).minute(endSplit[1]).format();

    const requestType = appointment?.id ? 'patch' : 'post';
    const url = appointment?.id ? `appointment/${appointment?.id}` : 'appointment';

    axios[requestType](url, {
      ...values,
      start: formattedStart,
      end: formattedEnd,
    })
      .then(({ data }) => {
        setSubmitting(false);
        resetForm();
        history.push(DOCTOR_DASHBOARD);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handlerCancel = () => {
    history.goBack();
  };

  const handlerAddPatient = () => {
    history.push(DOCTOR_CREATE_PATIENT);
  };

  const handlerChangeCheckbox = () => {
    setTelehealthCheckbox(!telehealthCheckbox);
    setLocationCheckbox(!locationCheckbox);
  };

  const onChangeLocal = (name, value, optionsForm) => {
    if (name === 'start' && value && scheduleDoctor?.appointmentTime) {
      const startSplit = value.split(':');
      const durationSplit = scheduleDoctor.appointmentTime.split(':');
      const formattedStart = dayjs.tz().hour(startSplit[0]).minute(startSplit[1]).second(0);
      const startPlusDuration = formattedStart.add(durationSplit[0], 'hour').add(durationSplit[1], 'minute');
      const helpers = optionsForm.getFieldHelpers('end');
      helpers.setValue(startPlusDuration.format('HH:mm'));
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Formik initialValues={initialValues} validationSchema={createAppointmentSchema} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting, ...optionsForm }) => (
            <form className={style.form} autoComplete="off" onSubmit={handleSubmit}>
              <PatientSearch namePrimaryField="patientId" nameSecondaryField="email" />
              <div className={style.flexGrid}>
                <FormikControl control="fileUpload" name="labResults" label="Attach medical reports" />
                <Button variant="contained" color="secondary" onClick={handlerAddPatient}>
                  + add patient
                </Button>
              </div>
              <div className={style.lineGrid}>
                <FormikControl control="datepicker" label="Date" name="date" minToDay />
                <div className={style.lineGridSecond}>
                  <div>
                    <span className={style.label}>Start</span>
                    <FormikControl
                      control="timePicker"
                      name="start"
                      onChange={(name, value) => {
                        onChangeLocal(name, value, optionsForm);
                      }}
                    />
                  </div>
                  <div>
                    <span className={style.label}>End</span>
                    <FormikControl control="timePicker" name="end" />
                  </div>
                </div>
              </div>
              <h3 className={style.subtitle}>Communication method:</h3>
              <div className={style.lineGrid}>
                <div className={style.lineSubGrid}>
                  <BaseCheckbox
                    onChange={handlerChangeCheckbox}
                    value={telehealthCheckbox}
                    checked={telehealthCheckbox}
                  />
                  <FormikControl control="textInput" label="Telehealth" type="text" name="telehealth" disabled={true} />
                </div>
                <div className={style.lineSubGrid}>
                  <BaseCheckbox onChange={handlerChangeCheckbox} value={locationCheckbox} checked={locationCheckbox} />
                  <FormikControl
                    control="textInput"
                    label="Location"
                    type="text"
                    name="location"
                    placeholder="Enter meeting address"
                    disabled={!locationCheckbox}
                  />
                </div>
              </div>
              <div>
                <FormikControl
                  control="textarea"
                  label="Note"
                  type="textarea"
                  name="note"
                  placeholder="Write some notes"
                />
              </div>
              <div className={style.actions}>
                <Button className={style.button} variant="outlined" onClick={handlerCancel}>
                  Cancel
                </Button>
                <Button
                  className={style.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Create
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default React.memo(CreateAppointment);
