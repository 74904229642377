import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module'
import App from './app';
import './index.css';
import './style-font-icons.css';
import { store } from './store';
import AppStateProvider from './state';

if (process.env.REACT_APP_ENV === 'prod') {
  const tagManagerArgs = {
    gtmId: 'GTM-PD3X5WG'
  }

  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppStateProvider>
        <App />
      </AppStateProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
