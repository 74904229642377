import React from 'react';
import SignInForm from '../../forms/authorization/signInForm';
import { Single } from '../../layouts/single/single';
import { useAuth } from './../../hooks/authHook';

export const SignInPage = () => {
  const { logout } = useAuth();
  logout();
  return (
    <Single>
      <SignInForm />
    </Single>
  );
};
