import React, { useState, useEffect } from 'react';
import style from './doctorRegistrationPage.module.css';
import PersonalData from '../../forms/doctorsRegistration/personalData/personalData';
import DoctorsData from '../../forms/doctorsRegistration/doctorsData/doctorsData';
import OfficeDetails from '../../forms/doctorsRegistration/officeDetails/officeDetails';
import Subscribe from '../../forms/doctorsRegistration/subscribe/subscribe';
import { Container } from '@material-ui/core';
import { Tabs } from './../../components/tabs/tabs';
import axios from './../../API/configAPI';
import Loader from '../../components/loader/loader';
import { useHistory } from 'react-router-dom';
import { DOCTOR_DASHBOARD } from '../../constants/locationPath';

const DoctorRegistrationPage = () => {
  const labels = ['1 Personal Data', "2 Doctor's Data", '3 Office Details', '4 Subscribe'];

  const contentTabs = (indexActive, nextStep, backStep) => {
    switch (indexActive) {
      case 0:
        return <PersonalData nextStep={nextStep} />;
      case 1:
        return <DoctorsData nextStep={nextStep} backStep={backStep} />;
      case 2:
        return <OfficeDetails nextStep={nextStep} backStep={backStep} />;
      case 3:
        return <Subscribe nextStep={nextStep} backStep={backStep} />;
    }
  };

  const [indexActive, setIndexActive] = useState(0);
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const requestsUser = axios.get('auth/me');
    const requestsCard = axios.get('card');

    Promise.all([requestsUser, requestsCard])
      .then(([{ data: user }, { data: card }]) => {
        setUser(user);

        if (!user.personalData) {
          return setIndexActive(0);
        }

        if (!user.doctorData) {
          return setIndexActive(1);
        }

        if (!user.offices.length) {
          return setIndexActive(2);
        }

        if (!card?.id) {
          return setIndexActive(3);
        }

        return history.push(DOCTOR_DASHBOARD);
      })
      .catch((error) => {});
  }, []);

  const backStep = () => {
    setIndexActive(indexActive - 1);
  };

  const nextStep = () => {
    handlerNextStep();
  };

  const handlerNextStep = () => {
    const indexNext = indexActive + 1;
    if (labels.length === indexNext) {
      return history.push(DOCTOR_DASHBOARD);
    }
    setIndexActive(indexNext);
  };

  return (
    <>
      {!user && <Loader />}
      {user && (
        <Container className={style.wrapper}>
          <div className={style.wrapperGrid}>
            <div className={style.header}>
              <Tabs toggleLabel={labels} indexActive={indexActive} />
            </div>

            <div className={style.contents}>{contentTabs(indexActive, nextStep, backStep)}</div>
          </div>
        </Container>
      )}
    </>
  );
};

export default React.memo(DoctorRegistrationPage);
