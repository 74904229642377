import React, { useEffect, useState } from 'react';
import style from './doctorSchedule.module.css';
import axios from '../../../API/configAPI';
import Loader from '../../../components/loader/loader';
import { Formik } from 'formik';
import FormikControl from '../../formikControl';
import { Button } from '@material-ui/core';
import { doctorScheduleSchema } from '../../validationSchema';
import { DAYS_OF_WEEK_FULL_NAME } from '../../../constants/daysOfWeek';
import BaseCheckbox from '../../baseFormControl/baseCheckbox/baseCheckbox';
import { useHistory } from 'react-router';
import { SETTING } from '../../../constants/locationPath';
import { useSelector } from 'react-redux';

const DoctorSchedule = () => {
  const INIT_START_TIME = '08:00';
  const INIT_END_TIME = '17:00';
  const INIT_APPOINTMENT_TIME = '00:30';

  const initialStateDaysOfWeek = {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  };

  const [schedule, setSchedule] = useState({});
  const [daysOfWeek, setDaysOfWeek] = useState(initialStateDaysOfWeek);
  const [allChecked, setAllChecked] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user.scheduleId) {
      setIsLoading(true);
      axios
        .get(`schedule/${user.scheduleId}`)
        .then(({ data: schedule }) => {
          setSchedule(schedule);
          setDaysOfWeek({
            sunday: !!schedule.sunStart || false,
            monday: !!schedule.monStart || false,
            tuesday: !!schedule.tueStart || false,
            wednesday: !!schedule.wedStart || false,
            thursday: !!schedule.thuStart || false,
            friday: !!schedule.friStart || false,
            saturday: !!schedule.satStart || false,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const all = {
      sunday: allChecked,
      monday: allChecked,
      tuesday: allChecked,
      wednesday: allChecked,
      thursday: allChecked,
      friday: allChecked,
      saturday: allChecked,
    };
    setDaysOfWeek(all);
  }, [allChecked]);

  const initialValues = {
    sunStart: schedule.sunStart ? schedule.sunStart.slice(0, 5) : INIT_START_TIME,
    sunEnd: schedule.sunEnd ? schedule.sunEnd.slice(0, 5) : INIT_END_TIME,
    monStart: schedule.monStart ? schedule.monStart.slice(0, 5) : INIT_START_TIME,
    monEnd: schedule.monEnd ? schedule.monEnd.slice(0, 5) : INIT_END_TIME,
    tueStart: schedule.tueStart ? schedule.tueStart.slice(0, 5) : INIT_START_TIME,
    tueEnd: schedule.tueEnd ? schedule.tueEnd.slice(0, 5) : INIT_END_TIME,
    wedStart: schedule.wenStart ? schedule.wenStart.slice(0, 5) : INIT_START_TIME,
    wedEnd: schedule.wenEnd ? schedule.wenEnd.slice(0, 5) : INIT_END_TIME,
    thuStart: schedule.thuStart ? schedule.thuStart.slice(0, 5) : INIT_START_TIME,
    thuEnd: schedule.thuEnd ? schedule.thuEnd.slice(0, 5) : INIT_END_TIME,
    friStart: schedule.friStart ? schedule.friStart.slice(0, 5) : INIT_START_TIME,
    friEnd: schedule.friEnd ? schedule.friEnd.slice(0, 5) : INIT_END_TIME,
    satStart: schedule.satStart ? schedule.satStart.slice(0, 5) : INIT_START_TIME,
    satEnd: schedule.satEnd ? schedule.satEnd.slice(0, 5) : INIT_END_TIME,
    appointmentTime: schedule.appointmentTime ? schedule.appointmentTime.slice(0, 5) : INIT_APPOINTMENT_TIME,
  };

  const onSubmit = (values, { setSubmitting }) => {
    const pushNewSchedule = {
      sunStart: daysOfWeek.sunday ? values.sunStart : null,
      sunEnd: daysOfWeek.sunday ? values.sunEnd : null,
      monStart: daysOfWeek.monday ? values.monStart : null,
      monEnd: daysOfWeek.monday ? values.monEnd : null,
      tueStart: daysOfWeek.tuesday ? values.tueStart : null,
      tueEnd: daysOfWeek.tuesday ? values.tueEnd : null,
      wedStart: daysOfWeek.wednesday ? values.wedStart : null,
      wedEnd: daysOfWeek.wednesday ? values.wedEnd : null,
      thuStart: daysOfWeek.thursday ? values.thuStart : null,
      thuEnd: daysOfWeek.thursday ? values.thuEnd : null,
      friStart: daysOfWeek.friday ? values.friStart : null,
      friEnd: daysOfWeek.friday ? values.friEnd : null,
      satStart: daysOfWeek.saturday ? values.satStart : null,
      satEnd: daysOfWeek.saturday ? values.satEnd : null,
      appointmentTime: values.appointmentTime,
    };

    const requestType = user?.scheduleId ? 'put' : 'post';

    axios[requestType]('schedule', { ...pushNewSchedule })
      .then(() => {
        setSubmitting(false);
        handlerCancel();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const handlerChangeAllDays = () => {
    setAllChecked(!allChecked);
  };

  const handlerChangeActiveDay = (dayName, dayNameStart, dayNameEnd, formOptions) => {
    if (daysOfWeek[dayName]) {
      formOptions.getFieldHelpers(dayNameStart).setValue(INIT_START_TIME);
      formOptions.getFieldHelpers(dayNameEnd).setValue(INIT_END_TIME);
    }

    setDaysOfWeek({
      ...daysOfWeek,
      [dayName]: !daysOfWeek[dayName],
    });
  };

  const handlerCancel = () => {
    history.push(SETTING, 'appointments');
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Formik initialValues={initialValues} validationSchema={doctorScheduleSchema} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting, ...formOptions }) => (
            <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
              <div className={style.formHeader}>
                <BaseCheckbox checked={allChecked} label="Choose all" onChange={() => handlerChangeAllDays()} />
              </div>
              {DAYS_OF_WEEK_FULL_NAME.map((dayName, index) => {
                const dayNameLowerCase = dayName.toLowerCase();
                const dayNameStart = `${dayNameLowerCase.slice(0, 3)}Start`;
                const dayNameEnd = `${dayNameLowerCase.slice(0, 3)}End`;
                return (
                  <div key={index} className={style.formWeek}>
                    <BaseCheckbox
                      label={dayName}
                      onChange={() => handlerChangeActiveDay(dayNameLowerCase, dayNameStart, dayNameEnd, formOptions)}
                      value={daysOfWeek[dayNameLowerCase]}
                      checked={daysOfWeek[dayNameLowerCase]}
                    />
                    <div className={style.formTime}>
                      {daysOfWeek[dayNameLowerCase] && (
                        <>
                          <span className={style.titleTimeStart}>Set time:</span>
                          <FormikControl control="timePicker" name={dayNameStart} />
                          <span className={style.titleTimeEnd}>to</span>
                          <FormikControl control="timePicker" name={dayNameEnd} />
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className={style.description}>
                <p>*All times Trinidad and Tobago (-4&nbsp;GMT)</p>
              </div>
              <div>
                <h4 className={style.titleAppointment}>Appointment duration:</h4>
                <div className={style.wrapperAppointmentInput}>
                  <FormikControl control="timePicker" name="appointmentTime" timeFormat24 />
                </div>
              </div>
              <div className={style.actions}>
                <Button className={style.button} variant="outlined" onClick={handlerCancel}>
                  Cancel
                </Button>
                <Button
                  className={style.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default React.memo(DoctorSchedule);
