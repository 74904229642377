import React from 'react';
import PropTypes from 'prop-types';
import style from './baseCheckbox.module.css';

const BaseCheckbox = ({ label, description, name, value, checked, onChange, onBlur, error, touched }) => {
  return (
    <label className={style.labelCheckbox}>
      {label}
      <input
        type="checkbox"
        className={style.checkboxInput}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className={style.box}>
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.12667 5.87833L5.30333 7.055L12.3583 0L13.5367 1.17833L5.30333 9.41167L0 4.10833L1.17833 2.93L2.94917 4.70083L4.12667 5.8775V5.87833Z"
            fill="white"
          />
        </svg>
      </span>
      {touched && error ? (
        <span className={[style.message, style.error].join(' ')}>{error}</span>
      ) : (
        description && <span className={[style.message, style.description].join(' ')}>{description}</span>
      )}
    </label>
  );
};

BaseCheckbox.defaultProps = {
  label: '',
  description: '',
  value: false,
  error: '',
  touched: false,
  onBlur: () => {},
  name: '',
};

BaseCheckbox.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

export default React.memo(BaseCheckbox);
