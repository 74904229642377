/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import style from './tabs.module.css';

export const Tabs = ({ toggleLabel, indexActive }) => {
  const [isActive, setActive] = useState(0);

  useEffect(() => {
    setActive(indexActive);
  }, [indexActive]);

  return (
    <ul className={style.tabsList}>
      {toggleLabel.map((label, index) => {
        return (
          <li key={index} className={[style.tabItem, isActive === index && style.active].join(' ')}>
            {label}
          </li>
        );
      })}
    </ul>
  );
};
