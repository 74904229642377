import React from 'react';
import PropTypes from 'prop-types';
import style from './prescriptionPreview.module.css';
import { useHistory } from 'react-router';
import ViewImage from '../viewImage/viewImage';
import { PRESCRIPTION_DETAILS } from '../../constants/locationPath';
import dayjs from 'dayjs';

const PrescriptionPreview = ({
  id,
  creationDate,
  description,
  avatarId,
  firstName,
  lastName,
  specialities,
  isMobileView,
}) => {
  const history = useHistory();

  const handlerClick = () => {
    history.push(`${PRESCRIPTION_DETAILS}/${id}`);
  };

  return (
    <>
      <div
        className={[style.prescriptionWrapper, isMobileView && style.mobileView].join(' ')}
        onClick={() => handlerClick()}
      >
        <ViewImage imageId={avatarId} size="51px" borderRadius="8px" />
        <div className={style.doctorInfo}>
          <h4 className={style.doctorName}>
            <span>{firstName}</span>
            &ensp;
            <span>{lastName}</span>
          </h4>
          <span className={style.doctorSpeciality}>{specialities}</span>
        </div>
        <div className={style.dateCreate}>{dayjs(creationDate).format('DD MMM YYYY')}</div>
        <p className={style.description}>{description}</p>
      </div>
    </>
  );
};

PrescriptionPreview.defaultProps = {
  isMobileView: false,
};

PrescriptionPreview.propTypes = {
  id: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  avatarId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  specialities: PropTypes.string.isRequired,
  isMobileView: PropTypes.bool,
};

export default React.memo(PrescriptionPreview);
