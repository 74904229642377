import React from 'react';
import PropTypes from 'prop-types';
import style from './searchGroup.module.css';
import { Button } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Formik } from 'formik';
import FormikControl from '../formikControl';

const SearchGroup = ({ handleSubmitValues, searchValues }) => {
  const inputSearchStyle = {
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '100px',
    border: 'none',
  };

  const initialValues = {
    name: searchValues?.name || '',
    speciality: searchValues?.speciality || '',
    location: searchValues?.location || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        handleSubmitValues(values);
      }}
    >
      {({ handleSubmit }) => (
        <form className={style.search} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className={style.wrapperFormControl}>
            <FormikControl
              control="textInput"
              type="text"
              name="name"
              placeholder="Doctor's name"
              styleInput={inputSearchStyle}
            />
          </div>
          <div className={style.wrapperFormControl}>
            <FormikControl
              control="textInput"
              type="text"
              name="speciality"
              placeholder="Speciality"
              styleInput={inputSearchStyle}
            />
          </div>
          <div className={style.wrapperFormControl}>
            <FormikControl
              control="textInput"
              type="text"
              name="location"
              placeholder="Location"
              styleInput={inputSearchStyle}
            />
          </div>
          <Button
            className={style.searchButton}
            variant="contained"
            color="primary"
            startIcon={<Icon>search</Icon>}
            type="submit"
          >
            Search
          </Button>
        </form>
      )}
    </Formik>
  );
};

SearchGroup.defaultProps = {
  searchValues: {
    name: '',
    speciality: '',
    location: '',
  },
};

SearchGroup.propTypes = {
  handleSubmitValues: PropTypes.func.isRequired,
  searchValues: PropTypes.shape({
    name: PropTypes.string,
    speciality: PropTypes.string,
    location: PropTypes.string,
  }),
};

export default React.memo(SearchGroup);
