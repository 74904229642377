import React from 'react';
import PropTypes from 'prop-types';
import style from './main.module.css';
import Header from '../header/header';
import Footer from '../footer/footer';

const Main = ({ children }) => {
  return (
    <div className={style.mainGrid}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.element,
};

export default React.memo(Main);
