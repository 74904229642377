import React from 'react';
import PropTypes from 'prop-types';
import style from './formComponents.module.css';
import { useField } from 'formik';

const Textarea = ({ label, description, disabled, id, name, styleInput, placeholder }) => {
  const [field, meta] = useField(name);

  return (
    <div className={style.wrapper}>
      {label && (
        <label className={style.label} htmlFor={id || name}>
          {label}
        </label>
      )}
      <div className={[style.wrapperInput, meta.touched && meta.error && style.errorInput].join(' ')}>
        <textarea
          disabled={disabled}
          style={styleInput}
          className={style.textInput}
          {...field}
          placeholder={placeholder}
        ></textarea>
      </div>
      {meta.touched && meta.error ? (
        <span className={[style.message, style.error].join(' ')}>{meta.error}</span>
      ) : (
        <span className={[style.message, style.description].join(' ')}>{description}</span>
      )}
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  styleInput: PropTypes.object,
  placeholder: PropTypes.string,
};

export default React.memo(Textarea);
