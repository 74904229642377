import { createSlice } from '@reduxjs/toolkit';

// const contentModal = {
//   isClosedByOverlay: boolean,
//   title: 'I am title',
//   description: 'description mast be inside, in me',
//   actions: [{
//     title: 'ok',
//     answerModal: 'string',
//     variant: 'contained',
//     color: 'primary',
//   }],
// };

const toolkitSlice = createSlice({
  name: 'modal',
  initialState: {
    content: null,
    answer: '',
  },
  reducers: {
    setModalContent(state, action) {
      state.content = action.payload;
    },
    setModalAnswer(state, action) {
      state.answer = action.payload;
    },
  },
});

export default toolkitSlice.reducer;
export const { setModalContent, setModalAnswer } = toolkitSlice.actions;
