import React from 'react';
import PropTypes from 'prop-types';
import style from './dialogBlockedTime.module.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Formik } from 'formik';
import FormikControl from './../../forms/formikControl';
import axios from './../../API/configAPI';
import { doctorBlockedTimeSchema } from './../../forms/validationSchema';
import * as dayjs from 'dayjs';

const DialogBlockedTime = ({ handleClose, isOpen, calendarDate, handleCloseReload, edit }) => {
  const INIT_START_TIME = '08:00';
  const INIT_END_TIME = '17:00';

  const initialValues = edit
    ? {
        start: dayjs(edit.start).tz().format('HH:mm'),
        end: dayjs(edit.end).tz().format('HH:mm'),
      }
    : {
        start: INIT_START_TIME,
        end: INIT_END_TIME,
      };

  const onSubmit = (values, { setSubmitting }) => {
    const addDate = dayjs(calendarDate).format('YYYY-MM-DD');
    const addStart = values.start.split(':');
    const addEnd = values.end.split(':');

    const addValues = {
      start: dayjs.tz(addDate).hour(addStart[0]).minute(addStart[1]).format(),
      end: dayjs.tz(addDate).hour(addEnd[0]).minute(addEnd[1]).format(),
    };

    const requestType = edit?.id ? 'put' : 'post';
    const requestUrl = edit?.id ? `blocked-time/${edit.id}` : 'blocked-time';

    axios[requestType](requestUrl, addValues)
      .then(() => {
        setSubmitting(false);
        handleCloseReload();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const handlerDeleteBlockedTime = (setSubmittingHook) => {
    if (!edit && !edit.id) return;
    setSubmittingHook(true);
    axios
      .delete(`blocked-time/${edit.id}`)
      .then(() => {
        handleCloseReload();
        setSubmittingHook(false);
      })
      .catch(() => {
        setSubmittingHook(false);
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Formik initialValues={initialValues} validationSchema={doctorBlockedTimeSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, setSubmitting }) => (
          <form className={style.form} autoComplete="off" onSubmit={handleSubmit}>
            <div className={style.formContent}>
              <span className={style.titleTimeStart}>Set time:</span>
              <FormikControl control="timePicker" name="start" />
              <span className={style.titleTimeEnd}>to</span>
              <FormikControl control="timePicker" name="end" />
            </div>
            <div className={[style.actions, edit && style.actionsGrid].join(' ')}>
              <Button className={style.button} variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className={style.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {edit ? 'Edit' : 'Save'}
              </Button>
              {edit && (
                <Button
                  className={style.button}
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => handlerDeleteBlockedTime(setSubmitting)}
                >
                  Delete
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

DialogBlockedTime.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCloseReload: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  calendarDate: PropTypes.string.isRequired,
  edit: PropTypes.object,
};

export default React.memo(DialogBlockedTime);
