import React, { useState, useEffect } from 'react';
import style from './createLabResultPage.module.css';
import { Container, LinearProgress } from '@material-ui/core';
import AddLabResult from '../../forms/addLabResult/addLabResult';
import AddLabValue from '../../forms/addLabValue/addLabValue';
import LabValueListItem from '../../components/labValueListItem/labValueListItem';
import { useLocation } from 'react-router';
import axios from './../../API/configAPI';
import dayJs from './../../helpers/getDayJsWithTimezone';

const CreateLabResultPage = () => {
  const { state } = useLocation();
  const [labResult, setLabResult] = useState(null);
  const [listValues, setListValues] = useState([]);
  const [editValuesId, setEditValuesId] = useState(state?.valueId);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (labResult?.id) {
      getListValues();
    }
  }, [labResult]);

  const getListValues = () => {
    setIsLoading(true);
    axios
      .get(`result-value?labResultId=${labResult?.id}`)
      .then(({ data: { results } }) => {
        setListValues(results);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const saveLabResult = (labResult) => {
    setLabResult(labResult);
  };

  const saveLabValue = () => {
    getListValues();
  };

  const handlerEditValue = (id) => {
    setEditValuesId(id);
  };

  return (
    <Container>
      {state?.patientId && (
        <div className={style.wrapper}>
          <div className={style.header}>
            <h3 className={style.title}>View/Edit medical report</h3>
          </div>
          <div className={style.content}>
            <div className={style.forms}>
              <AddLabResult labResultId={state?.resultId} patientId={state.patientId} handleSave={saveLabResult} />
              {labResult?.id && (
                <AddLabValue labValueId={editValuesId} labResultId={labResult.id} handleSave={saveLabValue} />
              )}
            </div>
            {labResult?.id && (
              <div className={style.printView}>
                <div className={style.printHeader}>
                  <span className={style.printDate}>{dayJs(labResult.creationDate).format('DD.MM.YYYY')}</span>
                  <h3 className={style.printTitle}>{labResult.title}</h3>
                </div>
                <div className={style.printContent}>
                  {labResult.reference && (
                    <>
                      <h5 className={style.printLabel}>Reference:</h5>
                      <p>{labResult.reference}</p>
                    </>
                  )}
                  {labResult.description && (
                    <>
                      <h5 className={style.printLabel}>Description:</h5>
                      <p>{labResult.description}</p>
                    </>
                  )}
                </div>
              </div>
            )}
            <ul className={style.list}>
              {isLoading && <LinearProgress />}
              {!!listValues.length && (
                <>
                  <div className={style.labHeader}>
                    <span>Component</span>
                    <span>Value</span>
                    <span>Unit</span>
                    <span>Flag</span>
                    <span>Standard range</span>
                    <span>Comment</span>
                    <span>Date</span>
                  </div>
                  {listValues.map((item) => (
                    <li key={item.id} className={style.listItem} onClick={() => handlerEditValue(item.id)}>
                      <LabValueListItem labValueItem={item} />
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </Container>
  );
};

export default React.memo(CreateLabResultPage);
