/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import { NavLink, useLocation } from 'react-router-dom';
import style from './authorization.module.css';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/authHook';
import { SIGN_UP } from '../../constants/locationPath';
import { signInSchema, codeSchema } from '../validationSchema';
import axios from '../../API/configAPI';
import FormikControl from '../formikControl';

const SignInForm = () => {
  const { state: location } = useLocation();
  const { login } = useAuth();
  const history = useHistory();
  const RESEND_LINK = '[SignInForm] Resend link';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [stepCode, setStepCode] = useState(false);

  useEffect(() => {
    if (answerModal === RESEND_LINK) {
      dispatch(setModalAnswer(''));
      axios.get(`auth/resend-verification/${email}`).then(() => {
        dispatch(setModalContent(contentModal));
      });
    }
  }, [answerModal]);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Email is not confirmed',
    description: `
      The email confirmation link was sent to your address. 
      Check it, please.
    `,
    actions: [
      {
        title: 'Resend link',
        answerModal: RESEND_LINK,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const initialValues2fa = {
    email: email,
    password: password,
    code: '',
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setEmail(values.email);
    setPassword(values.password);

    if (values.twoFA) {
      return axios
        .post('auth/2fa-token', {
          email: values.email,
          password: values.password,
        })
        .then(({ data }) => {
          setSubmitting(false);
          setStepCode(true);
        })
        .catch(() => {
          setSubmitting(false);
        });
    }

    axios
      .post('auth/login', { ...values })
      .then(({ data: { accessToken, user } }) => {
        resetForm();
        setSubmitting(false);
        login(accessToken, user);
        if (location?.from?.pathname) {
          history.push(location.from.pathname);
        } else {
          history.push(`/${user.role}`);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (error?.response?.data?.message === 'Email is not confirmed') {
          dispatch(setModalContent(contentModal));
        }
      });
  };

  const onSubmit2fa = (values, { setSubmitting, resetForm }) => {
    axios
      .post('auth/2fa-login', { ...values })
      .then(({ data: { accessToken, user } }) => {
        resetForm();
        setSubmitting(false);
        login(accessToken, user);
        if (location?.from?.pathname) {
          history.push(location.from.pathname);
        } else {
          history.push(`/${user.role}`);
        }
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2 className={style.title}>Welcome to Ibis</h2>
        <p className={style.subtitle}>
          {stepCode ? 'Enter code to continue.' : 'Enter your email and Password to continue.'}
        </p>
      </div>
      {!stepCode && (
        <Formik initialValues={initialValues} validationSchema={signInSchema} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting }) => (
            <form className={style.form} onSubmit={handleSubmit}>
              <FormikControl control="textInput" label="Email" type="email" name="email" placeholder="Email" />
              <FormikControl
                control="passwordInput"
                label="Password"
                type="password"
                name="password"
                placeholder="8+ characters"
                forgot={true}
              />
              <FormikControl
                control="checkboxInput"
                type="checkbox"
                name="twoFA"
                label="Keep me login with 2FA for 5 days"
              />
              <Button
                className={style.button}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={isSubmitting}
              >
                Sign In
              </Button>
              <p>
                Don’t have an account?&ensp;
                <NavLink to={SIGN_UP}>
                  <strong>Sign Up</strong>
                </NavLink>
              </p>
            </form>
          )}
        </Formik>
      )}
      {stepCode && (
        <Formik initialValues={initialValues2fa} validationSchema={codeSchema} onSubmit={onSubmit2fa}>
          {({ handleSubmit, isSubmitting }) => (
            <form className={style.form} onSubmit={handleSubmit}>
              <FormikControl control="textInput" label="Code" type="text" name="code" placeholder="Code" />
              <Button
                className={style.button}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={isSubmitting}
              >
                Sign In
              </Button>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default SignInForm;
