import React, { useState, useEffect } from 'react';
import style from './prescriptionsPage.module.css';
import { Button, Container } from '@material-ui/core';
import Loader from '../../components/loader/loader';
import { useHistory, useParams } from 'react-router';
import { DOCTOR_CREATE_PRESCRIPTION, DOCTOR_PATIENT_PROFILE, PATIENT_DASHBOARD } from '../../constants/locationPath';
import { DOCTOR_ROLE, PATIENT_ROLE } from '../../constants/userRole';
import Pagination from '@material-ui/lab/Pagination';
import ViewImage from '../../components/viewImage/viewImage';
import axios from '../../API/configAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import PrescriptionPreview from './../../components/prescriptionPreview/prescriptionPreview';
import LinkToProfile from '../../components/linkToProfile/linkToProfile';

const PrescriptionsPage = () => {
  const COUNT_SHOW_ON_PAGE = 4;

  const history = useHistory();
  const matches = useMediaQuery('(max-width:576px)');
  const userCurrentAuth = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (id) {
      axios
        .get(`user/${id}`)
        .then(({ data: patient }) => {
          setPatient(patient);
        })
        .catch((error) => {});
    }
  }, [id]);

  useEffect(() => {
    getPrescriptions();
  }, [patient, id, page]);

  const getPrescriptions = () => {
    if (patient && patient.id) {
      setIsLoading(true);
      setPrescriptionsList([]);
      const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
      axios
        .get(`prescription?patientId=${patient.id}&take=${COUNT_SHOW_ON_PAGE}&skip=${skip}`)
        .then(({ data: { results, total } }) => {
          setPrescriptionsList(results);
          if (total <= COUNT_SHOW_ON_PAGE) {
            setTotalPages(0);
          } else {
            setTotalPages(Math.ceil(total / COUNT_SHOW_ON_PAGE));
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const handlerAddPrescription = () => {
    if (patient && patient.id) {
      history.push(`${DOCTOR_CREATE_PRESCRIPTION}/${patient.id}`);
    }
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {!patient && <Loader />}
      {patient && (
        <div className={style.wrapper}>
          <div className={style.header}>
            <Container>
              <h3 className={style.title}>Prescriptions</h3>
              {userCurrentAuth.role === DOCTOR_ROLE && (
                <div className={style.actions}>
                  <div className={style.patientCurrent}>
                    <ViewImage imageId={patient.personalData.avatarId} size="51px" borderRadius="14px" />
                    <div className={style.patientInfo}>
                      <LinkToProfile
                        firstName={patient?.personalData?.firstName}
                        lastName={patient?.personalData?.lastName}
                        role={PATIENT_ROLE}
                        userId={patient?.id}
                        fontSize="large"
                      />
                      <span className={style.patientStatus}>{patient.role}</span>
                    </div>
                  </div>
                  <Button
                    className={style.addButton}
                    variant="contained"
                    color="primary"
                    onClick={handlerAddPrescription}
                  >
                    + Add Prescription
                  </Button>
                </div>
              )}
            </Container>
          </div>
          <div className={style.content}>
            <Container className={style.container}>
              {isLoading && <Loader />}
              {!isLoading && prescriptionsList.length !== 0 && (
                <ul className={style.prescriptionsList}>
                  {prescriptionsList.map(
                    ({ id, creationDate, description, avatarId, firstName, lastName, specialities }) => (
                      <li key={id} className={style.prescriptionListItem}>
                        <PrescriptionPreview
                          id={id}
                          creationDate={creationDate}
                          description={description}
                          avatarId={avatarId}
                          firstName={firstName}
                          lastName={lastName}
                          specialities={specialities}
                        />
                      </li>
                    ),
                  )}
                </ul>
              )}
              {!isLoading && prescriptionsList.length === 0 && (
                <div className={style.notFoundBlock}>
                  <h3 className={style.notFoundBlockTitle}>Here is no prescriptions yet</h3>
                </div>
              )}
              {totalPages !== 0 && (
                <div className={style.paginationWrapper}>
                  <Pagination
                    siblingCount={Number(!matches)}
                    count={totalPages}
                    color="secondary"
                    onChange={handleChangePagination}
                  />
                </div>
              )}
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(PrescriptionsPage);
