import React, { useEffect, useState, useRef } from 'react';
import style from './invoiceDetailsPage.module.css';
import { useHistory, useLocation, useParams } from 'react-router';
import axios from '../../API/configAPI';
import Loader from '../../components/loader/loader';
import { Container, Button, useMediaQuery } from '@material-ui/core';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { setModalContent, setModalAnswer } from '../../store/modalSlice';
import { DOCTOR_CREATE_INVOICE, INVOICES } from '../../constants/locationPath';
import { Formik } from 'formik';
import FormikControl from './../../forms/formikControl';
import { PATIENT_ROLE } from '../../constants/userRole';
import CreditCard from '../../components/creditCard/creditCard';
import DocumentPDF from '../../components/documentPDF/documentPDF';
import jsPDF from 'jspdf';
import domToImage from 'dom-to-image';
import NumberFormat from 'react-number-format';
import { FORMAT_PHONE, PREFIX } from '../../constants/formatValueInput';

const InvoiceDetailsPage = () => {
  const DELETE_INVOICE = '[InvoiceDetailsPage] Delete invoice';
  const REDIRECT_TO_LIST = '[InvoiceDetailsPage] Redirect to list';
  const DOWNLOAD_EVIDENCE = '[InvoiceDetailsPage] Download evidence';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const { id } = useParams();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:992px)');
  const currentUser = useSelector((state) => state.auth.user);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [patient, setPatient] = useState(null);
  const htmlRef = useRef();

  const options = [
    { displayName: 'Credit Card', value: 'credit card' },
    { displayName: 'Bank Transfer', value: 'bank transfer' },
    { displayName: 'Cheque', value: 'cheque' },
    { displayName: 'Cash', value: 'cash' },
  ];

  const initialValues = {
    paymentMethod: options[0].value,
  };

  const [methodPayValue, setMethodPayValue] = useState(options[0].value);
  const [openDialogCreditCard, setOpenDialogCreditCard] = useState(false);
  const { state: stateLocation } = useLocation();

  useEffect(() => {
    if (stateLocation === 'downloadPDF') {
      if (invoiceDetails && doctor && patient) {
        exportPDF();
      }
    }
  }, [invoiceDetails, doctor, patient, stateLocation]);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Delete invoice?',
    description: `Do you want to delete the current invoice?`,
    actions: [
      {
        title: 'No',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Yes',
        answerModal: DELETE_INVOICE,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const contentModalSuccessful = {
    isClosedByOverlay: true,
    title: 'Successful payment!',
    description: `You can download payment evidence`,
    actions: [
      {
        title: 'Cancel',
        answerModal: REDIRECT_TO_LIST,
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Download',
        answerModal: DOWNLOAD_EVIDENCE,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const getInvoiceDetails = () => {
    axios.get(`invoice/${id}`).then(({ data: details }) => {
      setInvoiceDetails(details);
    });
  };

  useEffect(() => {
    getInvoiceDetails();
  }, [id]);

  useEffect(() => {
    if (invoiceDetails?.patientId) {
      axios
        .get(`user/${invoiceDetails?.patientId}`)
        .then(({ data: patient }) => {
          setPatient(patient);
        })
        .catch((error) => {});
    }
    if (invoiceDetails?.doctorId) {
      axios
        .get(`user/${invoiceDetails?.doctorId}`)
        .then(({ data: doctor }) => {
          setDoctor(doctor);
        })
        .catch((error) => {});
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (answerModal === DELETE_INVOICE) {
      dispatch(setModalAnswer(''));
      axios.delete(`invoice/${id}`).then(() => {
        handleCancel();
      });
    }

    if (answerModal === REDIRECT_TO_LIST) {
      dispatch(setModalAnswer(''));
    }

    if (answerModal === DOWNLOAD_EVIDENCE) {
      dispatch(setModalAnswer(''));
      exportPDF();
    }
  }, [answerModal]);

  const exportPDF = () => {
    domToImage.toPng(htmlRef.current).then((dataUrl) => {
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(dataUrl, 'PNG', 0, 0, 210, 297);
      pdf.save(`invoice-${invoiceDetails.number}.pdf`);
    });
  };

  const handleCancel = () => {
    history.push(INVOICES);
  };

  const handleEdit = () => {
    history.push(`${DOCTOR_CREATE_INVOICE}`, invoiceDetails);
  };

  const handleDelete = () => {
    dispatch(setModalContent(contentModal));
  };

  const handlerSelectChange = (value) => {
    setMethodPayValue(value);
  };

  const handlePay = () => {
    switch (methodPayValue) {
      case options[0].value:
        setOpenDialogCreditCard(true);
        break;
      case options[1].value:
        payment();
        break;
      case options[2].value:
        payment();
        break;
      case options[3].value:
        payment();
        break;
      default:
        break;
    }
  };

  const handelCreditCardValue = (valueCreditCard) => {
    if (!valueCreditCard) {
      return setOpenDialogCreditCard(false);
    }
    payment(valueCreditCard);
    setOpenDialogCreditCard(false);
  };

  const payment = (creditCardValue) => {
    setIsSubmitting(true);

    const value = {
      invoiceId: invoiceDetails.id,
      amount: invoiceDetails.total,
      paymentMethod: methodPayValue,
      cardNumber: creditCardValue?.number || '',
      expiries: creditCardValue?.expiries || '',
      cvv: creditCardValue?.cvv || '',
    };

    axios
      .post('payment', value)
      .then(() => {
        getInvoiceDetails();
        dispatch(setModalContent(contentModalSuccessful));
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {invoiceDetails && doctor && patient && (
        <div style={{ position: 'fixed', top: '200vh' }}>
          <div ref={htmlRef}>
            <DocumentPDF invoice={invoiceDetails} doctor={doctor} patient={patient} />
          </div>
        </div>
      )}
      {!(invoiceDetails && doctor && patient) && <Loader />}
      {invoiceDetails && doctor && patient && (
        <Container>
          <div className={style.wrapper}>
            <div className={style.header}>
              <h3 className={style.title}>Invoice</h3>
              <div className={style.subheader}>
                <div className={style.info}>
                  <span className={style.number}>{invoiceDetails.number}</span>
                  <span className={[style.status, style[invoiceDetails.status]].join(' ')}>
                    {invoiceDetails.status}
                  </span>
                </div>
                <div className={style.dateWrapper}>
                  <span className={style.dateLabel}>Invoice date</span>
                  <span className={style.dateValue}>{dayjs(invoiceDetails.invoiceDate).format('DD.MM.YYYY')}</span>
                </div>
                <div className={style.dateWrapper}>
                  <span className={style.dateLabel}>Due date</span>
                  <span className={style.dateValue}>{dayjs(invoiceDetails.dueDate).format('DD.MM.YYYY')}</span>
                </div>
              </div>
            </div>
            <div className={style.members}>
              <div className={style.member}>
                <h4 className={style.subtitle}>Doctor’s information</h4>
                <ul className={style.memberInfo}>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Doctors name</span>
                    <span
                      className={style.value}
                    >{`${doctor.personalData.firstName} ${doctor.personalData.lastName}`}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Office name</span>
                    <span className={style.value}>{doctor.offices[0].name}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Phone number</span>
                    <span className={style.value}>
                      <NumberFormat value={doctor.offices[0].phone} displayType={'text'} format={FORMAT_PHONE} />
                    </span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Business email</span>
                    <span className={style.value}>{doctor.offices[0].email}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Address</span>
                    <span className={style.value}>{`${doctor.offices[0].country || ''} ${
                      doctor.offices[0].state || ''
                    } ${doctor.offices[0].city || ''} ${doctor.offices[0].zioCode || ''} ${
                      doctor.offices[0].address || ''
                    }`}</span>
                  </li>
                </ul>
              </div>
              <div className={style.member}>
                <h4 className={style.subtitle}>Patient information</h4>
                <ul className={style.memberInfo}>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Patient name</span>
                    <span
                      className={style.value}
                    >{`${patient.personalData.firstName} ${patient.personalData.lastName}`}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Phone number</span>
                    <span className={style.value}>
                      <NumberFormat value={patient.personalData.phone} displayType={'text'} format={FORMAT_PHONE} />
                    </span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Email</span>
                    <span className={style.value}>{patient.email}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.services}>
              <h4 className={style.subtitle}>Services</h4>
              <div>
                {!matches && (
                  <div className={style.gridServices}>
                    <span>Descriptions</span>
                    <span>Billing type</span>
                    <span>Quantity</span>
                    <span>Amount</span>
                    <span>Subtotal</span>
                  </div>
                )}
                <div className={style.servicesWrapper}>
                  {invoiceDetails.services.map(({ id, description, billingType, quantity, amount, subtotal }) => (
                    <div key={id} className={style.gridServices}>
                      <div className={style.service}>
                        <span className={style.serviceLabel}>Descriptions</span>
                        <span className={style.serviceValue}>{description}</span>
                      </div>
                      <div className={[style.service]}>
                        <span className={style.serviceLabel}>Billing type</span>
                        <span className={style.serviceValue}>{billingType}</span>
                      </div>
                      <div className={style.service}>
                        <span className={style.serviceLabel}>Quantity</span>
                        <span className={style.serviceValue}>{quantity}</span>
                      </div>
                      <div className={style.service}>
                        <span className={style.serviceLabel}>Amount</span>
                        <span className={style.serviceValue}>{amount}</span>
                      </div>
                      <div className={style.service}>
                        <span className={style.serviceLabel}>Subtotal</span>
                        <span className={[style.serviceValue, style.serviceSubtotal].join(' ')}>
                          <NumberFormat
                            value={subtotal || 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={PREFIX}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {invoiceDetails.status !== 'paid' && (
              <Formik initialValues={initialValues}>
                {() => (
                  <form autoComplete="off" className={style.form}>
                    <FormikControl
                      control="selectInput"
                      options={options}
                      name="paymentMethod"
                      placeholder="Payment method"
                      label="Payment method"
                      disabled={currentUser.role === PATIENT_ROLE}
                      onChange={handlerSelectChange}
                    />
                  </form>
                )}
              </Formik>
            )}
            <div className={style.wrapperTotal}>
              <div className={style.total}>
                <span className={style.labelTotal}>Grand Total</span>
                <span className={style.counterTotal}>
                  <NumberFormat
                    value={invoiceDetails.total}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={PREFIX}
                  />
                </span>
              </div>
            </div>
            <div
              className={[style.actions, currentUser.id === invoiceDetails?.doctorId && style.actionsGrid].join(' ')}
            >
              <Button className={style.button} variant="outlined" onClick={handleCancel} disabled={isSubmitting}>
                Cancel
              </Button>
              {invoiceDetails.status !== 'paid' && (
                <Button
                  className={style.button}
                  variant="contained"
                  color="primary"
                  onClick={handlePay}
                  disabled={isSubmitting}
                >
                  Pay
                </Button>
              )}
              {currentUser.id === invoiceDetails?.doctorId && (
                <>
                  {invoiceDetails.status !== 'paid' && (
                    <Button
                      className={style.button}
                      variant="outlined"
                      color="primary"
                      onClick={handleEdit}
                      disabled={isSubmitting}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    className={style.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                    disabled={isSubmitting}
                  >
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
        </Container>
      )}
      <CreditCard onClose={handelCreditCardValue} open={openDialogCreditCard} />
    </>
  );
};

export default React.memo(InvoiceDetailsPage);
