/* eslint-disable react/prop-types */
import React from 'react';
import style from './single.module.css';
import { Container } from '@material-ui/core';
import bgBird from './../../assets/images/single/bgBird.png';
import { DARK_THEME_LOGO } from './../../constants/themeLogo';
import Logo from './../../components/logo/logo';

export const Single = ({ children }) => {
  return (
    <section className={[style.wrapper, style.gradientVerticalMirageWhite].join(' ')}>
      <div className={style.bgImgWrapper}>
        <div className={style.bgImg}>
          <img src={bgBird} alt="bgBird" />
        </div>
        <Container>
          <div className={style.logo}>
            <Logo themeLogo={DARK_THEME_LOGO} />
          </div>
          {children}
        </Container>
      </div>
    </section>
  );
};
