import React from 'react';

export default function ClipIcon() {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8284 5.75689L7.17245 11.4139C6.91249 11.665 6.80823 12.0368 6.89975 12.3864C6.99126 12.736 7.26431 13.0091 7.61394 13.1006C7.96357 13.1921 8.33537 13.0878 8.58645 12.8279L14.2434 7.17189C15.4151 6.00022 15.4151 4.10056 14.2434 2.92889C13.0718 1.75722 11.1721 1.75722 10.0004 2.92889L4.34345 8.58589C3.05369 9.84354 2.54017 11.6978 2.99918 13.4397C3.4582 15.1817 4.81877 16.5421 6.56081 17.0008C8.30284 17.4596 10.157 16.9458 11.4144 15.6559L17.0714 9.99989L18.4854 11.4139L12.8284 17.0709C11.06 18.8394 8.48239 19.53 6.06662 18.8827C3.65085 18.2354 1.76392 16.3485 1.11662 13.9327C0.469318 11.5169 1.15998 8.93935 2.92845 7.17089L8.58645 1.51489C10.5483 -0.379973 13.6669 -0.352874 15.5955 1.5758C17.5242 3.50446 17.5513 6.62299 15.6564 8.58489L10.0004 14.2439C9.24233 15.0018 8.13744 15.2977 7.10199 15.0202C6.06654 14.7426 5.25783 13.9337 4.98049 12.8982C4.70315 11.8626 4.99933 10.7578 5.75745 9.99989L11.4144 4.34289L12.8284 5.75689Z"
        fill="#BBBCC3"
      />
    </svg>
  );
}
