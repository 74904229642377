import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './createRequest.module.css';
import { Formik } from 'formik';
import FormikControl from '../formikControl';
import Loader from '../../components/loader/loader';
import { createRequestSchema } from '../validationSchema';
import { Button } from '@material-ui/core';
import axios from '../../API/configAPI';
import BaseCheckbox from '../baseFormControl/baseCheckbox/baseCheckbox';
import { useHistory, useLocation } from 'react-router';
import { PATIENT_DASHBOARD } from '../../constants/locationPath';
import dayjs from '../../helpers/getDayJsWithTimezone';
import ScheduleTable from '../../layouts/scheduleTable/scheduleTable';
import BlockedTime from '../../layouts/blocketTime/blockedTime';
import { useSelector, useDispatch } from 'react-redux';
import { setAppointmentsOfDay, setBlockedTimeOfDay, setCalendarDate } from '../../store/scheduleSlice';
import { PATIENT_ROLE } from '../../constants/userRole';
import { UNCONFIRMED } from '../../constants/appointmentStatus';
import getFormattedPhotoId from '../../helpers/getFormattedPhotoId';

const CreateRequest = ({ doctorId, doctorScheduleId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarDate = useSelector((state) => state.schedule.calendarDate);
  const appointmentsOfDay = useSelector((state) => state.schedule.appointmentsOfDay);
  const blockedTimeOfDay = useSelector((state) => state.schedule.blockedTimeOfDay);
  const scheduleOfWeek = useSelector((state) => state.schedule.scheduleOfWeek);
  const { state } = useLocation();

  const [patient, setPatient] = useState(null);
  const [telehealthCheckbox, setTelehealthCheckbox] = useState(true);
  const [locationCheckbox, setLocationCheckbox] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const blockedTimeOfDayWithAppointmentsOfDay = blockedTimeOfDay
      .concat(appointmentsOfDay)
      .sort((prev, next) => +dayjs(prev.start) - +dayjs(next.start));
    dispatch(setBlockedTimeOfDay(blockedTimeOfDayWithAppointmentsOfDay));

    return () => {
      dispatch(setBlockedTimeOfDay([]));
    };
  }, [appointmentsOfDay]);

  useEffect(() => {
    axios.get('auth/me').then(({ data: user }) => {
      setPatient(user);
    });
  }, []);

  useEffect(() => {
    const formattedDate = dayjs(calendarDate).format('YYYY-MM-DD');
    axios
      .get(`appointment/search?userId=${doctorId}&date=${formattedDate}&status=confirmed`)
      .then(({ data: { results } }) => {
        dispatch(setAppointmentsOfDay(results));
      });

    return () => {
      dispatch(setAppointmentsOfDay([]));
    };
  }, [calendarDate]);

  useEffect(() => {
    const currentDay = dayjs(calendarDate).format('ddd').toLowerCase();
    const scheduleStart = scheduleOfWeek[currentDay + 'Start'];
    const scheduleEnd = scheduleOfWeek[currentDay + 'End'];
    // console.log(blockedTimeOfDay, scheduleStart, scheduleEnd, scheduleOfWeek.appointmentTime);
  }, [blockedTimeOfDay, scheduleOfWeek]);

  useEffect(() => {
    if (state && state.appointmentId) {
      setIsLoading(true);
      axios
        .get(`appointment/${state.appointmentId}`)
        .then(({ data: appointment }) => {
          setAppointment(appointment);
          if (appointment.location) {
            handlerChangeCheckbox();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [state]);

  const initialValues = {
    status: UNCONFIRMED,
    start: appointment ? dayjs(appointment.start).tz().format('HH:mm') : '09:00',
    end: appointment ? dayjs(appointment.end).tz().format('HH:mm') : '09:30',
    patientId: patient?.id || '',
    email: patient?.email || '',
    scheduleId: doctorScheduleId,
    telehealth: appointment?.id || '',
    location: appointment?.location || '',
    note: appointment?.note || '',
    labResults: getFormattedPhotoId(appointment?.labResultsId) || null,
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (!locationCheckbox) {
      values.location = '';
    }
    const { start, end } = values;
    const eventDate = dayjs(calendarDate).format('YYYY-MM-DD');
    const startSplit = start.split(':');
    const endSplit = end.split(':');
    const formattedStart = dayjs.tz(eventDate).hour(startSplit[0]).minute(startSplit[1]).second(0).format();
    const formattedEnd = dayjs.tz(eventDate).hour(endSplit[0]).minute(endSplit[1]).second(0).format();

    const requestType = appointment?.id ? 'patch' : 'post';
    const url = appointment?.id ? `appointment/${appointment?.id}` : 'appointment';

    axios[requestType](url, {
      ...values,
      start: formattedStart,
      end: formattedEnd,
    })
      .then(({ data }) => {
        setSubmitting(false);
        resetForm();
        history.push(PATIENT_DASHBOARD);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handlerCancel = () => {
    history.goBack();
  };

  const handlerChangeCheckbox = () => {
    setTelehealthCheckbox(!telehealthCheckbox);
    setLocationCheckbox(!locationCheckbox);
  };

  const onChangeLocal = (name, value, optionsForm) => {
    if (name === 'start' && value && scheduleOfWeek?.appointmentTime) {
      const startSplit = value.split(':');
      const durationSplit = scheduleOfWeek.appointmentTime.split(':');
      const formattedStart = dayjs.tz().hour(startSplit[0]).minute(startSplit[1]).second(0);
      const startPlusDuration = formattedStart.add(durationSplit[0], 'hour').add(durationSplit[1], 'minute');
      const helpers = optionsForm.getFieldHelpers('end');
      helpers.setValue(startPlusDuration.format('HH:mm'));
    }
  };

  return (
    <>
      {isLoading && !patient && <Loader />}
      {!isLoading && patient && (
        <Formik initialValues={initialValues} validationSchema={createRequestSchema} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting, ...optionsForm }) => (
            <form className={style.formGrid} autoComplete="off" onSubmit={handleSubmit}>
              <div className={style.gridCell}>
                <ScheduleTable scheduleId={doctorScheduleId} />
              </div>
              <div className={style.gridCell}>
                <h3 className={style.subtitle}>Choose date and time</h3>
                <div className={style.lineGridSecond}>
                  <div>
                    <span className={style.label}>Start</span>
                    <FormikControl
                      control="timePicker"
                      name="start"
                      onChange={(name, value) => {
                        onChangeLocal(name, value, optionsForm);
                      }}
                    />
                  </div>
                  <div>
                    <span className={style.label}>End</span>
                    <FormikControl control="timePicker" name="end" />
                  </div>
                </div>
                <BlockedTime scheduleId={doctorScheduleId} initialDate={appointment?.start} />
              </div>
              <div className={style.gridCell}>
                <h3 className={style.subtitle}>Communication method:</h3>
                <div className={style.lineGrid}>
                  <div className={style.lineSubGrid}>
                    <BaseCheckbox
                      onChange={handlerChangeCheckbox}
                      value={telehealthCheckbox}
                      checked={telehealthCheckbox}
                    />
                    <FormikControl
                      control="textInput"
                      label="Telehealth"
                      type="text"
                      name="telehealth"
                      disabled={true}
                    />
                  </div>
                  <div className={style.lineSubGrid}>
                    <BaseCheckbox
                      onChange={handlerChangeCheckbox}
                      value={locationCheckbox}
                      checked={locationCheckbox}
                    />
                    <FormikControl
                      control="textInput"
                      label="Location"
                      type="text"
                      name="location"
                      placeholder="Enter meeting address"
                      disabled={!locationCheckbox}
                    />
                  </div>
                </div>
              </div>
              <div className={style.gridCell}>
                <h3 className={style.subtitle}>Appointment notes</h3>
                <FormikControl
                  control="textarea"
                  label="Note"
                  type="textarea"
                  name="note"
                  placeholder="Write some notes"
                />
                <div className={style.fileWrapper}>
                  <FormikControl control="fileUpload" name="labResults" label="Attach medical reports" />
                </div>
              </div>
              <div className={style.actions}>
                <Button className={style.button} variant="outlined" onClick={handlerCancel}>
                  Cancel
                </Button>
                <Button
                  className={style.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Create
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

CreateRequest.propTypes = {
  doctorId: PropTypes.string.isRequired,
  doctorScheduleId: PropTypes.string.isRequired,
};

export default React.memo(CreateRequest);
