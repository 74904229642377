import React from 'react';

export default function VideoOffIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="27.6026" fill="white" stroke="#EDEEF0" stroke-width="0.794839" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.2845 22.4091L32.5455 25.7273V21.9091C32.5455 21.407 32.1384 21 31.6364 21H18.9091C18.407 21 18 21.407 18 21.9091V34.6364C18 35.1384 18.407 35.5455 18.9091 35.5455H31.6364C32.1384 35.5455 32.5455 35.1384 32.5455 34.6364V30.8182L37.2845 34.1364C37.4235 34.2337 37.605 34.2457 37.7555 34.1673C37.9059 34.0889 38.0002 33.9333 38 33.7636V22.7818C38.0002 22.6122 37.9059 22.4566 37.7555 22.3782C37.605 22.2998 37.4235 22.3117 37.2845 22.4091ZM23.4545 24.6364H21.6364V26.4545H23.4545V24.6364Z"
        fill="#1D2239"
      />
      <rect x="16.1005" y="20.929" width="2" height="24" transform="rotate(-45 16.1005 20.929)" fill="#1D2239" />
      <rect x="17.5147" y="19.5149" width="2" height="24" transform="rotate(-45 17.5147 19.5149)" fill="white" />
    </svg>
  );
}
