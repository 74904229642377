import React, { useState, useEffect, useCallback } from 'react';
import style from './patientRegistrationPage.module.css';
import PersonalData from '../../forms/patientRegistration/personalData/personalData';
import { Container } from '@material-ui/core';
import axios from './../../API/configAPI';
import Loader from '../../components/loader/loader';
import { useHistory } from 'react-router-dom';
import { PATIENT_DASHBOARD } from '../../constants/locationPath';

const PatientRegistrationPage = () => {
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    axios.get('auth/me').then(({ data: user }) => {
      if (user?.personalData?.id) {
        return nextStep();
      }
      setUser(user);
    });
  }, []);

  const nextStep = useCallback(() => {
    history.push(PATIENT_DASHBOARD);
  });

  return (
    <>
      {!user && <Loader />}
      {user && (
        <Container className={style.wrapper}>
          <div className={style.container}>
            <PersonalData nextStep={nextStep} />
          </div>
        </Container>
      )}
    </>
  );
};

export default PatientRegistrationPage;
