import { CONTACT_US, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../constants/locationPath';
import { DARK_THEME_LOGO } from '../../constants/themeLogo';

export const footerDataConfig = {
  logoTheme: DARK_THEME_LOGO,
  description: '',
  contact: {
    phoneLabel: 'Phone: ',
    phone: '+18686855787',
    phonePlaceholder: '+1 (868) 685-5787',
    emailLabel: 'Email: ',
    email: 'Info@myibismd.com',
    addressLabel: 'Address: ',
    address: '677 Iduuda Highway',
  },
  socialNetworks: [
    {
      icon: 'icon-instagram',
      title: 'Instagram',
      url: 'https://www.instagram.com/myibismd',
    },
    {
      icon: 'icon-facebook',
      title: 'Facebook',
      url: 'https://www.facebook.com/myibismd',
    },
    {
      icon: 'icon-twitter',
      title: 'Twitter',
      url: 'https://twitter.com/',
    },
  ],
  copyrightText: '2021 Ibis. All rights reserved.',
  policy: {
    linkURL: PRIVACY_POLICY,
    linkTitle: 'Privacy Policy',
  },
  terms: {
    linkURL: TERMS_AND_CONDITIONS,
    linkTitle: 'Terms and Conditions',
  },
  contactUs: {
    linkURL: CONTACT_US,
    linkTitle: 'Contact us',
  },
};
