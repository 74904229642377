import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import commonStyle from '../../doctorsRegistration/registration.module.css';
import style from './subscribeSetting.module.css';
import { Formik } from 'formik';
import { doctorCardSchema } from '../../validationSchema';
import axios from '../../../API/configAPI';
import FormikControl from '../../formikControl';
import { Button } from '@material-ui/core';
import Loader from '../../../components/loader/loader';
import EditButton from '../../../components/editButton/editButton';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../../store/modalSlice';
import { CARD_CVV, CARD_EXPIRIES, CARD_NUMBER } from './../../../constants/formatValueInput';
import formattedCardExpiries from '../../../helpers/formattedCardExpiries';
import BaseTextInput from './../../baseFormControl/baseTextInput/baseTextInput';

const SubscribeSetting = ({ nextStep }) => {
  const SAVE_CREDENTIALS = '[SubscribeSetting] Save credentials';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const [card, setCard] = useState(null);
  const [isCanEditCard, setIsCanEditCard] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [valuesForm, setValuesForm] = useState(null);

  useEffect(() => {
    axios.get('card').then(({ data: card }) => {
      setCard({ number: card?.number?.replace(/[*]{4}/g, '**** ') });
    });
  }, []);

  useEffect(() => {
    if (answerModal === SAVE_CREDENTIALS) {
      dispatch(setModalAnswer(''));
      setIsSubmitting(true);

      const requestType = card?.number ? 'put' : 'post';

      axios[requestType]('card', { ...valuesForm, expiries: formattedCardExpiries(valuesForm.expiries) })
        .then(({ data: card }) => {
          setIsSubmitting(false);
          setIsCanEditCard(false);
          nextStep();
        })
        .catch((error) => {
          setIsSubmitting(false);
        });
    }
  }, [answerModal]);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Save changes?',
    description: `
      Do you want to save your changes?
    `,
    actions: [
      {
        title: 'Cancel',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Ok',
        answerModal: SAVE_CREDENTIALS,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const initialValues = {
    number: '',
    holder: '',
    expiries: '',
    cvv: '',
  };

  const onSubmit = (values) => {
    setValuesForm(values);
    dispatch(setModalContent(contentModal));
  };

  const clickEdit = () => {
    setIsCanEditCard(true);
  };

  const handlerCancel = () => {
    setIsCanEditCard(false);
  };

  return (
    <>
      {!card && <Loader />}
      {card && (
        <div className={style.containerSetting}>
          <div className={commonStyle.header}>
            <h3 className={commonStyle.title}>Subscription</h3>
          </div>
          <Formik initialValues={initialValues} validationSchema={doctorCardSchema} onSubmit={onSubmit}>
            {({ handleSubmit, resetForm }) => (
              <form autoComplete="off" className={commonStyle.form} onSubmit={handleSubmit}>
                <div className={style.formSettingGrid}>
                  <div className={style.formSettingHeader}>
                    <h4 className={style.formSettingTitle}>Payment method</h4>
                    <span className={style.formSettingEditButton}>
                      {!isCanEditCard && <EditButton handlerClick={clickEdit} />}
                    </span>
                  </div>
                  {isCanEditCard && (
                    <div className={style.formGrid}>
                      <FormikControl
                        control="textInput"
                        label="Card number"
                        type="text"
                        name="number"
                        placeholder="**** **** **** ****"
                        format={CARD_NUMBER}
                      />
                      <div className={style.formSubGrid}>
                        <FormikControl
                          control="textInput"
                          label="Expiries"
                          type="text"
                          name="expiries"
                          placeholder="mm/yy"
                          format={CARD_EXPIRIES}
                        />
                        <FormikControl
                          control="textInput"
                          label="CVV"
                          type="text"
                          name="cvv"
                          placeholder="CVV"
                          format={CARD_CVV}
                        />
                      </div>
                    </div>
                  )}
                  {!isCanEditCard && (
                    <div className={style.formGrid}>
                      <BaseTextInput label="Card number" type="text" name="number" placeholder={card.number} disabled />
                      <div className={style.formSubGrid}>
                        <BaseTextInput label="Expiries" type="text" name="expiries" placeholder="**/**" disabled />
                        <BaseTextInput label="CVV" type="text" name="cvv" placeholder="***" disabled />
                      </div>
                    </div>
                  )}
                </div>
                {isCanEditCard && (
                  <div className={commonStyle.actions}>
                    <Button
                      className={commonStyle.button}
                      variant="outlined"
                      onClick={() => {
                        resetForm();
                        handlerCancel();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={commonStyle.button}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

SubscribeSetting.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default React.memo(SubscribeSetting);
