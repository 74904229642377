import React from 'react';
import { useField } from 'formik';
import BaseCheckbox from '../baseFormControl/baseCheckbox/baseCheckbox';

const CheckboxInput = (props) => {
  const [field, meta] = useField(props);

  return <BaseCheckbox {...props} {...field} {...meta} />;
};

export default React.memo(CheckboxInput);
