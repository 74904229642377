import React from 'react';
import PropTypes from 'prop-types';
import style from './baseTextInput.module.css';

const BaseTextInput = ({
  label,
  id,
  name,
  touched,
  error,
  description,
  value,
  onBlur,
  onChange,
  onClick,
  disabled,
  autoComplete,
  placeholder,
}) => {
  return (
    <div className={style.wrapper}>
      {label && (
        <label className={style.label} htmlFor={id || name}>
          {label}
        </label>
      )}
      <div className={[style.wrapperInput, touched && error && style.errorInput].join(' ')}>
        <input
          className={style.textInput}
          id={id || name}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
          autoComplete={autoComplete}
          placeholder={placeholder}
        />
      </div>
      {touched && error ? (
        <span className={[style.message, style.error].join(' ')}>{error}</span>
      ) : (
        description && <span className={[style.message, style.description].join(' ')}>{description}</span>
      )}
    </div>
  );
};

BaseTextInput.defaultProps = {
  label: '',
  id: '',
  name: '',
  touched: false,
  error: '',
  description: '',
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onClick: () => {},
  disabled: false,
  autoComplete: 'off',
  placeholder: '',
};

BaseTextInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
};

export default React.memo(BaseTextInput);
