import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './chipsInput.module.css';
import AddIcon from '@material-ui/icons/Add';
import { Chip, LinearProgress } from '@material-ui/core';
import { useField } from 'formik';
import axios from './../../../API/configAPI';

const ChipsInput = ({ label, description, disabled, searchRoutePath, addRoutePath, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [valueInput, setValueInput] = useState('');
  const [chipsValue, setChipsValue] = useState(field.value || []);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeHandler = (event) => {
    setValueInput(event.target.value);
    if (event.target.value === '') {
      return setAutoCompleteValue([]);
    }
    setLoading(true);
    axios
      .get(`${searchRoutePath}?query=${event.target.value}`)
      .then(({ data }) => {
        setAutoCompleteValue(data);
        setLoading(false);
      })
      .catch(() => {
        setAutoCompleteValue([]);
        setLoading(false);
      });
  };

  const onKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onClickIconAdd = () => {
    axios.post(`${addRoutePath}`, { name: valueInput }).then(({ data: newChips }) => {
      setChipsValue([...chipsValue, newChips]);
      helpers.setValue([...chipsValue, newChips]);
      setValueInput('');
      setAutoCompleteValue([]);
    });
  };

  const handleDelete = (val) => {
    setChipsValue(chipsValue.filter((item) => item.id !== val.id));
    helpers.setValue(chipsValue.filter((item) => item.id !== val.id));
  };

  const handleSelected = (val) => {
    setChipsValue([...chipsValue, val]);
    helpers.setValue([...chipsValue, val]);
    setValueInput('');
    setAutoCompleteValue([]);
  };

  const onBlurHandler = () => {
    helpers.setTouched(true);
    setTimeout(() => {
      setAutoCompleteValue([]);
    }, 300);
  };

  return (
    <div className={style.wrapper}>
      {label && (
        <label className={style.label} htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div className={[style.wrapperInput, meta.touched && meta.error && style.errorInput].join(' ')}>
        {!!chipsValue.length &&
          chipsValue.map((val) => (
            <Chip
              variant="outlined"
              key={val.id}
              className={style.chip}
              label={val.name}
              onDelete={() => handleDelete(val)}
            />
          ))}
        <div className={style.wrapperInput}>
          <input
            disabled={disabled}
            style={props.styleInput}
            className={style.textInput}
            {...field}
            placeholder={props.placeholder}
            onChange={onChangeHandler}
            value={valueInput}
            onKeyDown={onKeyDownHandler}
            onBlur={onBlurHandler}
          />
          <span className={style.icon} onClick={onClickIconAdd}>
            <AddIcon className={style.iconMat} fontSize="small" />
          </span>
        </div>
        {!!autoCompleteValue.length && (
          <ul className={style.listAutoComplete}>
            {autoCompleteValue.map((val) => {
              if (chipsValue.find((item) => item.id === val.id)) {
                return null;
              }
              return (
                <li
                  className={style.itemAutoComplete}
                  key={val.id}
                  onClick={() => {
                    handleSelected(val);
                  }}
                >
                  {val.name}
                </li>
              );
            })}
          </ul>
        )}
        {loading && <LinearProgress color="secondary" className={style.progressBar} />}
        {meta.touched && meta.error ? (
          <span className={[style.message, style.error].join(' ')}>{meta.error}</span>
        ) : (
          <span className={[style.message, style.description].join(' ')}>{description}</span>
        )}
      </div>
    </div>
  );
};

ChipsInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  styleInput: PropTypes.object,
  placeholder: PropTypes.string,
  searchRoutePath: PropTypes.string.isRequired,
  addRoutePath: PropTypes.string.isRequired,
};

export default React.memo(ChipsInput);
