import React, { useState, useEffect } from 'react';
import style from './header.module.css';
import SignInHeaderForm from '../../forms/authorization/signInHeaderForm';
import { Link } from 'react-router-dom';
import { Navigation } from './../../components/navigation/navigation';
import { Container, useMediaQuery } from '@material-ui/core';
import Logo from './../../components/logo/logo';
import { LIGHT_THEME_LOGO } from './../../constants/themeLogo';
import { useLocation } from 'react-router-dom';
import { LANDING, SIGN_IN, SIGN_UP } from './../../constants/locationPath';
import { useSelector } from 'react-redux';
import UserNavigation from '../../components/userNavigation/userNavigation';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const getPageYOffset = () => {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

const Header = () => {
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [locationPath, setLocationPath] = useState(LANDING);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:768px)');
  const isScrollUp = useScrollTrigger();

  useEffect(() => {
    setIsScrollTop(!getPageYOffset());

    window.onscroll = () => {
      setIsScrollTop(!getPageYOffset());
    };
  }, []);

  useEffect(() => {
    setLocationPath(location.pathname);
    window.scrollTo(0, 0);
  }, [location]);

  const getViewRightSide = () => {
    switch (locationPath) {
      case LANDING:
        return isMobile ? (
          <Navigation />
        ) : (
          <div className={style.headerForm}>
            <SignInHeaderForm />
            <Link to={SIGN_UP} className={style.link}>
              Sign Up
            </Link>
          </div>
        );
      case SIGN_UP:
        return (
          <Link to={SIGN_IN} className={style.link}>
            Sign In
          </Link>
        );
      default:
        return (
          <Link to={SIGN_UP} className={style.link}>
            Sign Up
          </Link>
        );
    }
  };

  return (
    <>
      <div
        className={[style.plug, locationPath === LANDING && style.landingHeaderPlug, isMobile && style.mobilePlug].join(
          ' ',
        )}
      />
      <header
        className={[
          'header',
          style.headerRoot,
          locationPath === LANDING && style.landingHeader,
          isScrollTop && locationPath === LANDING && style.scrollTop,
          !isScrollUp && style.scrollUp,
          isMobile && style.mobile,
        ].join(' ')}
      >
        <Container>
          <div className={style.headerGrid}>
            <Link to={LANDING} className={style.positionLogo}>
              <div className={style.sizeLogo}>
                <Logo themeLogo={LIGHT_THEME_LOGO} />
              </div>
            </Link>
            <div className={style.viewRightSide}>
              {!user?.personalData && getViewRightSide()}
              {user?.personalData && <UserNavigation />}
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

export default React.memo(Header);
