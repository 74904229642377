import React from 'react';
import style from './contactUsPage.module.css';
import { Container, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import ContactUs from '../../forms/contactUs/contactUs';

const ContactUsPage = () => {
  const history = useHistory();
  return (
    <Container>
      <div className={style.wrapper}>
        <div className={style.header}>
          <h3 className={style.title}>Contact us</h3>
          <Button variant="contained" color="primary" onClick={history.goBack}>
            Go back
          </Button>
        </div>
        <div className={style.content}>
          <ContactUs />
        </div>
      </div>
    </Container>
  );
};

export default ContactUsPage;
