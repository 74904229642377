import React from 'react';
import PropTypes from 'prop-types';
import style from './labFlag.module.css';

const LabFlag = ({ flag }) => {
  return (
    <div
      className={[style.wrapper, flag === 'H' && style.h, flag === 'L' && style.l, flag === 'N' && style.n].join(' ')}
    >
      <span>{flag}</span>
    </div>
  );
};

LabFlag.propTypes = {
  flag: PropTypes.oneOf(['H', 'L', 'N']).isRequired,
};

export default React.memo(LabFlag);
