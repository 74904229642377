import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './formComponents.module.css';
import { useField } from 'formik';
import NumberFormat from 'react-number-format';

const TextInput = ({ label, description, disabled, format, ...props }) => {
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(field.value, props.nameField, props.index);
    }
  }, [field.value]);

  return (
    <div className={style.wrapper}>
      {label && (
        <label className={style.label} htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div className={[style.wrapperInput, meta.touched && meta.error && style.errorInput].join(' ')}>
        {!format && (
          <input
            disabled={disabled}
            style={props.styleInput}
            className={style.textInput}
            {...field}
            placeholder={props.placeholder}
          />
        )}
        {format && (
          <NumberFormat
            disabled={disabled}
            style={props.styleInput}
            className={style.textInput}
            placeholder={props.placeholder}
            format={format}
            value={field.value}
            onValueChange={(values) => {
              const { value } = values;
              helpers.setValue(value);
            }}
            mask="_"
            onBlur={() => {
              helpers.setTouched(true);
            }}
          />
        )}
      </div>
      {meta.touched && meta.error ? (
        <span className={[style.message, style.error].join(' ')}>{meta.error}</span>
      ) : (
        <span className={[style.message, style.description].join(' ')}>{description}</span>
      )}
    </div>
  );
};
TextInput.defaultProps = {
  label: '',
  description: '',
  disabled: false,
  id: '',
  styleInput: {},
  placeholder: '',
  format: '',
};

TextInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  styleInput: PropTypes.object,
  placeholder: PropTypes.string,
  format: PropTypes.string,
};

export default React.memo(TextInput);
