import React from 'react';
import style from './invoicePreview.module.css';
import dayJs from './../../helpers/getDayJsWithTimezone';
import { useHistory } from 'react-router';
import { INVOICE_DETAILS } from '../../constants/locationPath';
import NumberFormat from 'react-number-format';
import { PREFIX } from '../../constants/formatValueInput';

const InvoicePreview = ({ invoiceData }) => {
  const { id, number, invoiceDate, dueDate, total, status } = invoiceData;
  const history = useHistory();

  const handlerClick = () => {
    history.push(`${INVOICE_DETAILS}/${id}`);
  };

  return (
    <div className={style.wrapper} onClick={() => handlerClick()}>
      <div className={style.gridLine}>
        <span className={style.title}>Invoice</span>
        <span className={style.number}>{number}</span>
      </div>
      <div className={style.gridLine}>
        <span className={style.subtitle}>Invoice date</span>
        <span className={style.date}>{dayJs(invoiceDate).format('DD.MM.YYYY')}</span>
      </div>
      <div className={style.gridLine}>
        <span className={style.subtitle}>Due date</span>
        <span className={style.date}>{dayJs(dueDate).format('DD.MM.YYYY')}</span>
      </div>
      <div className={style.gridLine}>
        <span className={[style.status, style[status]].join(' ')}>{status}</span>
        <span className={style.total}>
          <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={PREFIX} />
        </span>
      </div>
    </div>
  );
};

export default React.memo(InvoicePreview);
