import React from 'react';
import PropTypes from 'prop-types';
import style from './formComponents.module.css';
import { MenuItem, Select } from '@material-ui/core';
import BootstrapInput from '../../components/bootstrapComponent/bootstrapInput';
import { useField } from 'formik';

// icon
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const SelectInput = ({ label, description, disabled, id, name, options, onChange }) => {
  const [field, meta, helpers] = useField(name);

  const handlerSelect = (value) => {
    helpers.setValue(value);
    onChange(value);
  };

  const onBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <div className={style.wrapper}>
      {label && (
        <label className={style.label} htmlFor={id || name}>
          {label}
        </label>
      )}
      <div className={[style.wrapperInput, meta.touched && meta.error && style.errorInput].join(' ')}>
        <Select
          fullWidth
          className={style.selectList}
          value={field.value}
          onChange={({ target: { value } }) => handlerSelect(value)}
          variant="outlined"
          color="secondary"
          input={<BootstrapInput />}
          IconComponent={KeyboardArrowDownIcon}
          disabled={disabled}
          onBlur={onBlur}
        >
          {options &&
            !!options.length &&
            options.map(({ displayName, value }, index) => (
              <MenuItem key={index} value={value} className={style.selectListItem}>
                {displayName}
              </MenuItem>
            ))}
          {!(options && options.length) && (
            <MenuItem value={''} className={style.selectListItem}>
              no values
            </MenuItem>
          )}
        </Select>
      </div>
      {meta.touched && meta.error ? (
        <span className={[style.message, style.error].join(' ')}>{meta.error}</span>
      ) : (
        description && <span className={[style.message, style.description].join(' ')}>{description}</span>
      )}
    </div>
  );
};

SelectInput.defaultProps = {
  onChange: () => {},
};

SelectInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func,
};

export default React.memo(SelectInput);
