/* eslint-disable max-len */
import React from 'react';
import style from './landingPage.module.css';
import SignInForm from '../../forms/authorization/signInForm';
import { Container, Button } from '@material-ui/core';
import SearchGroup from '../../forms/searchGroup/searchGroup';
import { useHistory } from 'react-router-dom';
import { SEARCH } from '../../constants/locationPath';

// images
import illustration from './../../assets/images/landingPage/illustration.png';
import doctorEnrolment from './../../assets/images/landingPage/doctor_enrolment.png';
import bg from './../../assets/images/landingPage/bg.jpg';
import patientsVideoChat from './../../assets/images/landingPage/patients-video-chat.png';
import doctorVideoChat from './../../assets/images/landingPage/doctor-video-chat.png';
import chat from './../../assets/images/landingPage/chat.jpg';

// icons
import doctorFeature from './../../assets/icons/doctor_feature@3x.svg';
import scheduleFeature from './../../assets/icons/schedule_feature@3x.svg';
import wifiFeature from './../../assets/icons/wifi_feature@3x.svg';
import locationFeature from './../../assets/icons/location_feature@3x.svg';
import scheduling from './../../assets/icons/ic_scheduling.svg';
import telehealth from './../../assets/icons/ic_telehealth.svg';
import management from './../../assets/icons/ic_management.svg';
import prescription from './../../assets/icons/ic_prescription.svg';
import points from './../../assets/icons/points.svg';

const LandingPage = () => {
  const history = useHistory();

  const handlerAppointments = () => {
    history.push(SEARCH);
  };

  const handlerSearchDoctor = (values) => {
    history.push(SEARCH, values);
  };

  return (
    <>
      <section className={style.section}>
        <Container>
          <div className={style.sectionGrid}>
            <div className={style.sectionInfo}>
              <h2 className={style.sectionTitle}>Doctor Location Services</h2>
              <p className={style.sectionSubtitle}>
                Find and schedule an appointment with any of the IBIS Network of Medical Practitioners using criteria
                such as name, location and specialty.
              </p>
              <div className={style.searchGroup}>
                <SearchGroup handleSubmitValues={handlerSearchDoctor} />
              </div>
              <div className={style.performGrid}>
                <div className={style.performGridCell}>
                  <span className={style.performInfoQuantity}>1000 +</span>
                  <span className={style.performInfo}>Happy Patients</span>
                </div>
                <div className={style.performGridCell}>
                  <span className={style.performInfoQuantity}>200</span>
                  <span className={style.performInfo}>Expert Doctors</span>
                </div>
                <div className={style.performGridCell}>
                  <span className={style.performInfoQuantity}>315 +</span>
                  <span className={style.performInfo}>Hospital rooms</span>
                </div>
              </div>
            </div>
            <div className={[style.sectionImg, style.sectionInfoImg].join(' ')}>
              <img src={illustration} alt="sectionImg" />
            </div>
          </div>
        </Container>
      </section>

      <section className={style.cardsSection}>
        <Container>
          <div className={style.cardsGrid}>
            <div className={style.card}>
              <div className={style.cardIcon}>
                <img src={doctorFeature} alt="icon" />
              </div>
              <span className={style.cardDescription}>Find a Doctor for you</span>
            </div>
            <div className={style.card}>
              <div className={style.cardIcon}>
                <img src={scheduleFeature} alt="icon" />
              </div>
              <span className={style.cardDescription}>Schedule an Appointment</span>
            </div>
            <div className={style.card}>
              <div className={style.cardIcon}>
                <img src={wifiFeature} alt="icon" />
              </div>
              <span className={style.cardDescription}>Virtual Consultation</span>
            </div>
            <div className={style.card}>
              <div className={style.cardIcon}>
                <img src={locationFeature} alt="icon" />
              </div>
              <span className={style.cardDescription}>Medical Institution Locator</span>
            </div>
          </div>
        </Container>
      </section>

      <section className={style.section}>
        <Container>
          <div className={style.sectionGrid}>
            <div className={style.sectionImg}>
              <img src={doctorEnrolment} alt="Enrolment" />
            </div>
            <div className={style.sectionEnrolment}>
              <h2 className={style.sectionTitle}>IBIS Doctor Enrolment</h2>
              <p className={style.sectionSubtitle}>
                Sign up to become a member of the IBIS Network of Medical Practitioners to gain access to our many
                features.
              </p>
              <div className={style.sectionEnrolmentGrid}>
                <div className={style.sectionEnrolmentCell}>
                  <div className={style.sectionEnrolmentIcon}>
                    <img src={scheduling} alt="icon" />
                  </div>
                  <span className={style.sectionEnrolmentDescription}>Integrated Scheduling Assistant</span>
                </div>

                <div className={style.sectionEnrolmentCell}>
                  <div className={style.sectionEnrolmentIcon}>
                    <img src={telehealth} alt="icon" />
                  </div>
                  <span className={style.sectionEnrolmentDescription}>Secure Telehealth Application</span>
                </div>

                <div className={style.sectionEnrolmentCell}>
                  <div className={style.sectionEnrolmentIcon}>
                    <img src={management} alt="icon" />
                  </div>
                  <span className={style.sectionEnrolmentDescription}>Patient Management System</span>
                </div>

                <div className={style.sectionEnrolmentCell}>
                  <div className={style.sectionEnrolmentIcon}>
                    <img src={prescription} alt="icon" />
                  </div>
                  <span className={style.sectionEnrolmentDescription}>Electronic Prescription Services</span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className={[style.section, style.sectionAccent].join(' ')} style={{ backgroundImage: `url(${bg})` }}>
        <Container>
          <div className={style.sectionGrid}>
            <div>
              <h2 className={style.sectionTitle}>Online Appointments and Prescriptions</h2>
            </div>
            <div className={style.appointments}>
              <p className={style.sectionSubtitle}>You can now book a limited amount of doctors appointments online</p>
              <Button
                className={style.appointmentsButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={handlerAppointments}
              >
                Make an appointment
              </Button>
            </div>
          </div>
        </Container>
      </section>

      <section className={[style.section, style.sectionRecord].join(' ')}>
        <Container maxWidth="md">
          <h2 className={style.sectionTitle}>Patient Medical Record</h2>
          <p className={style.sectionSubtitle}>
            Create your own medical record using the secure IBIS Electronic Medical Record System that you can share
            with any medical practitioner prior to or during your consultation.
          </p>
        </Container>
        <Container>
          <div className={style.recordGrid}>
            <div className={style.recordCell}>
              <div className={style.recordIcon}>
                <img src={points} alt="icon" />
              </div>
              <span className={style.recordDescription}>24/7 secure access to your medical records</span>
            </div>

            <div className={style.recordCell}>
              <div className={style.recordIcon}>
                <img src={points} alt="icon" />
              </div>
              <span className={style.recordDescription}>Better patient therapy management</span>
            </div>

            <div className={style.recordCell}>
              <div className={style.recordIcon}>
                <img src={points} alt="icon" />
              </div>
              <span className={style.recordDescription}>Easily transferrable to any doctor you choose</span>
            </div>
          </div>
        </Container>
      </section>

      <section className={style.gradientVerticalWhiteGray}>
        <Container maxWidth="md">
          <div className={style.sectionImages}>
            <div className={style.imagePatients}>
              <img src={patientsVideoChat} alt="patients video chat" />
            </div>
            <div className={style.imageDoctor}>
              <img src={doctorVideoChat} alt="doctor video chat" />
            </div>
            <div className={style.imageChat}>
              <img src={chat} alt="chat" />
            </div>
          </div>
        </Container>
      </section>

      <section id="signIn" className={style.gradientVerticalGrayMirage}>
        <Container maxWidth="md">
          <SignInForm />
        </Container>
      </section>
    </>
  );
};

export default React.memo(LandingPage);
