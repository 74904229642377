import React, { useState, useEffect } from 'react';
import style from './userNavigation.module.css';
import { useSelector } from 'react-redux';
import ViewImage from '../viewImage/viewImage';
import { Menu, MenuItem } from '@material-ui/core';
import { DOCTOR_ROLE, PATIENT_ROLE } from '../../constants/userRole';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  SETTING,
  SIGN_IN,
  DOCTOR_MY_PATIENTS,
  DOCTOR_DASHBOARD,
  PATIENT_DASHBOARD,
  PATIENT_MY_DOCTORS,
  INVOICES,
} from '../../constants/locationPath';

const UserNavigation = () => {
  const user = useSelector((state) => state.auth.user);
  const [options, setOptions] = useState([]);
  const history = useHistory();
  const [locationPath, setLocationPath] = useState(null);
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = (path) => {
    setAnchorEl(null);
    history.push(path);
  };

  const optionsDoctor = [
    { name: 'Profile', path: DOCTOR_DASHBOARD },
    { name: 'My Patients', path: DOCTOR_MY_PATIENTS },
    { name: 'Invoices', path: INVOICES },
    { name: 'Settings', path: SETTING },
    { name: 'Log out', path: SIGN_IN },
  ];

  const optionsPatient = [
    { name: 'Profile', path: PATIENT_DASHBOARD },
    { name: 'My doctors', path: PATIENT_MY_DOCTORS },
    { name: 'Invoices', path: INVOICES },
    { name: 'Settings', path: SETTING },
    { name: 'Log out', path: SIGN_IN },
  ];

  useEffect(() => {
    switch (user.role) {
      case DOCTOR_ROLE:
        return setOptions(optionsDoctor);
      case PATIENT_ROLE:
        return setOptions(optionsPatient);
      default:
        return [];
    }
  }, [user]);

  useEffect(() => {
    setLocationPath(location.pathname);
  }, [location]);

  const menuProps = {
    style: {
      background: '#FFFFFF',
      border: '1px solid #F7F7F9',
      boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.085)',
      borderRadius: '8px',
      padding: '12px 14px',
    },
  };

  return (
    <>
      {user && user.personalData && (
        <div className={style.wrapper}>
          <div className={style.user}>
            <span className={style.userName}>
              {user.personalData.firstName}
              &ensp;
              {user.personalData.lastName}
            </span>
            <Link to={`/${user.role}`} className={style.link}>
              View profile
            </Link>
          </div>
          <div
            className={style.arrow}
            aria-label="more"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ViewImage imageId={user.personalData.avatarId} size="36px" borderRadius="8px" />
          </div>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleCloseMenu}
            PaperProps={menuProps}
          >
            {options.map(({ name, path }, index) => (
              <MenuItem
                key={index}
                onClick={() => handleClose(path)}
                selected={locationPath === path}
                className={[style.menuItemStyle, style.menuItem, path === SIGN_IN && style.menuItemMark].join(' ')}
              >
                {name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </>
  );
};

export default React.memo(UserNavigation);
