import React, { useState, useEffect } from 'react';
import style from './planner.module.css';
import DatePicker from 'react-datepicker';
import { useMediaQuery, Dialog } from '@material-ui/core';
import axios from './../../API/configAPI';
import { checkMatchDate } from './../../helpers/checkMathDate';
import dayjs from './../../helpers/getDayJsWithTimezone';
import { useHistory } from 'react-router';
import { DOCTOR_BIG_PLANER } from '../../constants/locationPath';

// icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';

const Planner = ({ doctor }) => {
  const matches_XL = useMediaQuery('(max-width:1200px)');
  const matches_SM_XL = useMediaQuery('(min-width:576px) and (max-width:1200px)');
  const [plannerDate, setPlannerDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [schedule, setSchedule] = useState({});
  const [scheduleCurrentDay, setScheduleCurrentDay] = useState({ start: null, end: null });
  const [blockedTime, setBlockedTime] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const history = useHistory();

  useEffect(() => {
    switch (dayjs(plannerDate).day()) {
      case 0:
        return setScheduleCurrentDay({ start: schedule.sunStart, end: schedule.sunEnd });
      case 1:
        return setScheduleCurrentDay({ start: schedule.monStart, end: schedule.monEnd });
      case 2:
        return setScheduleCurrentDay({ start: schedule.tueStart, end: schedule.tueEnd });
      case 3:
        return setScheduleCurrentDay({ start: schedule.wedStart, end: schedule.wedEnd });
      case 4:
        return setScheduleCurrentDay({ start: schedule.thuStart, end: schedule.thuEnd });
      case 5:
        return setScheduleCurrentDay({ start: schedule.friStart, end: schedule.friEnd });
      case 6:
        return setScheduleCurrentDay({ start: schedule.satStart, end: schedule.satEnd });
    }
  }, [plannerDate, schedule]);

  useEffect(() => {
    const currentDate = dayjs(plannerDate).format('YYYY-MM-DD');
    axios.get(`appointment/search?date=${currentDate}&status=confirmed`).then(({ data: { results } }) => {
      setAppointments(results);
    });
  }, [plannerDate]);

  useEffect(() => {
    setOpen(!matches_XL);
  }, [matches_XL]);

  useEffect(() => {
    if (doctor.scheduleId) {
      axios.get(`schedule/${doctor.scheduleId}`).then(({ data: schedule }) => {
        setSchedule(schedule);
      });
      axios.get(`blocked-time/${doctor.scheduleId}`).then(({ data: blockedTime }) => {
        setBlockedTime(blockedTime);
      });
    }
  }, [doctor]);

  const handlerPlanner = () => {
    setOpen(!open);
  };

  const getTimeSheet = () => {
    const timeSheet = [];
    const { start, end } = scheduleCurrentDay;

    if (!start || !end) return timeSheet;

    let hourStart = Number(start.slice(0, 2));
    const hourEnd = Number(end.slice(0, 2));

    while (hourStart <= hourEnd) {
      timeSheet.push(`${('00' + hourStart).slice(-2)}:00`);
      hourStart += 1;
    }

    return timeSheet;
  };

  const getHeightBlockedTime = (start, end) => {
    // return 2px = 1 minute
    return `${((+dayjs(end) - +dayjs(start)) / 1000 / 60) * 2}px`;
  };

  const getPositionBlockedTime = (start) => {
    const hour = dayjs(start).tz().hour();
    const minute = dayjs(start).tz().minute();
    return `${20 + minute * 2 + (hour - Number(scheduleCurrentDay.start.slice(0, 2))) * 120}px`;
  };

  const handlerLink = () => {
    history.push(DOCTOR_BIG_PLANER);
  };

  const renderPlanner = () => {
    return (
      <div className={style.planner}>
        {matches_XL && (
          <div className={style.plannerHeader}>
            <span className={style.buttonClose} onClick={handlerPlanner}>
              <CloseIcon />
            </span>
          </div>
        )}
        <div className={style.calendar}>
          <DatePicker
            selected={plannerDate}
            onChange={(date) => setPlannerDate(date)}
            inline
            dayClassName={(date) => {
              const blockedDay = blockedTime.filter(({ start }) => {
                return checkMatchDate(date, start);
              });
              return blockedDay.length ? 'haveBlockedTime' : '';
            }}
            monthsShown={matches_SM_XL ? 2 : 1}
          />
        </div>
        <div className={style.dayPlanner}>
          <div className={style.dayPlannerHeader}>
            <h4 className={style.dayPlannerTitle}>Planner</h4>
            <span className={style.dayPlannerLink} onClick={handlerLink}>
              See all
            </span>
          </div>
          <ul className={style.dayPlannerTimeList}>
            {scheduleCurrentDay.start &&
              !!blockedTime.length &&
              blockedTime.map(({ id, start, end }) => {
                return (
                  checkMatchDate(plannerDate, start) && (
                    <li
                      key={id}
                      style={{ top: getPositionBlockedTime(start) }}
                      className={style.blockedTimeItemWrapper}
                    >
                      <div className={style.blockedTimeItem} style={{ minHeight: getHeightBlockedTime(start, end) }}>
                        {`${dayjs(start).tz().format('HH:mm')} - ${dayjs(end).tz().format('HH:mm')}`}
                        <span className={style.blockedTimeLabel}>Blocked time</span>
                      </div>
                    </li>
                  )
                );
              })}
            {scheduleCurrentDay.start &&
              !!appointments.length &&
              appointments.map(({ id, start, end, firstName, lastName }) => {
                return (
                  checkMatchDate(plannerDate, start) && (
                    <li
                      key={id}
                      style={{ top: getPositionBlockedTime(start) }}
                      className={style.blockedTimeItemWrapper}
                    >
                      <div className={style.blockedTimeItem} style={{ minHeight: getHeightBlockedTime(start, end) }}>
                        {`${dayjs(start).tz().format('HH:mm')} - ${dayjs(end).tz().format('HH:mm')}`}
                        <span className={style.blockedTimeLabel}>{`${firstName} ${lastName}`}</span>
                      </div>
                    </li>
                  )
                );
              })}
            {scheduleCurrentDay.start &&
              scheduleCurrentDay.end &&
              getTimeSheet().map((time, index) => (
                <li key={index} className={style.dayPlannerTimeItem}>
                  <span className={style.dayPlannerTime}>{time}</span>
                </li>
              ))}
            {!scheduleCurrentDay.start && <li>no schedule</li>}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className={style.wrapper}>
      {!matches_XL && <>{renderPlanner()}</>}
      {matches_XL && (
        <>
          <div className={style.widget}>
            <div className={style.widgetDate}>
              <div className={style.widgetDateDay}>{dayjs(plannerDate).format('DD')}</div>
              <div className={style.widgetDateOther}>
                <span className={style.widgetDateMonth}>{dayjs(plannerDate).format('MMMM')}</span>
                <span className={style.widgetDateYear}>{dayjs(plannerDate).format('YYYY')} Year</span>
                <span className={style.widgetActionsLabelMobile}>Planner</span>
              </div>
            </div>
            <div className={style.widgetActions} onClick={handlerPlanner}>
              <span className={style.widgetActionsLabel}>Planner</span>
              <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
            </div>
          </div>
          <Dialog
            className={style.dialogPlannerWrapper}
            onClose={handlerPlanner}
            aria-labelledby="dialog-planner"
            open={open}
          >
            {renderPlanner()}
          </Dialog>
        </>
      )}
    </div>
  );
};

export default React.memo(Planner);
