import React from 'react';
import style from './authorization.module.css';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import { forgotPasswordSchema } from '../validationSchema';
import { useDispatch } from 'react-redux';
import { setModalContent } from './../../store/modalSlice';
import axios from './../../API/configAPI';
import FormikControl from '../formikControl';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Check your inbox',
    description: `
      The restore password link was sent to your address. 
      Check it, please.
    `,
    actions: [
      {
        title: 'Ok',
        answerModal: '',
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2 className={style.title}>Restore password</h2>
        <p className={style.subtitle}>We will send a restore link to provided email to reset your password.</p>
      </div>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const { email } = values;
          axios
            .get(`auth/restore-password/${email}`)
            .then(() => {
              resetForm();
              setSubmitting(false);
              dispatch(setModalContent(contentModal));
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <FormikControl control="textInput" label="Email" type="email" name="email" placeholder="Enter your email" />
            <Button
              className={style.button}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={isSubmitting}
            >
              Send restore link
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
