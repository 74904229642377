import React, { useEffect, useState } from 'react';
import style from './doctorCreatePrescriptionPage.module.css';
import { Container, Button } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router';
import axios from '../../API/configAPI';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import Loader from './../../components/loader/loader';
import { doctorPrescriptionSchema } from './../../forms/validationSchema';
import FormikControl from './../../forms/formikControl';
import { useSelector } from 'react-redux';
import getFormattedId from './../../helpers/getFormattedPhotoId';
import getFormattedDate from './../../helpers/getFormattedDate';
import { FORMAT_PHONE } from '../../constants/formatValueInput';

const DoctorCreatePrescriptionPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const doctor = useSelector((state) => state.auth.user);
  const [patient, setPatient] = useState(null);
  const { state: details } = useLocation();

  useEffect(() => {
    if (id) {
      axios
        .get(`user/${id}`)
        .then(({ data: patient }) => {
          setPatient(patient);
        })
        .catch((error) => {});
    }
  }, [id]);

  const initialValues = {
    creationDate: details?.creationDate ? getFormattedDate(details?.creationDate) : new Date(),
    patientId: patient?.id || '',
    image0: details?.imageId0 ? getFormattedId(details?.imageId0) : null,
    image1: details?.imageId1 ? getFormattedId(details?.imageId1) : null,
    image2: details?.imageId2 ? getFormattedId(details?.imageId2) : null,
    signature: details?.signatureId ? getFormattedId(details?.signatureId) : null,
    supplies: details?.supplies || '',
    description: details?.description || '',

    doctorName: `${doctor?.personalData?.firstName} ${doctor?.personalData?.lastName}` || '',
    officeName: doctor?.offices[0]?.name || '',
    address: doctor?.offices[0]?.address || '',
    country: 'Trinidad and Tobago',
    city: doctor?.offices[0]?.city || '',
    state: doctor?.offices[0]?.state || '',
    zipCode: doctor?.offices[0]?.zipCode || '',
    doctorPhone: doctor?.offices[0]?.phone || '',
    doctorEmail: doctor?.offices[0]?.email || '',

    patientName: `${patient?.personalData?.firstName} ${patient?.personalData?.lastName}` || '',
    patientPhone: patient?.personalData?.phone || '',
    patientEmail: patient?.email || '',
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const { creationDate, patientId, image0, image1, image2, signature, supplies, description } = values;
    const formattedDate = dayjs(creationDate.toISOString()).format('YYYY-MM-DD');

    const requestType = details ? 'put' : 'post';
    const requestUrl = details ? `prescription/${details.id}` : `prescription`;

    axios[requestType](requestUrl, {
      creationDate: formattedDate,
      patientId,
      image0,
      image1,
      image2,
      signature,
      supplies,
      description,
    })
      .then(() => {
        setSubmitting(false);
        resetForm();
        handlerGoBack();
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const handlerGoBack = () => {
    history.goBack();
  };

  const getImages = (values) => {
    const array = [values.image0, values.image1, values.image2];

    const elementArray = array.map((value, index) => {
      return value ? <FormikControl control="imageUpload" name={`image${index}`} canRemoveImage /> : null;
    });

    const arrayView = elementArray.filter((item, index) => {
      return item != null;
    });

    if (arrayView.length < 3) {
      const freeIndex = elementArray.findIndex((value) => value === null);
      arrayView.push(<FormikControl control="imageUpload" name={`image${freeIndex}`} canRemoveImage />);
    }

    return arrayView;
  };

  return (
    <Container className={style.container}>
      <div className={style.wrapper}>
        <h3 className={style.title}>Create prescription</h3>
        {(!patient || !doctor) && <Loader />}
        {patient && doctor && (
          <Formik initialValues={initialValues} validationSchema={doctorPrescriptionSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, values }) => (
              <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
                <div className={style.grid}>
                  <FormikControl control="datepicker" label="Prescription date" name="creationDate" />
                </div>
                <h4 className={style.subtitle}>Doctor's info</h4>
                <div className={style.grid}>
                  <FormikControl
                    control="textInput"
                    label="Doctor's name"
                    type="text"
                    name="doctorName"
                    disabled={true}
                  />
                  <FormikControl
                    control="textInput"
                    label="Office name"
                    type="text"
                    name="officeName"
                    disabled={true}
                  />
                  <FormikControl control="textInput" label="Address" type="text" name="address" disabled={true} />
                  <FormikControl control="textInput" label="City" type="text" name="city" disabled={true} />
                  <FormikControl control="textInput" label="State" type="text" name="state" disabled={true} />
                  <FormikControl control="textInput" label="Zip code" type="text" name="zipCode" disabled={true} />
                  <FormikControl
                    control="textInput"
                    label="Phone number"
                    type="text"
                    name="doctorPhone"
                    format={FORMAT_PHONE}
                    disabled={true}
                  />
                  <FormikControl
                    control="textInput"
                    label="Business email"
                    type="text"
                    name="doctorEmail"
                    disabled={true}
                  />
                </div>
                <h4 className={style.subtitle}>Patient information</h4>
                <div className={style.grid}>
                  <FormikControl
                    control="textInput"
                    label="Patient name"
                    type="text"
                    name="patientName"
                    disabled={true}
                  />
                  <FormikControl
                    control="textInput"
                    label="Phone number"
                    type="text"
                    name="patientPhone"
                    disabled={true}
                    format={FORMAT_PHONE}
                  />
                  <FormikControl
                    control="textInput"
                    label="Patient email"
                    type="text"
                    name="patientEmail"
                    disabled={true}
                  />
                </div>
                <h4 className={style.subtitle}>Prescription details</h4>
                <FormikControl
                  control="textarea"
                  label="Medical supplies"
                  type="textarea"
                  name="supplies"
                  placeholder="Add medical supplies"
                />
                <div className={style.blockImages}>
                  {getImages(values).map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div>
                <FormikControl
                  control="textarea"
                  label="Description"
                  type="textarea"
                  name="description"
                  placeholder="Add description"
                />
                <div className={style.blockImage}>
                  <h5 className={style.blockImageTitle}>Signature</h5>
                  <FormikControl control="signature" name="signature" />
                </div>
                <div className={style.actions}>
                  <Button onClick={handlerGoBack} className={style.button} variant="outlined">
                    Back
                  </Button>
                  <Button
                    className={style.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {details ? 'Save' : 'Send'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Container>
  );
};

export default React.memo(DoctorCreatePrescriptionPage);
