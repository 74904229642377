/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import commonStyle from './../registration.module.css';
import style from './doctorsData.module.css';
import { Formik } from 'formik';
import { doctorsDataSchema } from './../../validationSchema';
import { Button } from '@material-ui/core';
import FormikControl from '../../formikControl';
import axios from './../../../API/configAPI';
import Loader from '../../../components/loader/loader';
import getFormattedPhotoId from '../../../helpers/getFormattedPhotoId';
import getFormattedChips from '../../../helpers/getFormattedChips';

const DoctorsData = ({ nextStep, backStep, viewSetting }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get('auth/me').then(({ data }) => {
      setUser(data);
    });
  }, []);

  const initialValues = {
    qualifications: getFormattedChips(user?.doctorData?.qualifications, 'qualification') || null,
    specialities: getFormattedChips(user?.doctorData?.specialities, 'speciality') || null,
    photo: getFormattedPhotoId(user?.doctorData?.photoFileId) || null,
    photoId: user?.doctorData?.photoId || '',
    licence: getFormattedPhotoId(user?.doctorData?.licenceFileId) || null,
    licenceId: user?.doctorData?.licenceId || '',
    registrationId: user?.doctorData?.registrationId || '',
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const requestType = user.doctorData?.id ? 'put' : 'post';
    axios[requestType]('doctor-data', values)
      .then(() => {
        resetForm();
        setSubmitting(false);
        nextStep();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {!user && <Loader />}
      {user && (
        <div className={commonStyle.container}>
          <div className={commonStyle.header}>
            <h3 className={commonStyle.title}>Doctor&apos;s data</h3>
          </div>
          <Formik initialValues={initialValues} validationSchema={doctorsDataSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <form autoComplete="off" className={commonStyle.form} onSubmit={handleSubmit}>
                <div className={style.formGrid}>
                  <FormikControl
                    control="chipsInput"
                    label="Qualification"
                    type="chips"
                    name="qualifications"
                    searchRoutePath="doctor-data/qualifications"
                    addRoutePath="doctor-data/qualification"
                    placeholder="Add your qualification"
                  />
                  <FormikControl
                    control="chipsInput"
                    label="Specialities"
                    type="chips"
                    name="specialities"
                    searchRoutePath="doctor-data/specialities"
                    addRoutePath="doctor-data/speciality"
                    placeholder="Add your specialities"
                  />
                </div>
                <div className={style.formGrid}>
                  <div className={style.formSubGrid}>
                    <FormikControl control="imageUpload" name="photo" label="Upload National ID" />
                    <FormikControl
                      control="textInput"
                      label="Type of ID"
                      type="text"
                      name="photoId"
                      placeholder="*** *** ***"
                    />
                  </div>
                  <div className={style.formSubGrid}>
                    <FormikControl control="imageUpload" name="licence" />
                    <FormikControl
                      control="textInput"
                      label="Registration licence ID "
                      type="text"
                      name="licenceId"
                      placeholder="*** *** ***"
                    />
                  </div>
                </div>
                <div className={style.formGrid}>
                  <FormikControl
                    control="textInput"
                    label="National ID number"
                    type="text"
                    name="registrationId"
                    placeholder="*** *** ***"
                  />
                </div>
                <div className={[commonStyle.actions, viewSetting && commonStyle.actionsSettings].join(' ')}>
                  {!viewSetting && (
                    <Button className={commonStyle.button} variant="outlined" onClick={backStep}>
                      Back
                    </Button>
                  )}
                  <Button
                    className={commonStyle.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {viewSetting ? 'Save' : 'Next Step'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default DoctorsData;
