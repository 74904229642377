import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import style from './modal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from '../../store/modalSlice';

export const TransitionsModal = () => {
  const [open, setOpen] = useState(false);
  const content = useSelector((state) => state.modal.content);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(!!content);
  }, [content]);

  useEffect(() => {
    if (open) {
      dispatch(setModalAnswer(''));
    }
  }, [open]);

  const handleClose = () => {
    dispatch(setModalContent(null));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={style.modalOverlay}
      open={open}
      onClose={() => {
        if (content?.isClosedByOverlay) {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={style.modalWindow}>
          {content?.isClosedByOverlay && (
            <span className={style.modalClose} onClick={handleClose}>
              &#x2715;
            </span>
          )}
          <div className={style.modalHeader}>
            <h3 className={style.modalTitle}>{content?.title}</h3>
          </div>
          <div className={style.modalBody}>
            <p className={style.modalDescription}>{content?.description}</p>
          </div>
          <div className={[style.modalFooter, content?.actions?.length > 1 && style.modalFooterGrid].join(' ')}>
            {content?.actions.map(({ title, variant, color, answerModal }, index) => {
              return (
                <Button
                  key={index}
                  variant={variant}
                  color={color}
                  onClick={() => {
                    dispatch(setModalAnswer(answerModal));
                    handleClose();
                  }}
                  className={style.modalButton}
                >
                  {title}
                </Button>
              );
            })}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
