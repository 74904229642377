import dayJs from './../../helpers/getDayJsWithTimezone';

export const filterSettings = [
  {
    settingName: 'This year',
    from: dayJs().startOf('year').format('YYYY-MM-DD'),
    to: dayJs().startOf('year').add(1, 'year').format('YYYY-MM-DD'),
  },
  {
    settingName: 'Last year',
    from: dayJs().subtract(1, 'year').format('YYYY-MM-DD'),
    to: dayJs().format('YYYY-MM-DD'),
  },
  {
    settingName: 'This month',
    from: dayJs().startOf('month').format('YYYY-MM-DD'),
    to: dayJs().startOf('month').add(1, 'month').format('YYYY-MM-DD'),
  },
  {
    settingName: 'Last month',
    from: dayJs().subtract(1, 'month').format('YYYY-MM-DD'),
    to: dayJs().format('YYYY-MM-DD'),
  },
  {
    settingName: 'Last 3 months',
    from: dayJs().subtract(3, 'month').format('YYYY-MM-DD'),
    to: dayJs().format('YYYY-MM-DD'),
  },
  {
    settingName: 'Last 6 months',
    from: dayJs().subtract(6, 'month').format('YYYY-MM-DD'),
    to: dayJs().format('YYYY-MM-DD'),
  },
  {
    settingName: 'This week',
    from: dayJs().startOf('week').format('YYYY-MM-DD'),
    to: dayJs().startOf('week').add(1, 'week').format('YYYY-MM-DD'),
  },
  {
    settingName: 'All',
    from: '',
    to: '',
  },
];

export const valuesRadio = [
  { settingName: 'Invoice date', key: 'invoiceDate' },
  { settingName: 'Due date', key: 'dueDate' },
];
