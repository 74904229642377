import React from 'react';
import style from './updatesFeed.module.css';
import { Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';

// icon
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ViewImage from '../viewImage/viewImage';

const UpdatesFeed = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <span className={style.title}>Updates feed</span>
        <Badge badgeContent={4} color="primary">
          <NotificationsNoneIcon />
        </Badge>
      </div>
      <div className={style.message}>
        <div className={style.messageHeader}>
          <ViewImage imageId={user?.personalData?.avatarId} size="18px" borderRadius="8px" />
          <span className={style.messageSender}>Luis Snyder</span>
          <span className={style.messageDate}>04 Nov 2020</span>
        </div>
        <p className={style.messageText}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>
    </div>
  );
};

export default React.memo(UpdatesFeed);
