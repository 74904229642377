import React from 'react';
import { Button } from '@material-ui/core';

import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import EndCallIcon from '../../../../icons/endCallIcon';

export default function EndCallButton(props: { className?: string }) {
  const { room } = useVideoContext();

  return <Button onClick={() => room.disconnect()} data-cy-disconnect startIcon={<EndCallIcon />} />;
}
