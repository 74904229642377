import React, { useState, useEffect } from 'react';
import style from './updatesFeedPage.module.css';
import { Container } from '@material-ui/core';
import Loader from '../../components/loader/loader';
import { useHistory } from 'react-router';
import Pagination from '@material-ui/lab/Pagination';
import axios from '../../API/configAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UpdatesFeedListItem from '../../components/updatesFeedListItem/updatesFeedListItem';

const UpdatesFeedPage = () => {
  const COUNT_SHOW_ON_PAGE = 8;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [updatesFeedList, setUpdatesFeedList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const matches = useMediaQuery('(max-width:576px)');

  useEffect(() => {
    getPatient();
  }, [page]);

  const getPatient = () => {
    setIsLoading(true);
    setUpdatesFeedList([]);
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    axios
      .get(`user/search/patients?take=${COUNT_SHOW_ON_PAGE}&skip=${skip}`)
      .then(({ data: { results, total } }) => {
        setUpdatesFeedList(results);
        if (total <= COUNT_SHOW_ON_PAGE) {
          setTotalPages(0);
        } else {
          setTotalPages(Math.ceil(total / COUNT_SHOW_ON_PAGE));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <Container>
          <h3 className={style.title}>Updates feed</h3>
        </Container>
      </div>
      <div className={style.content}>
        <Container className={style.container}>
          {isLoading && <Loader />}
          {!isLoading && updatesFeedList.length !== 0 && (
            <ul className={style.updatesFeedList}>
              {updatesFeedList.map((item) => (
                <li key={item.id} className={style.updatesFeedListItem}>
                  <UpdatesFeedListItem updateEvent={item} />
                </li>
              ))}
            </ul>
          )}
          {!isLoading && updatesFeedList.length === 0 && (
            <div className={style.notFoundBlock}>
              <h3 className={style.notFoundBlockTitle}>Here is no updates feed yet</h3>
            </div>
          )}
          {totalPages !== 0 && (
            <div className={style.patientsPaginationWrapper}>
              <Pagination
                siblingCount={Number(!matches)}
                count={totalPages}
                color="secondary"
                onChange={handleChangePagination}
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default React.memo(UpdatesFeedPage);
