import React from 'react';
import style from './footer.module.css';
import Logo from './../../components/logo/logo';
import { Container } from '@material-ui/core';
import { footerDataConfig } from './footerConfig';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <Container>
        <div className={style.footerGrid}>
          <div className={style.footerWrapperLogo}>
            <div className={style.sizeLogo}>
              <Logo themeLogo={footerDataConfig.logoTheme} />
            </div>
            {footerDataConfig.description && <p>{footerDataConfig.description}</p>}
          </div>
          <div className={style.listsWrapper}>
            <ul className={style.footerDataList}>
              <li className={style.footerDataItem}>
                <span>{footerDataConfig.contact.phoneLabel}</span>
                <a
                  href={`tel:${footerDataConfig.contact.phone}`}
                  className={style.footerDataLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className={style.footerDataLinkText}>{footerDataConfig.contact.phonePlaceholder}</span>
                </a>
              </li>
              <li className={style.footerDataItem}>
                <span>{footerDataConfig.contact.emailLabel}</span>
                <a
                  href={`mailto:${footerDataConfig.contact.email}`}
                  className={style.footerDataLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className={style.footerDataLinkText}>{footerDataConfig.contact.email}</span>
                </a>
              </li>
              <li className={style.footerDataItem}>
                <span>{footerDataConfig.contact.addressLabel}</span>
                <address className={style.address}>{footerDataConfig.contact.address}</address>
              </li>
            </ul>
            <ul className={style.socialNetworksList}>
              {footerDataConfig.socialNetworks.map(({ icon, title, url }, index) => {
                return (
                  <li key={index} className={style.socialNetworksItem}>
                    <a href={url} className={style.socialNetworksLink} target="_blank" rel="noopener noreferrer">
                      {icon && <span className={icon}></span>}
                      <span className={style.socialNetworksLinkTitle}>{title}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Container>
      <div className={style.footerDown}>
        <Container>
          <div className={style.footerDownGrid}>
            <p>&copy; {footerDataConfig.copyrightText}</p>
            <div className={style.footerDownLink}>
              <a href={footerDataConfig.contactUs.linkURL} rel="noopener noreferrer">
                {footerDataConfig.contactUs.linkTitle}
              </a>
              <a href={footerDataConfig.policy.linkURL} rel="noopener noreferrer">
                {footerDataConfig.policy.linkTitle}
              </a>
              <a href={footerDataConfig.terms.linkURL} rel="noopener noreferrer">
                {footerDataConfig.terms.linkTitle}
              </a>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
