import React from 'react';
import PropTypes from 'prop-types';
import style from './logo.module.css';
import logo_for_white_bg from './../../assets/images/logo/IbisMD_logo.svg';
import logo_for_dark_bg from './../../assets/images/logo/IbisMD_logo_white.svg';
import { LIGHT_THEME_LOGO, DARK_THEME_LOGO } from './../../constants/themeLogo';

const Logo = ({ themeLogo }) => {
  switch (themeLogo) {
    case DARK_THEME_LOGO:
      return <img className={style.logo} src={logo_for_dark_bg} alt="logoForDarkBg" />;
    case LIGHT_THEME_LOGO:
    default:
      return <img className={style.logo} src={logo_for_white_bg} alt="logoForWhiteBg" />;
  }
};

Logo.defaultProps = {
  themeLogo: LIGHT_THEME_LOGO,
};

Logo.propTypes = {
  themeLogo: PropTypes.string,
};

export default React.memo(Logo);
