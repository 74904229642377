/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import style from './formComponents.module.css';
import { useField } from 'formik';

const RadioButtonGroup = ({ label, name, options, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <div className={style.wrapper}>
      {label && <label className={style.label}>{label}</label>}
      <div className={[style.radioButtonGrid, meta.touched && meta.error && style.errorGroup].join(' ')}>
        {options.map((option) => {
          return (
            <label key={option.key} className={style.labelCheckbox}>
              {option.key}
              <span className={[field.value === option.value && style.checked, style.box].join(' ')}>
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.12667 5.87833L5.30333 7.055L12.3583 0L13.5367 1.17833L5.30333 9.41167L0 4.10833L1.17833 2.93L2.94917 4.70083L4.12667 5.8775V5.87833Z"
                    fill="white"
                  />
                </svg>
              </span>
              <input
                type="radio"
                id={option.value}
                className={style.checkboxInput}
                {...field}
                {...props}
                checked={field.value === option.value}
                value={option.value}
              />
            </label>
          );
        })}
      </div>
      {meta.touched && meta.error && <span className={[style.message, style.error].join(' ')}>{meta.error}</span>}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default React.memo(RadioButtonGroup);
