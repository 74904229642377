import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './timePicker.module.css';
import { useField } from 'formik';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RcTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const TimePicker = ({ name, label, onChange, timeFormat24 }) => {
  const [field, meta, helpers] = useField(name);
  const [time, setTime] = useState(moment(field.value, 'HH:mm'));

  useEffect(() => {
    setTime(moment(field.value, 'HH:mm'));
    helpers.setTouched(true);
  }, [field.value, meta.error]);

  const handleChange = (momentDate) => {
    if (!momentDate) return;
    helpers.setValue(momentDate.format('HH:mm'));
    helpers.setTouched(true);
    onChange(name, momentDate.format('HH:mm'));
  };

  return (
    <div className={style.wrapper}>
      {label && (
        <label className={style.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={[style.wrapperInput, meta.touched && meta.error && style.errorInput].join(' ')}>
        <RcTimePicker
          className={style.textInput}
          popupClassName={style.customPanel}
          id={name}
          name={name}
          showSecond={false}
          value={time}
          onChange={handleChange}
          inputIcon={<AccessTimeIcon className={style.icon} fontSize="small" />}
          clearIcon={<span className={style.clear}>&#x2715;</span>}
          use12Hours={!timeFormat24}
        />
        {meta.touched && meta.error && <span className={[style.message, style.error].join(' ')}>{meta.error}</span>}
      </div>
    </div>
  );
};

TimePicker.defaultProps = {
  label: '',
  onChange: () => {},
  timeFormat24: false,
};

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  timeFormat24: PropTypes.bool,
};

export default React.memo(TimePicker);
