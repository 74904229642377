import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import style from './notFoundPage.module.css';

export const NotFoundPage = () => {
  const history = useHistory();
  return (
    <section className={style.wrapper}>
      <h1 className={style.titlePage}>Page is not found</h1>
      <Button variant="contained" color="primary" size="large" onClick={history.goBack}>
        Go back
      </Button>
    </section>
  );
};
