import React from 'react';
import RestorePasswordForm from '../../forms/authorization/restorePasswordForm';
import { Single } from '../../layouts/single/single';

export const RestorePasswordPage = ({ ...props }) => {
  return (
    <Single>
      <RestorePasswordForm {...props} />
    </Single>
  );
};
