import React, { Component } from 'react';
import './assets/Conversation.css';
import MessageBubble from './MessageBubble';
import Dropzone from 'react-dropzone';
import styles from './assets/Conversation.module.css';
import { ButtonBase, Button } from '@material-ui/core';
import Icon from '@ant-design/icons';
import ConversationsMessages from './ConversationsMessages';
import PropTypes from 'prop-types';
import ClipIcon from '../../icons/clipIcon';

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessage: '',
      conversationProxy: props.conversationProxy,
      messages: [],
      loadingState: 'initializing',
      boundConversations: new Set(),
    };
  }

  loadMessagesFor = (thisConversation) => {
    if (this.state.conversationProxy === thisConversation) {
      thisConversation
        .getMessages()
        .then((messagePaginator) => {
          if (this.state.conversationProxy === thisConversation) {
            this.setState({ messages: messagePaginator.items, loadingState: 'ready' });
          }
        })
        .catch((err) => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
          this.setState({ loadingState: 'failed' });
        });
    }
  };

  componentDidMount = () => {
    if (this.state.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on('messageAdded', (m) => this.messageAdded(m, newConversation));
        this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
      }
    }
  };

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.conversationProxy !== oldState.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on('messageAdded', (m) => this.messageAdded(m, newConversation));
        this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
      }
    }
  };

  static getDerivedStateFromProps(newProps, oldState) {
    let logic = oldState.loadingState === 'initializing' || oldState.conversationProxy !== newProps.conversationProxy;
    if (logic) {
      return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
    } else {
      return null;
    }
  }

  messageAdded = (message, targetConversation) => {
    if (targetConversation === this.state.conversationProxy)
      this.setState((prevState, props) => ({
        messages: [...prevState.messages, message],
      }));
  };

  onMessageChanged = (event) => {
    this.setState({ newMessage: event.target.value });
  };

  sendMessage = (event) => {
    event.preventDefault();
    if (this.state.newMessage.length > 0) {
      const message = this.state.newMessage;
      this.setState({ newMessage: '' });
      this.state.conversationProxy.sendMessage(message);
    }
  };

  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles)
    this.state.conversationProxy.sendMessage({ contentType: acceptedFiles[0].type, media: acceptedFiles[0] });
  };

  render = () => {
    return (
      <Dropzone onDrop={this.onDrop} accept="image/png,image/jpeg,image/jpeg,image/bmp,image/gif,
      application/pdf,application/xml,text/xml,application/msword,application/rtf,application/vnd.oasis.opendocument.text,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/mp4,video/avi,video/mov">
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} onClick={() => { }} id="OpenChannel" style={{ position: 'relative', top: 0 }}>
            {isDragActive && (
              <div className={styles.drop}>
                <Icon type={'cloud-upload'} style={{ fontSize: '5em', color: '#fefefe' }} />
                <h3 style={{ color: '#fefefe' }}>Release to Upload</h3>
              </div>
            )}
            <div
              className={styles.messages}
              style={{
                filter: `blur(${isDragActive ? 4 : 0}px)`,
                maxHeight: '100vh',
              }}
            >
              <input id="files" {...getInputProps()} />
              <div id="messagesContainer">
                <ConversationsMessages identity={this.props.myIdentity} messages={this.state.messages} />
              </div>
              <div>
                <form onSubmit={this.sendMessage}>
                  <div
                    compact={true}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      height: '50px',
                    }}
                  >
                    <div
                      style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px', paddingTop: '15px' }}
                    >
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                          e.preventDefault()
                          this.onDrop(e.target.files)
                        }}
                        onClick={(event) => {
                          event.target.value = null
                        }}
                      />
                      <label htmlFor="contained-button-file">
                        <ButtonBase component="span">
                          <ClipIcon />
                        </ButtonBase>
                      </label>

                    </div>
                    <textarea
                      placeholder={'Write your message…'}
                      data-max-rows="5"
                      data-rows="1"
                      badinput="false"
                      type={'text'}
                      name={'message'}
                      id="type-a-message"
                      autoComplete={'off'}
                      disabled={this.state.loadingState !== 'ready'}
                      onChange={this.onMessageChanged}
                      value={this.state.newMessage}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '15px', paddingTop: '15px' }}>
                      <ButtonBase
                        onClick={this.sendMessage}
                        icon="enter"
                        type={'submit'}
                        style={{
                          color: '#2A64D1',
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: 500,
                        }}
                      >
                        Send
                      </ButtonBase>
                      <div style={{ flexGrow: 1 }} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  };
}

Conversation.propTypes = {
  myIdentity: PropTypes.string.isRequired,
};

export default Conversation;
