import React from 'react';
import style from './patientLabResultPage.module.css';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Loader from '../../components/loader/loader';
import LabResultsDetails from '../../layouts/labResultsDetails/labResultsDetails';

const PatientLabResultPage = () => {
  const patient = useSelector((state) => state.auth.user);
  return (
    <>
      {!patient && <Loader />}
      {patient && (
        <Container>
          <div className={style.wrapper}>
            <div className={style.content}>
              <LabResultsDetails patientId={patient.id} countShowOnPage={8} />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default PatientLabResultPage;
