import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './authorization.module.css';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import { restorePasswordSchema } from '../validationSchema';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import { useHistory, useParams } from 'react-router';
import { SIGN_IN } from './../../constants/locationPath';
import axios from './../../API/configAPI';
import FormikControl from '../formikControl';

const RestorePasswordForm = ({ isCreatePassword }) => {
  const REDIRECT_TO_SIGN_IN = '[RestorePasswordForm] Redirect to sign in';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (answerModal === REDIRECT_TO_SIGN_IN) {
      dispatch(setModalAnswer(''));
      history.push(SIGN_IN);
    }
  }, [answerModal]);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Success.',
    description: `
      Password ${isCreatePassword ? 'created' : 'changed'} successfully. 
      Log in using a new password.
    `,
    actions: [
      {
        title: 'Go sign in',
        answerModal: REDIRECT_TO_SIGN_IN,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const initialValues = {
    password: '',
    passwordConfirm: '',
  };
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const { password } = values;
    axios
      .post(`auth/restore-password/${token}`, { password })
      .then(() => {
        resetForm();
        setSubmitting(false);
        dispatch(setModalContent(contentModal));
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2 className={style.title}>{isCreatePassword ? 'Create' : 'Restore'} password</h2>
        <p className={style.subtitle}>Enter new password and confirm.</p>
      </div>
      <Formik initialValues={initialValues} validationSchema={restorePasswordSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <FormikControl
              control="passwordInput"
              label="New password"
              type="password"
              name="password"
              placeholder="8+ characters"
            />
            <FormikControl
              control="passwordInput"
              label="Confirm Password"
              type="password"
              name="passwordConfirm"
              placeholder="8+ characters"
            />
            <Button
              className={style.button}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

RestorePasswordForm.defaultProps = {
  isCreatePassword: false,
};

RestorePasswordForm.propTypes = {
  isCreatePassword: PropTypes.bool,
};

export default React.memo(RestorePasswordForm);
