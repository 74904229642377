import React, { useState } from 'react';
import style from './settingPage.module.css';
import { Container, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/loader';
import { setModalContent } from '../../store/modalSlice';
import DoctorData from '../../forms/doctorsRegistration/personalData/personalData';
import PatientData from '../../forms/patientRegistration/personalData/personalData';
import DoctorsData from '../../forms/doctorsRegistration/doctorsData/doctorsData';
import OfficeDetails from '../../forms/doctorsRegistration/officeDetails/officeDetails';
import BootstrapInput from '../../components/bootstrapComponent/bootstrapInput';
import Appointments from '../../layouts/appointments/appointments';
import { useLocation } from 'react-router';
import SubscribeSetting from '../../forms/settingsForm/subscribeSetting/subscribeSetting';
import RestorePassword from '../../forms/settingsForm/restorePassword/restorePassword';
import { DOCTOR_ROLE } from '../../constants/userRole';

// icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const SettingPage = () => {
  const RELOAD_FORM = 'Reload form';
  const { state } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const pointMenuDoctorPersonalData = { settingName: 'Personal data', key: 'doctor-personal-data' };
  const pointMenuPatientPersonalData = { settingName: 'Personal data', key: 'patient-personal-data' };
  const pointMenuDoctorData = { settingName: 'Doctor data', key: 'doctor-data' };
  const pointMenuOfficeDetails = { settingName: 'Office details', key: 'office-details' };
  const pointMenuSubscribe = { settingName: 'Subscribe', key: 'subscribe' };
  const pointMenuPassword = { settingName: 'Password', key: 'password' };
  const pointMenuAppointments = { settingName: 'Appointments', key: 'appointments' };

  const doctorSettings = [
    pointMenuDoctorPersonalData,
    pointMenuDoctorData,
    pointMenuOfficeDetails,
    pointMenuSubscribe,
    pointMenuPassword,
    pointMenuAppointments,
  ];

  const patientSettings = [pointMenuPatientPersonalData, pointMenuPassword];

  const [activeSetting, setActiveSetting] = useState(
    state || (user?.role === DOCTOR_ROLE ? doctorSettings[0].key : patientSettings[0].key),
  );
  const settings = user?.role === DOCTOR_ROLE ? doctorSettings : patientSettings;

  const toggleSettings = (settingName) => {
    if (settingName !== activeSetting) {
      setActiveSetting(settingName);
    }
  };

  const handlerSave = (message) => {
    const reloadOfActiveSetting = activeSetting;
    setActiveSetting(RELOAD_FORM);
    setTimeout(() => setActiveSetting(reloadOfActiveSetting), 0);
    if (message) {
      dispatch(setModalContent(getContentModal(message)));
    }
  };

  const getSettingsContent = () => {
    switch (activeSetting) {
      case pointMenuDoctorPersonalData.key:
        return <DoctorData viewSetting nextStep={handlerSave} />;
      case pointMenuPatientPersonalData.key:
        return <PatientData viewSetting nextStep={handlerSave} />;
      case pointMenuDoctorData.key:
        return <DoctorsData viewSetting nextStep={handlerSave} />;
      case pointMenuOfficeDetails.key:
        return <OfficeDetails viewSetting nextStep={handlerSave} />;
      case pointMenuSubscribe.key:
        return <SubscribeSetting nextStep={handlerSave} />;
      case pointMenuPassword.key:
        return <RestorePassword nextStep={handlerSave} />;
      case pointMenuAppointments.key:
        return <Appointments />;
      case RELOAD_FORM:
        return <Loader />;
      default:
        return;
    }
  };

  const getContentModal = (message) => {
    return {
      isClosedByOverlay: true,
      title: 'Saved',
      description: message,
      actions: [
        {
          title: 'ok',
          answerModal: '',
          variant: 'contained',
          color: 'primary',
        },
      ],
    };
  };

  return (
    <Container className={style.container}>
      <h2 className={style.title}>Settings</h2>
      <div className={style.grid}>
        <div className={style.settingMenu}>
          <ul className={style.settingList}>
            {settings.map(({ settingName, key }, index) => (
              <li
                key={index}
                className={[style.settingItem, key === activeSetting && style.activeSetting].join(' ')}
                onClick={() => toggleSettings(key)}
              >
                {settingName}
              </li>
            ))}
          </ul>
          <Select
            fullWidth
            className={style.settingListSelect}
            value={activeSetting}
            onChange={({ target: { value } }) => toggleSettings(value)}
            variant="outlined"
            color="secondary"
            input={<BootstrapInput />}
            IconComponent={KeyboardArrowDownIcon}
          >
            {settings.map(({ settingName, key }, index) => (
              <MenuItem key={index} value={key} className={style.settingItemSelect}>
                {settingName}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={style.content}>
          {!user && <Loader />}
          {user && getSettingsContent()}
        </div>
      </div>
    </Container>
  );
};

export default React.memo(SettingPage);
