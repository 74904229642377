import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import style from './authorization.module.css';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/authHook';
import { signInSchema } from '../validationSchema';
import axios from '../../API/configAPI';
import FormikControl from '../formikControl';

const SignInHeaderForm = () => {
  const { login } = useAuth();
  const history = useHistory();
  const RESEND_LINK = '[SignInHeaderForm] Resend link';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const [emailResend, setEmailResend] = useState();

  useEffect(() => {
    if (answerModal === RESEND_LINK) {
      dispatch(setModalAnswer(''));
      axios.get(`auth/resend-verification/${emailResend}`).then(() => {
        dispatch(setModalContent(contentModal));
      });
    }
  }, [answerModal]);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Email is not confirmed',
    description: `
      The email confirmation link was sent to your address. 
      Check it, please.
    `,
    actions: [
      {
        title: 'Resend link',
        answerModal: RESEND_LINK,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        keepMeLoginCode: false,
      }}
      validationSchema={signInSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setEmailResend(values.email);
        axios
          .post('auth/login', { ...values })
          .then(({ data: { accessToken, user } }) => {
            resetForm();
            setSubmitting(false);
            login(accessToken, user);
            history.push(`/${user.role}`);
          })
          .catch((error) => {
            setSubmitting(false);
            if (error?.response?.data?.message === 'Email is not confirmed') {
              dispatch(setModalContent(contentModal));
            }
          });
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form className={style.formInHeader} onSubmit={handleSubmit}>
          <FormikControl
            control="textInput"
            type="email"
            name="email"
            placeholder="Email"
            styleInput={{
              padding: '8px 12px 4px',
            }}
          />
          <FormikControl
            control="passwordInput"
            type="password"
            name="password"
            placeholder="8+ characters"
            forgot={false}
            styleInput={{
              padding: '8px 36px 4px 12px',
            }}
          />
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            Sign In
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default SignInHeaderForm;
