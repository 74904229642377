import React, { useCallback, useRef } from 'react';

// import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';

import VideoOffIcon from '../../../../icons/videoOffIcon';
import VideoOnIcon from '../../../../icons/videoOnIcon';

import { useHasVideoInputDevices } from '../../../../hooks/deviceHooks/deviceHooks';
import useLocalVideoToggle from '../../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const hasVideoDevices = useHasVideoInputDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <ButtonBase className={props.className} onClick={toggleVideo} disabled={!hasVideoDevices || props.disabled}>
      {isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
    </ButtonBase>
  );
}
