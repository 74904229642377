import dayjs from 'dayjs';

export const getYearsToNow = (birth, suffix = false) => {
  if (!birth) return '';
  const currentDate = dayjs();
  const birthDate = dayjs(birth);
  let yearsOld = currentDate.year() - birthDate.year();
  if (
    currentDate.month() > birthDate.month() ||
    (currentDate.month() === birthDate.month() && !(currentDate.date() < birthDate.date()))
  ) {
    return `${yearsOld} ${suffix ? 'years' : ''}`;
  }
  return `${(yearsOld -= 1)} ${suffix ? 'years' : ''}`;
};
