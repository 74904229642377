import React, { useEffect, useState } from 'react';
import style from './prescriptionDetailsPage.module.css';
import { useHistory, useParams } from 'react-router';
import axios from './../../API/configAPI';
import Loader from '../../components/loader/loader';
import { Container, Button, useMediaQuery } from '@material-ui/core';
import dayjs from 'dayjs';
import ViewImage from '../../components/viewImage/viewImage';
import { useSelector, useDispatch } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import { DOCTOR_CREATE_PRESCRIPTION } from '../../constants/locationPath';
import NumberFormat from 'react-number-format';
import { FORMAT_PHONE } from '../../constants/formatValueInput';

const PrescriptionDetailsPage = () => {
  const DELETE_PRESCRIPTION = '[PrescriptionDetailsPage] Delete prescription';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const { id } = useParams();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:576px)');
  const currentUser = useSelector((state) => state.auth.user);

  const [prescriptionDetails, setPrescriptionDetails] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [patient, setPatient] = useState(null);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Delete prescription?',
    description: `
      Do you want to delete prescription?
    `,
    actions: [
      {
        title: 'No',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Yes',
        answerModal: DELETE_PRESCRIPTION,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  useEffect(() => {
    axios.get(`prescription/${id}`).then(({ data: details }) => {
      setPrescriptionDetails(details);
    });
  }, [id]);

  useEffect(() => {
    if (prescriptionDetails?.patientId) {
      axios
        .get(`user/${prescriptionDetails?.patientId}`)
        .then(({ data: patient }) => {
          setPatient(patient);
        })
        .catch((error) => {});
    }
    if (prescriptionDetails?.doctorId) {
      axios
        .get(`user/${prescriptionDetails?.doctorId}`)
        .then(({ data: doctor }) => {
          setDoctor(doctor);
        })
        .catch((error) => {});
    }
  }, [prescriptionDetails]);

  useEffect(() => {
    if (answerModal === DELETE_PRESCRIPTION) {
      dispatch(setModalAnswer(''));
      axios.delete(`prescription/${id}`).then(() => {
        handleClose();
      });
    }
  }, [answerModal]);

  const handleClose = () => {
    history.goBack();
  };

  const handleEdit = () => {
    history.push(`${DOCTOR_CREATE_PRESCRIPTION}/${prescriptionDetails.patientId}`, prescriptionDetails);
  };

  const handleDelete = () => {
    dispatch(setModalContent(contentModal));
  };

  return (
    <>
      {!(prescriptionDetails && doctor && patient) && <Loader />}
      {prescriptionDetails && doctor && patient && (
        <Container>
          <div className={style.wrapper}>
            <div className={style.header}>
              <h3 className={style.title}>Prescription</h3>
              <div className={style.dateWrapper}>
                <span className={style.dateLabel}>Date</span>
                <span className={style.dateValue}>{dayjs(prescriptionDetails.creationDate).format('DD.MM.YYYY')}</span>
              </div>
            </div>
            <div className={style.members}>
              <div className={style.member}>
                <h4 className={style.subtitle}>Doctor’s information</h4>
                <ul className={style.memberInfo}>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Doctors name</span>
                    <span
                      className={style.value}
                    >{`${doctor.personalData.firstName} ${doctor.personalData.lastName}`}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Office name</span>
                    <span className={style.value}>{doctor.offices[0].name}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Phone number</span>
                    <span className={style.value}>
                      <NumberFormat value={doctor.offices[0].phone} displayType={'text'} format={FORMAT_PHONE} />
                    </span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Business email</span>
                    <span className={style.value}>{doctor.offices[0].email}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Address</span>
                    <span className={style.value}>{`${doctor.offices[0].country || ''} ${
                      doctor.offices[0].state || ''
                    } ${doctor.offices[0].city || ''} ${doctor.offices[0].zioCode || ''} ${
                      doctor.offices[0].address || ''
                    }`}</span>
                  </li>
                </ul>
              </div>
              <div className={style.member}>
                <h4 className={style.subtitle}>Patient information</h4>
                <ul className={style.memberInfo}>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Patient name</span>
                    <span
                      className={style.value}
                    >{`${patient.personalData.firstName} ${patient.personalData.lastName}`}</span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Phone number</span>
                    <span className={style.value}>
                      <NumberFormat value={patient.personalData.phone} displayType={'text'} format={FORMAT_PHONE} />
                    </span>
                  </li>
                  <li className={style.memberGrid}>
                    <span className={style.name}>Email</span>
                    <span className={style.value}>{patient.email}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.details}>
              <h4 className={style.subtitle}>Medical supplies</h4>
              <p className={style.text}>{prescriptionDetails.supplies}</p>
              <div className={style.images}>
                {prescriptionDetails.imageId0 && (
                  <ViewImage imageId={prescriptionDetails.imageId0} size={matches ? '93px' : '140px'} isCanDownload />
                )}
                {prescriptionDetails.imageId1 && (
                  <ViewImage imageId={prescriptionDetails.imageId1} size={matches ? '93px' : '140px'} isCanDownload />
                )}
                {prescriptionDetails.imageId2 && (
                  <ViewImage imageId={prescriptionDetails.imageId2} size={matches ? '93px' : '140px'} isCanDownload />
                )}
              </div>
            </div>
            <div className={style.details}>
              <h4 className={style.subtitle}>Description</h4>
              <p className={style.text}>{prescriptionDetails.description}</p>
            </div>
            <div className={style.signature}>
              <h5 className={style.signatureTitle}>Signature</h5>
              <div className={style.signatureImage}>
                <ViewImage imageId={prescriptionDetails.signatureId} />
              </div>
            </div>
            <div
              className={[style.actions, currentUser.id === prescriptionDetails?.doctorId && style.actionsGrid].join(
                ' ',
              )}
            >
              <Button className={style.button} variant="outlined" onClick={handleClose}>
                Close
              </Button>
              {currentUser.id === prescriptionDetails?.doctorId && (
                <>
                  <Button className={style.button} variant="contained" color="primary" onClick={handleEdit}>
                    Edit
                  </Button>
                  <Button className={style.button} variant="contained" color="secondary" onClick={handleDelete}>
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default React.memo(PrescriptionDetailsPage);
