/* eslint-disable react/prop-types */
import React from 'react';
import style from './loader.module.css';
import { CircularProgress } from '@material-ui/core';

const Loader = ({ size }) => {
  return (
    <div className={style.wrapper}>
      <CircularProgress className={style.loader} size={size} />
    </div>
  );
};

export default React.memo(Loader);
