import React, { useState, useEffect } from 'react';
import style from './profileDoctorsPage.module.css';
import { Container, Button, useMediaQuery } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import axios from './../../API/configAPI';
import Loader from '../../components/loader/loader';
import ViewImage from '../../components/viewImage/viewImage';
import { getYearsToNow } from '../../helpers/getYearsToNow';
import { PATIENT_REQUEST } from './../../constants/locationPath';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import NumberFormat from 'react-number-format';
import { FORMAT_PHONE } from '../../constants/formatValueInput';

const ProfileDoctorsPage = () => {
  const REMOVE_DOCTOR = '[ProfileDoctorsPage] Remove doctor';
  const user = useSelector((state) => state.auth.user);
  const answerModal = useSelector((state) => state.modal.answer);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [connection, setConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const matches = useMediaQuery('(max-width:992px)');
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const requestDoctor = axios.get(`user/doctor/${id}`);
      const requestConnection = axios.get(`doctor-to-patient?counterPartyUserId=${id}`);

      Promise.all([requestDoctor, requestConnection])
        .then(([{ data: doctor }, { data: connection }]) => {
          setDoctor(doctor);
          setConnection(connection);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (answerModal === REMOVE_DOCTOR) {
      dispatch(setModalAnswer(''));
      pushRequest();
    }
  }, [answerModal]);

  const getContentModal = (isAdded) => {
    return {
      isClosedByOverlay: true,
      title: `Doctor ${isAdded ? 'added' : 'removed'}`,
      description: `
        Doctor ${isAdded ? 'added to' : 'removed from'} your doctors' list.
      `,
      actions: [
        {
          title: 'Ok',
          answerModal: '',
          variant: 'contained',
          color: 'primary',
        },
      ],
    };
  };

  const contentModule = {
    isClosedByOverlay: true,
    title: `Remove doctor?`,
    description: `
        Do you want remove doctor from your doctors list?
      `,
    actions: [
      {
        title: 'No',
        answerModal: '',
        variant: 'contained',
        color: 'secondary',
      },
      {
        title: 'Yes',
        answerModal: REMOVE_DOCTOR,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const handleNewAppointment = () => {
    history.push(`${PATIENT_REQUEST}/${id}`);
  };

  const handlerAddRemove = () => {
    if (connection?.id) {
      dispatch(setModalContent(contentModule));
    } else {
      pushRequest();
    }
  };

  const pushRequest = () => {
    setIsSubmit(true);
    const requestType = connection?.id ? 'delete' : 'post';
    const requestUrl = connection?.id ? `doctor-to-patient/${connection.id}` : 'doctor-to-patient';
    const requestData = connection?.id ? null : { doctorId: id, patientId: user.id };

    axios[requestType](requestUrl, requestData)
      .then(({ data }) => {
        setConnection(data);
        dispatch(setModalContent(getContentModal(!!data?.id)));
        setIsSubmit(false);
      })
      .catch((error) => {
        setIsSubmit(false);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && doctor && (
        <Container>
          <div className={style.wrapper}>
            <div className={[style.card, style.personalData].join(' ')}>
              <h4 className={style.title}>
                Dr.&ensp;
                <span>{doctor?.personalData?.firstName}</span>
                &ensp;
                <span>{doctor?.personalData?.lastName}</span>
              </h4>
              <ViewImage imageId={doctor.personalData.avatarId} size={matches ? '142px' : '250px'} />
              <ul className={style.list}>
                <li className={style.listItem}>
                  <span className={style.label}>Specialities</span>
                  <div className={style.values}>
                    {doctor?.doctorData?.specialities?.map(({ id, speciality }) => (
                      <span key={id} className={style.valuesItem}>
                        {speciality.name}
                      </span>
                    ))}
                  </div>
                </li>
                <li className={style.listItem}>
                  <span className={style.label}>Qualifications</span>
                  <div className={style.values}>
                    {doctor?.doctorData?.qualifications?.map(({ id, qualification }) => (
                      <span key={id} className={style.valuesItem}>
                        {qualification.name}
                      </span>
                    ))}
                  </div>
                </li>
                <li className={style.listItem}>
                  <span className={style.label}>Age</span>
                  <span className={style.values}>{getYearsToNow(doctor?.personalData?.birth)}</span>
                </li>
                <li className={style.listItem}>
                  <span className={style.label}>Sex</span>
                  <span className={style.values}>{doctor.personalData.gender}</span>
                </li>
                <li className={style.listItem}>
                  <span className={style.label}>Phone</span>
                  <span className={style.values}>
                    <NumberFormat value={doctor.personalData.phone} displayType={'text'} format={FORMAT_PHONE} />
                  </span>
                </li>
                <li className={style.listItem}>
                  <span className={style.label}>Address</span>
                  <div className={style.values}>
                    {doctor.personalData.country && (
                      <span className={style.valuesItem}>{doctor.personalData.country}</span>
                    )}
                    {doctor.personalData.state && <span className={style.valuesItem}>{doctor.personalData.state}</span>}
                    {doctor.personalData.city && <span className={style.valuesItem}>{doctor.personalData.city}</span>}
                    {doctor.personalData.zipCode && (
                      <span className={style.valuesItem}>{doctor.personalData.zipCode}</span>
                    )}
                    {doctor.personalData.address && (
                      <span className={style.valuesItem}>{doctor.personalData.address}</span>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            {doctor.offices.map((office) => (
              <div key={office.id} className={style.card}>
                <div>
                  <h4 className={style.title}>Office Details</h4>
                  <ul className={style.list}>
                    <li className={style.listItem}>
                      <span className={style.label}>Office name</span>
                      <span className={style.values}>{office.name}</span>
                    </li>
                    <li className={style.listItem}>
                      <span className={style.label}>Phone number</span>
                      <span className={style.values}>
                        <NumberFormat value={office.phone} displayType={'text'} format={FORMAT_PHONE} />
                      </span>
                    </li>
                    <li className={style.listItem}>
                      <span className={style.label}>Business email</span>
                      <span className={style.values}>{office.email}</span>
                    </li>
                    <li className={style.listItem}>
                      <span className={style.label}>Address</span>
                      <div className={style.values}>
                        {office.country && <span className={style.valuesItem}>{office.country}</span>}
                        {office.state && <span className={style.valuesItem}>{office.state}</span>}
                        {office.city && <span className={style.valuesItem}>{office.city}</span>}
                        {office.zipCode && <span className={style.valuesItem}>{office.zipCode}</span>}
                        {office.address && <span className={style.valuesItem}>{office.address}</span>}
                      </div>
                    </li>
                    <li className={style.listItem}>
                      <span className={style.label}>Social networks</span>
                      <div className={style.values}>
                        {office.instagram && (
                          <a href={office.instagram} target="_blank" rel="noopener noreferrer">
                            <span className="icon-instagram"></span>
                          </a>
                        )}
                        {office.facebook && (
                          <a href={office.facebook} target="_blank" rel="noopener noreferrer">
                            <span className="icon-facebook"></span>
                          </a>
                        )}
                        {office.youtube && (
                          <a href={office.youtube} target="_blank" rel="noopener noreferrer">
                            <span className="icon-youtube"></span>
                          </a>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
            <div className={style.actions}>
              <Button
                className={style.button}
                variant="contained"
                color="secondary"
                onClick={handlerAddRemove}
                disabled={isSubmit}
              >
                {connection?.id ? `remove from my doctor's list` : `Add to my doctor's list`}
              </Button>
              <Button className={style.button} variant="contained" color="primary" onClick={handleNewAppointment}>
                Schedule meeting
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default React.memo(ProfileDoctorsPage);
