import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './invoiceListItem.module.css';
import LinkToProfile from '../../components/linkToProfile/linkToProfile';
import dayJs from './../../helpers/getDayJsWithTimezone';
import { DOCTOR_ROLE, PATIENT_ROLE } from '../../constants/userRole';
import { Menu, MenuItem } from '@material-ui/core';
import { INVOICE_DETAILS } from '../../constants/locationPath';
import { useHistory } from 'react-router';
import axios from './../../API/configAPI';
import { useSelector, useDispatch } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import NumberFormat from 'react-number-format';

// icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PREFIX } from '../../constants/formatValueInput';

const InvoiceListItem = ({ invoiceData, updateList }) => {
  const DELETE_INVOICE = '[InvoiceListItem] Delete invoice';
  const {
    id,
    doctorId,
    patientId,
    number,
    status,
    total,
    invoiceDate,
    dueDate,
    firstName,
    lastName,
    avatarId,
  } = invoiceData;
  const user = useSelector((state) => state.auth.user);
  const isDoctor = user?.role === DOCTOR_ROLE;
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const pointMenuPDF = { value: 'Export as PDF', key: 'PDF' };
  const pointMenuDelete = { value: 'Delete', key: 'delete' };
  const pointMenuDetails = { value: 'Details', key: 'details' };

  const options = [pointMenuPDF, pointMenuDetails];

  if (isDoctor) {
    options.push(pointMenuDelete);
  }

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Delete invoice?',
    description: `
      Do you want to delete invoice?
    `,
    actions: [
      {
        title: 'No',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Yes',
        answerModal: `${DELETE_INVOICE} ${id}`,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const menuProps = {
    style: {
      background: '#FFFFFF',
      border: '1px solid #F7F7F9',
      boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.085)',
      borderRadius: '8px',
      padding: '12px 14px',
    },
  };

  useEffect(() => {
    if (answerModal === `${DELETE_INVOICE} ${id}`) {
      dispatch(setModalAnswer(''));
      deleteInvoice();
    }
  }, [answerModal]);

  const deleteInvoice = () => {
    axios
      .delete(`invoice/${id}`)
      .then(() => {
        updateList();
      })
      .catch((error) => {});
  };

  const getEventDate = (date) => {
    return dayJs(date).format('DD MMMM YYYY');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = (v) => {
    switch (v) {
      case pointMenuPDF.key:
        history.push(`${INVOICE_DETAILS}/${id}`, 'downloadPDF');
        break;
      case pointMenuDelete.key:
        dispatch(setModalContent(contentModal));
        break;
      case pointMenuDetails.key:
        history.push(`${INVOICE_DETAILS}/${id}`);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  return (
    <div className={style.invoicesListItem}>
      <div className={style.invoiceInfo}>
        <span className={style.label}>Name</span>
        <LinkToProfile
          firstName={firstName}
          lastName={lastName}
          role={isDoctor ? PATIENT_ROLE : DOCTOR_ROLE}
          userId={isDoctor ? patientId : doctorId}
          fontSize="medium"
          className={style.nameValue}
        />
      </div>
      <div className={style.invoiceInfo}>
        <span className={style.label}>Number</span>
        <span className={style.valueData}>{number}</span>
      </div>
      <div className={style.invoiceInfo}>
        <span className={style.label}>Invoice date</span>
        <span className={style.valueDate}>{getEventDate(invoiceDate)}</span>
      </div>
      <div className={style.invoiceInfo}>
        <span className={style.label}>Due date</span>
        <span className={style.valueDate}>{getEventDate(dueDate)}</span>
      </div>
      <div className={style.invoiceInfo}>
        <span className={style.label}>Total</span>
        <span className={style.valueData}>
          <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={PREFIX} />
        </span>
      </div>
      <div className={style.invoiceInfo}>
        <span className={style.label}>Status</span>
        <span className={[style.valueStatus, style[status]].join(' ')}>{status}</span>
      </div>
      <MoreVertIcon
        className={style.menuButton}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleCloseMenu} PaperProps={menuProps}>
        {options.map(({ value, key }) => (
          <MenuItem
            key={key}
            onClick={() => handleClose(key)}
            className={[
              style.menuItemStyle,
              style.menuItem,
              key === pointMenuDelete.key && style.menuItemMark,
              key === pointMenuPDF.key && style.menuItemMarkAccent,
            ].join(' ')}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

InvoiceListItem.propTypes = {
  invoiceData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    doctorId: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    invoiceDate: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatarId: PropTypes.string.isRequired,
  }),
  updateList: PropTypes.func.isRequired,
};

export default React.memo(InvoiceListItem);
