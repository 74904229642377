import React, { useState, useEffect } from 'react';
import { Badge, Layout } from 'antd';
import { Client as ConversationsClient } from '@twilio/conversations';
import { useSelector } from 'react-redux';

import './assets/Conversation.css';
import './assets/ConversationSection.css';

import Conversation from './Conversation';
import { useAppState } from '../../state';
import { useParams } from 'react-router';
import axios from '../../API/configAPI';
import Loader from '../../components/loader/loader';

const { Content } = Layout;

const ConversationsApp = () => {
  const [state, setState] = useState({
    isLoading: true,
    joined: false,
    statusString: null,
    conversationsReady: false,
    selectedConversationSid: null,
    newMessage: '',
    conversationsClient: null,
    conversationContent: null,
  });

  const user = useSelector((state) => state.auth.user);

  const { token, getToken, chatOpen, conversation, setConversation, appointment, setAppointment } = useAppState();
  const { appointmentId } = useParams();

  useEffect(() => {
    if (!state.joined) {
      handleJoin();
    }
    if (token) {
      initConversations();
    }
  }, [token]);

  const handleJoin = async () => {
    axios.get(`/appointment/${appointmentId}`).then((res) => {
      setAppointment(res.data);
      getToken('name', appointmentId);
    });

    setState({ joined: true });
  };

  const initConversations = async () => {
    window.conversationsClient = ConversationsClient;
    const conversationsClient = await ConversationsClient.create(token);

    conversationsClient.on('conversationJoined', (newConversation) => {
      const current = [newConversation].find((c) => {
        return c.sid == appointment.coversationSid;
      });

      if (current) {
        setConversation(current);
      } else {
        setState({ conversationContent: 'Loading your conversation!' });
      }
    });
  };

  return (
    <div className="conversations-window-wrapper" style={chatOpen ? { minWidth: '30%', maxHeight: '100vh' } : { display: 'none' }}>
      <div id="SelectedConversation">
        {conversation ? <Conversation conversationProxy={conversation} myIdentity={`${user.personalData.firstName} ${user.personalData.lastName} (${user.email})`} /> : <Loader />}
      </div>
    </div>
  );

};

export default ConversationsApp;
