import React from 'react';

export default function MicOffIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="27.6026" fill="white" stroke="#EDEEF0" stroke-width="0.794839" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.685 19.471L32.465 28.251H32.467C32.8192 27.553 33.0022 26.7819 33.001 26L33 22C33.0014 19.742 31.4892 17.7633 29.3101 17.1717C27.131 16.5801 24.8257 17.5224 23.685 19.471ZM32.425 33.839C31.3701 34.436 30.2048 34.8123 29 34.945V39H27V34.945C22.8214 34.4766 19.5234 31.1786 19.055 27H21.07C21.3861 29.1887 22.7176 31.0994 24.6614 32.1539C26.6053 33.2083 28.933 33.2825 30.94 32.354L29.389 30.804C27.879 31.2407 26.2515 30.9418 24.9951 29.9971C23.7387 29.0524 22.9998 27.5719 23 26V24.414L17.393 18.808L18.808 17.393L38.607 37.193L37.192 38.607L32.425 33.839ZM35.375 31.16L33.932 29.718C34.441 28.908 34.788 27.988 34.929 27H36.945C36.7799 28.4967 36.2399 29.9275 35.375 31.16Z"
        fill="#1D2239"
      />
    </svg>
  );
}
