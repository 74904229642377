import React from 'react';
import ParticipantList from '../participantList/participantList';
import { styled, makeStyles, Theme } from '@material-ui/core/styles';
import MainParticipant from '../mainParticipant/mainParticipant';
import Menu from '../menuBar/menu/menu';
import MobileTopMenuBar from '../../../components/videoCallComponents/mobileTopMenuBar/mobileTopMenuBar';

const useStyles = makeStyles((theme: Theme) => ({
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
}));

const Container = styled('div')(({ theme }) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight + theme.sidebarMobilePadding * 2 + theme.participantBorderWidth
    }px`;

  return {
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: `${theme.sidebarWidth}px 1fr`,
    gridTemplateRows: '100%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `100%`,
      gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
    },
  };
});

export default function Room() {
  const classes = useStyles();

  return (
    <Container>
      <MainParticipant />
      <ParticipantList />
      <Menu buttonClassName={classes.settingsButton} />
    </Container>
  );
}
