import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './blockedTime.module.css';
import axios from './../../API/configAPI';
import Loader from '../../components/loader/loader';
import DatePicker from 'react-datepicker';
import dayjs from './../../helpers/getDayJsWithTimezone';
import EditButton from '../../components/editButton/editButton';
import DialogBlockedTime from '../../components/dialogBlockedTime/dialogBlockedTime';
import { checkMatchDate } from './../../helpers/checkMathDate';
import { Button, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setBlockedTime, setCalendarDate, setBlockedTimeOfDay } from '../../store/scheduleSlice';
import { DOCTOR_ROLE } from '../../constants/userRole';

const BlockedTime = ({ scheduleId, initialDate }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const blockedTime = useSelector((state) => state.schedule.blockedTime);
  const blockedTimeOfDay = useSelector((state) => state.schedule.blockedTimeOfDay);
  const calendarDate = useSelector((state) => state.schedule.calendarDate);
  const canEdit = user?.role === DOCTOR_ROLE;

  const TODAY_DATE = new Date();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [editBlockedTime, setEditBlockedTime] = useState(null);
  const [isDisableAddBlockedTimeButton, setIsDisableAddBlockedTimeButton] = useState(false);

  const matches = useMediaQuery('(min-width:576px) and (max-width:768px)');

  useEffect(() => {
    dispatch(setCalendarDate(initialDate));

    return () => {
      dispatch(setCalendarDate(TODAY_DATE.toISOString()));
    };
  }, []);

  useEffect(() => {
    if (scheduleId) {
      getBlockedTime();
    }
    return () => {
      dispatch(setBlockedTime([]));
    };
  }, [scheduleId]);

  useEffect(() => {
    setIsDisableAddBlockedTimeButton(
      dayjs(TODAY_DATE).hour(0).minute(0).second(0).millisecond(0) >
        dayjs(calendarDate).hour(0).minute(0).second(0).millisecond(0),
    );
  }, [calendarDate]);

  useEffect(() => {
    getBlockedTimeOfDay();
    return () => {
      dispatch(setBlockedTimeOfDay([]));
    };
  }, [blockedTime, calendarDate]);

  const getBlockedTime = () => {
    axios.get(`blocked-time/${scheduleId}`).then(({ data: blockedTime }) => {
      dispatch(setBlockedTime(blockedTime));
    });
  };

  const getHeightBlockedTime = (start, end) => {
    // return 0.5px = 1 minute
    return `${(+dayjs(end) - +dayjs(start)) / 1000 / 60 / 2}px`;
  };

  const handleClickOpen = () => {
    setIsOpenDialog(true);
  };

  const handleClose = () => {
    setEditBlockedTime(null);
    setIsOpenDialog(false);
  };

  const handleCloseReload = () => {
    getBlockedTime();
    setEditBlockedTime(null);
    setIsOpenDialog(false);
  };

  const handlerEditBlockedTime = (id) => {
    const editTime = blockedTime.find(({ id: BlockedId }) => {
      return BlockedId === id;
    });
    setEditBlockedTime(editTime);
    setIsOpenDialog(true);
  };

  const getBlockedTimeOfDay = () => {
    if (blockedTime.length === 0) return;
    const blockedTimeOfDay = blockedTime.filter(({ id, start, end }) => checkMatchDate(calendarDate, start));
    dispatch(setBlockedTimeOfDay(blockedTimeOfDay));
  };

  const handlerChangeDate = (date) => {
    dispatch(setCalendarDate(date.toISOString()));
  };

  return (
    <>
      {!blockedTime && <Loader />}
      {blockedTime && (
        <div className={style.contentBlockedTime}>
          <div className={style.calendar}>
            <DatePicker
              selected={new Date(calendarDate)}
              onChange={(date) => handlerChangeDate(date)}
              inline
              dayClassName={(date) => {
                const blockedDay = blockedTime.filter(({ start }) => {
                  return checkMatchDate(date, start);
                });
                return blockedDay.length ? 'haveBlockedTime' : '';
              }}
              monthsShown={matches ? 2 : 1}
              minDate={canEdit ? null : TODAY_DATE}
            />
          </div>
          <div className={style.blockedTime}>
            <h3 className={style.subtitle}>Blocked time:</h3>
            {canEdit && (
              <div className={style.actions}>
                <Button
                  className={style.button}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleClickOpen}
                  disabled={isDisableAddBlockedTimeButton}
                >
                  Add blocked time
                </Button>
              </div>
            )}
            <div className={style.blockedTimeSheet}>
              <ul className={style.blockedTimeList}>
                {blockedTimeOfDay.length !== 0 &&
                  blockedTimeOfDay.map(({ id, start, end, firstName }) => (
                    <li key={id} className={style.blockedTimeItemWrapper}>
                      <div className={style.blockedTimeItem} style={{ minHeight: getHeightBlockedTime(start, end) }}>
                        {`${dayjs(start).tz().format('HH:mm')} - ${dayjs(end).tz().format('HH:mm')}`}
                        {!canEdit && <span>{firstName ? 'appointment' : 'blocked'}</span>}
                        {canEdit && <EditButton handlerClick={() => handlerEditBlockedTime(id)} size="30px" />}
                      </div>
                    </li>
                  ))}
                {blockedTimeOfDay.length === 0 && <li>no blocked time</li>}
              </ul>
            </div>
          </div>
        </div>
      )}
      {canEdit && (
        <DialogBlockedTime
          isOpen={isOpenDialog}
          handleClose={handleClose}
          calendarDate={calendarDate}
          handleCloseReload={handleCloseReload}
          edit={editBlockedTime}
        />
      )}
    </>
  );
};

BlockedTime.defaultProps = {
  initialDate: dayjs().toISOString(),
};

BlockedTime.propTypes = {
  scheduleId: PropTypes.string.isRequired,
  initialDate: PropTypes.string,
};

export default React.memo(BlockedTime);
