import React, { useEffect, useState } from 'react';
import style from './doctorPatientProfilePage.module.css';
import { Container, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import axios from './../../API/configAPI';
import Loader from '../../components/loader/loader';
import ViewImage from './../../components/viewImage/viewImage';
import { getYearsToNow } from './../../helpers/getYearsToNow';
import heightImage from './../../assets/images/patientParameters/height.png';
import weightImage from './../../assets/images/patientParameters/weight.png';
import {
  DOCTOR_CREATE_APPOINTMENTS,
  DOCTOR_CREATE_INVOICE,
  DOCTOR_CREATE_PRESCRIPTION,
  DOCTOR_DASHBOARD,
  PRESCRIPTIONS,
} from '../../constants/locationPath';
import LabResultsDetails from '../../layouts/labResultsDetails/labResultsDetails';
import PrescriptionPreview from './../../components/prescriptionPreview/prescriptionPreview';
import AppointmentListItem from './../../components/appointmentListItem/appointmentListItem';
import dayjs from './../../helpers/getDayJsWithTimezone';
import { UPCOMING, PAST } from './../../constants/appointmentType';
import { useSelector, useDispatch } from 'react-redux';
import { CONFIRMED } from '../../constants/appointmentStatus';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import NumberFormat from 'react-number-format';
import { FORMAT_PHONE } from '../../constants/formatValueInput';

// icons
import AddIcon from '@material-ui/icons/Add';

const DoctorPatientProfilePage = () => {
  const REMOVE_PATIENT = '[DoctorPatientProfilePage] Remove patient';
  const user = useSelector((state) => state.auth.user);
  const answerModal = useSelector((state) => state.modal.answer);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [patient, setPatient] = useState(null);
  const [activeTab, setActiveTab] = useState(UPCOMING);
  const [isLoadingAppointment, setIsLoadingAppointment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [connection, setConnection] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const requestPatient = axios.get(`user/${id}`);
      const requestPrescription = axios.get(`prescription?patientId=${id}&take=2&skip=0`);
      const requestConnection = axios.get(`doctor-to-patient?counterPartyUserId=${id}`);

      Promise.all([requestPatient, requestPrescription, requestConnection])
        .then(
          ([
            { data: patient },
            {
              data: { results },
            },
            { data: connection },
          ]) => {
            setPatient(patient);
            setPrescriptions(results);
            setConnection(connection);
            setIsLoading(false);
          },
        )
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const getAppointment = () => {
    if (patient && patient.id) {
      setIsLoadingAppointment(true);
      axios
        .get(
          `appointment/search?userId=${patient.id}&scheduleId=${user.scheduleId}&range=${activeTab}&status=${CONFIRMED}&take=2&skip=0`,
        )
        .then(({ data: { results } }) => {
          setAppointments(results);
          setIsLoadingAppointment(false);
        })
        .catch((error) => {
          setIsLoadingAppointment(false);
        });
    }
  };

  useEffect(() => {
    getAppointment();
  }, [patient, activeTab]);

  useEffect(() => {
    if (answerModal === REMOVE_PATIENT) {
      dispatch(setModalAnswer(''));
      removePatient();
    }
  }, [answerModal]);

  const handleSeeAllPrescriptions = () => {
    if (patient && patient.id) {
      history.push(`${PRESCRIPTIONS}/${patient.id}`);
    }
  };

  const handleSeeAllAppointments = () => {
    history.push(DOCTOR_DASHBOARD);
  };

  const handleCreateAppointment = () => {
    if (patient && patient.id) {
      history.push(DOCTOR_CREATE_APPOINTMENTS, { patientId: patient.id });
    }
  };

  const handlerChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const contentModalAsking = {
    isClosedByOverlay: true,
    title: `Remove patient?`,
    description: `
      Do you want remove patient from your patients list?
    `,
    actions: [
      {
        title: 'No',
        answerModal: '',
        variant: 'contained',
        color: 'secondary',
      },
      {
        title: 'Yes',
        answerModal: REMOVE_PATIENT,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const contentModalRemove = {
    isClosedByOverlay: true,
    title: `Patient removed`,
    description: `
      Patient removed from your patients' list.
    `,
    actions: [
      {
        title: 'Ok',
        answerModal: '',
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const handlerAddRemove = () => {
    dispatch(setModalContent(contentModalAsking));
  };

  const removePatient = () => {
    setIsSubmit(true);
    axios
      .delete(`doctor-to-patient/${connection.id}`)
      .then(({ data }) => {
        setConnection(data);
        dispatch(setModalContent(contentModalRemove));
        setIsSubmit(false);
        history.goBack();
      })
      .catch((error) => {
        setIsSubmit(false);
      });
  };

  const handlerSendInvoice = () => {
    history.push(`${DOCTOR_CREATE_INVOICE}`, { patientId: id });
  };

  const handlerAddPrescription = () => {
    if (patient && patient.id) {
      history.push(`${DOCTOR_CREATE_PRESCRIPTION}/${patient.id}`);
    }
  };

  const handlerChangeStatus = () => {
    getAppointment();
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && patient && (
        <Container>
          <div className={style.wrapper}>
            <div className={style.patientWrapper}>
              <div className={style.patientGrid}>
                <div className={style.personalData}>
                  <div className={style.personalInfo}>
                    <ViewImage imageId={patient?.personalData?.avatarId} size="116px" />
                    <div>
                      <h4 className={style.name}>
                        <span>{patient?.personalData?.firstName}</span>
                        &ensp;
                        <span>{patient?.personalData?.lastName}</span>
                      </h4>
                      <div className={style.data}>
                        <span className={style.dataName}>Age:</span>
                        <span className={style.dataValue}>{getYearsToNow(patient?.personalData?.birth)}</span>
                        <span className={style.dataName}>Sex:</span>
                        <span className={style.dataValue}>{patient?.personalData?.gender}</span>
                        <span className={style.dataName}>Birth:</span>
                        <span className={style.dataValue}>
                          {dayjs(patient?.personalData?.birth).format('DD.MM.YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={style.specialInfo}>
                    <h4 className={style.specialInfoLabel}>Illnesses</h4>
                    {patient?.personalData?.illnesses?.map(({ id, illness }) => (
                      <span key={id} className={style.specialName}>
                        {illness.name}
                      </span>
                    ))}
                    {!patient?.personalData?.illnesses?.length && (
                      <span className={style.specialName}>No information</span>
                    )}
                  </div>
                  <div className={style.specialInfo}>
                    <h4 className={style.specialInfoLabel}>Medications</h4>
                    {patient?.personalData?.medications?.map(({ id, medication }) => (
                      <span key={id} className={style.specialName}>
                        {medication.name}
                      </span>
                    ))}
                    {!patient?.personalData?.medications?.length && (
                      <span className={style.specialName}>No information</span>
                    )}
                  </div>
                  <div className={style.specialInfo}>
                    <h4 className={style.specialInfoLabel}>Allergies</h4>
                    {patient?.personalData?.allergies?.map(({ id, allergy }) => (
                      <span key={id} className={style.specialName}>
                        {allergy.name}
                      </span>
                    ))}
                    {!patient?.personalData?.allergies?.length && (
                      <span className={style.specialName}>No information</span>
                    )}
                  </div>
                  <Button
                    className={style.createAppointmentButton}
                    variant="contained"
                    color="primary"
                    onClick={handleCreateAppointment}
                  >
                    Schedule meeting
                  </Button>
                  {connection?.id && (
                    <Button
                      className={style.createAppointmentButton}
                      variant="contained"
                      color="secondary"
                      onClick={handlerAddRemove}
                      disabled={isSubmit}
                    >
                      Remove patient
                    </Button>
                  )}
                  <Button className={style.button} variant="outlined" color="primary" onClick={handlerSendInvoice}>
                    Send invoice
                  </Button>
                </div>
                <div className={style.contacts}>
                  <h4 className={style.contactsTitle}>Contacts</h4>
                  <div className={style.contactsDataWrapper}>
                    <div className={style.contactsData}>
                      <span className={style.contactsDataName}>Phone number</span>
                      <span className={style.contactsDataValue}>
                        <NumberFormat value={patient?.personalData?.phone} displayType={'text'} format={FORMAT_PHONE} />
                      </span>
                    </div>
                    <div className={style.contactsData}>
                      <span className={style.contactsDataName}>Email</span>
                      <span className={style.contactsDataValue}>{patient?.email}</span>
                    </div>
                    <div className={style.contactsData}>
                      <span className={style.contactsDataName}>Address</span>
                      <span className={style.contactsDataValue}>{patient?.personalData?.country}</span>
                      {patient?.personalData?.state && (
                        <span className={style.contactsDataValue}>{patient?.personalData?.state}</span>
                      )}
                      {patient?.personalData?.city && (
                        <span className={style.contactsDataValue}>{patient?.personalData?.city}</span>
                      )}
                      <span className={style.contactsDataValue}>{patient?.personalData?.address}</span>
                    </div>
                  </div>
                </div>
                <div className={style.parameters}>
                  <div className={style.parameter}>
                    <div className={style.parameterIcon}>
                      <img src={heightImage} alt="height" />
                    </div>
                    <h5 className={style.parameterTitle}>Height</h5>
                    <span className={style.parameterValue}>
                      {patient?.personalData?.height || '--'}
                      <span className={style.parameterUnit}>Ft</span>
                    </span>
                  </div>
                  <div className={style.parameter}>
                    <div className={style.parameterIcon}>
                      <img src={weightImage} alt="weight" />
                    </div>
                    <h5 className={style.parameterTitle}>Weight</h5>
                    <span className={style.parameterValue}>
                      {patient?.personalData?.weight || '--'}
                      <span className={style.parameterUnit}>Ib</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className={style.activities}>
                <div className={style.activity}>
                  <div className={style.activityHeader}>
                    <h4 className={style.activityTitle}>Prescriptions</h4>
                    <div>
                      <Button
                        size="small"
                        className={style.button}
                        variant="contained"
                        color="secondary"
                        onClick={handlerAddPrescription}
                      >
                        <AddIcon fontSize="inherit" />
                      </Button>
                      <span className={style.activityButton} onClick={handleSeeAllPrescriptions}>
                        See all
                      </span>
                    </div>
                  </div>
                  <div className={style.activityContent}>
                    {!!prescriptions.length && (
                      <ul className={style.activityList}>
                        {prescriptions.map(
                          ({ id, creationDate, description, avatarId, firstName, lastName, specialities }) => (
                            <li key={id} className={style.activityListItem}>
                              <PrescriptionPreview
                                id={id}
                                creationDate={creationDate}
                                description={description}
                                avatarId={avatarId}
                                firstName={firstName}
                                lastName={lastName}
                                specialities={specialities}
                              />
                            </li>
                          ),
                        )}
                      </ul>
                    )}
                    {!prescriptions.length && (
                      <div className={style.notFoundBlock}>
                        <h3 className={style.notFoundBlockTitle}>No prescriptions</h3>
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.activity}>
                  <div className={style.activityHeader}>
                    <h4 className={style.activityTitle}>Appointments</h4>
                    <span className={style.activityButton} onClick={handleSeeAllAppointments}>
                      See all
                    </span>
                  </div>
                  <div className={style.activityContent}>
                    <div className={style.tabs}>
                      <Button
                        className={style.tabsButton}
                        color={activeTab === UPCOMING ? 'secondary' : 'default'}
                        variant={activeTab === UPCOMING ? 'contained' : 'text'}
                        onClick={() => handlerChangeTab(UPCOMING)}
                      >
                        {UPCOMING}
                      </Button>
                      <Button
                        className={style.tabsButton}
                        color={activeTab === PAST ? 'secondary' : 'default'}
                        variant={activeTab === PAST ? 'contained' : 'text'}
                        onClick={() => handlerChangeTab(PAST)}
                      >
                        {PAST}
                      </Button>
                    </div>
                    <div className={style.tabsContainer}>
                      {isLoadingAppointment && <Loader />}
                      {!isLoadingAppointment && appointments.length !== 0 && (
                        <ul className={style.activityList}>
                          {appointments.map((appointment) => (
                            <li key={appointment.id}>
                              <AppointmentListItem
                                type={activeTab}
                                appointment={appointment}
                                onChangeStatus={handlerChangeStatus}
                                isPreview
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                      {!isLoadingAppointment && appointments.length === 0 && (
                        <div className={style.notFoundBlock}>
                          <h3 className={style.notFoundBlockTitle}>No appointments</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.lab}>
              <LabResultsDetails patientId={patient.id} />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default React.memo(DoctorPatientProfilePage);
