/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import DateView from 'react-datepicker';
import { useField } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import style from './datepicker.module.css';

const DatePicker = ({ label, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [field, meta, helpers] = useField(rest);

  const handleChange = (value) => {
    helpers.setValue(value);
    helpers.setTouched(true);
    if (isOpen) setIsOpen(false);
  };

  return (
    <div className={style.wrapper}>
      <label className={style.label} htmlFor={rest.name}>
        {label}
      </label>
      <div className={[style.wrapperInput, meta.touched && meta.error && style.errorInput].join(' ')}>
        <DateView
          id={rest.name}
          name={field.name}
          selected={field.value}
          onChange={handleChange}
          className={style.textInput}
          dateFormat="dd/MM/yy"
          autoComplete="false"
          placeholderText="dd/mm/yy"
          open={isOpen}
          onClickOutside={() => setIsOpen(false)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          minDate={rest.minToDay ? new Date() : null}
        />
        <span className={style.icon} onClick={() => setIsOpen(!isOpen)}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M17.8333 3.66667H14.5V2H12.8333V3.66667H7.83333V2H6.16667V3.66667H2.83333C2.3731 3.66667 2 4.03976 2 4.5V17.8333C2 18.2936 2.3731 18.6667 2.83333 18.6667H17.8333C18.2936 18.6667 18.6667 18.2936 18.6667 17.8333V4.5C18.6667 4.03976 18.2936 3.66667 17.8333 3.66667ZM3.66667 10.3333H17V17H3.66667V10.3333ZM12.8333 5.33333H7.83333V7H6.16667V5.33333H3.66667V8.66667H17V5.33333H14.5V7H12.8333V5.33333ZM7 13.6667V12H5.33333V13.6667H7ZM9.5 12H11.1667V13.6667H9.5V12ZM15.3333 13.6667V12H13.6667V13.6667H15.3333Z"
              fill="#BBBCC3"
            />
          </svg>
        </span>
        {meta.touched && meta.error && <span className={[style.message, style.error].join(' ')}>{meta.error}</span>}
      </div>
    </div>
  );
};

export default React.memo(DatePicker);
