import React from 'react';
import style from './linkToProfile.module.css';
import { useHistory } from 'react-router';
import { DOCTOR_PATIENT_PROFILE, PROFILE_DOCTORS } from '../../constants/locationPath';
import { DOCTOR_ROLE } from '../../constants/userRole';

const LinkToProfile = ({ firstName, lastName, role, userId, className, fontSize }) => {
  const history = useHistory();

  const getFontSize = () => {
    switch (fontSize) {
      case 'small':
        return '14px';
      case 'medium':
        return '16px';
      case 'large':
        return '20px';
      default:
        return 'inherit';
    }
  };

  const goToProfile = () => {
    if (role === DOCTOR_ROLE) {
      history.push(`${PROFILE_DOCTORS}/${userId}`);
    } else {
      history.push(`${DOCTOR_PATIENT_PROFILE}/${userId}`);
    }
  };

  return (
    <h4
      className={[style.mainClassName, className].join(' ')}
      onClick={goToProfile}
      style={{
        fontSize: getFontSize(),
      }}
    >
      <span>{firstName}&ensp;</span>
      <span>{lastName}</span>
    </h4>
  );
};

export default LinkToProfile;
