import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import commonStyle from './../registration.module.css';
import style from './subscribe.module.css';
import { Formik } from 'formik';
import { doctorCardSchema } from './../../validationSchema';
import axios from './../../../API/configAPI';
import FormikControl from '../../formikControl';
import { Button } from '@material-ui/core';
import * as dayjs from 'dayjs';
import Loader from '../../../components/loader/loader';
import { CARD_CVV, CARD_EXPIRIES, CARD_NUMBER } from '../../../constants/formatValueInput';
import formattedCardExpiries from '../../../helpers/formattedCardExpiries';

const Subscribe = ({ nextStep, backStep }) => {
  const PLAN_PRICE = '$04.99';
  const DISCOUNTED_PLAN_PRICE = '$00.00';
  const TOTAL_DAYS_FREE = 30;
  const DAY_FINISH_FREE = dayjs().add(TOTAL_DAYS_FREE, 'days').format('DD/MM/YY');
  const [card, setCard] = useState(null);
  const [isGetMonthFree, setIsGetMonthFree] = useState(false);

  useEffect(() => {
    axios.get('card').then(({ data: card }) => {
      setCard(card);
    });
  }, []);

  const initialValues = {
    number: card?.number || '',
    holder: card?.holder || '',
    expiries: card?.expiries || '',
    cvv: card?.cvv || '',
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const requestType = card?.id ? 'put' : 'post';

    axios[requestType]('card', { ...values, expiries: formattedCardExpiries(values.expiries) })
      .then(({ data: card }) => {
        resetForm();
        setSubmitting(false);
        nextStep();
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {!card && <Loader />}
      {card && (
        <div className={[style.container, !isGetMonthFree && style.containerSmall].join(' ')}>
          <div className={commonStyle.header}>
            <h3 className={commonStyle.title}>
              {isGetMonthFree ? 'Set up your payment for later' : 'IBIS:  Get one month free!'}
            </h3>
          </div>
          <p className={style.subtitle}>
            Total due during {TOTAL_DAYS_FREE} days free trial&ensp;
            <span className={style.markAccent}>{PLAN_PRICE}</span>&ensp;
            <span className={style.markPrimary}>{DISCOUNTED_PLAN_PRICE}</span>;
          </p>
          {isGetMonthFree && (
            <p className={style.subtitle}>
              Cancel before&ensp;
              <span className={style.markPrimary}>{DAY_FINISH_FREE}</span>&ensp; to not be charged.
            </p>
          )}
          {!isGetMonthFree && (
            <Button
              className={style.button}
              variant="contained"
              color="primary"
              onClick={() => setIsGetMonthFree(true)}
            >
              Get 30 days free!
            </Button>
          )}
          {isGetMonthFree && (
            <Formik initialValues={initialValues} validationSchema={doctorCardSchema} onSubmit={onSubmit}>
              {({ handleSubmit, isSubmitting }) => (
                <form autoComplete="off" className={commonStyle.form} onSubmit={handleSubmit}>
                  <div className={style.formGrid}>
                    <FormikControl
                      control="textInput"
                      label="Card number"
                      type="text"
                      name="number"
                      placeholder="***** ***** ***** *****"
                      format={CARD_NUMBER}
                    />
                    <div className={style.formSubGrid}>
                      <FormikControl
                        control="textInput"
                        label="Expiries"
                        type="text"
                        name="expiries"
                        placeholder="mm/yy"
                        format={CARD_EXPIRIES}
                      />
                      <FormikControl
                        control="textInput"
                        label="CVV"
                        type="text"
                        name="cvv"
                        placeholder="CVV"
                        format={CARD_CVV}
                      />
                    </div>
                  </div>
                  <div className={commonStyle.actions}>
                    <Button className={commonStyle.button} variant="outlined" onClick={backStep}>
                      Back
                    </Button>
                    <Button
                      className={commonStyle.button}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Start Membership
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      )}
    </>
  );
};

Subscribe.propTypes = {
  nextStep: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
};

export default React.memo(Subscribe);
