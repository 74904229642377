import React, { useEffect } from 'react';
import style from './contactUs.module.css';
import { Formik } from 'formik';
import FormikControl from './../formikControl';
import { useHistory } from 'react-router';
import axios from './../../API/configAPI';
import { contactUsSchema } from './../validationSchema';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';

const ContactUs = () => {
  const GO_BACK = '[ContactUs] Go back';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const currentUser = useSelector((state) => state.auth.user);
  const history = useHistory();

  useEffect(() => {
    if (answerModal === GO_BACK) {
      dispatch(setModalAnswer(''));
      history.goBack();
    }
  }, [answerModal]);

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Message has been sent',
    description: 'Thank you for contacting us, we will contact you shortly',
    actions: [
      {
        title: 'go back',
        answerModal: GO_BACK,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const initialValues = {
    name: currentUser ? `${currentUser.personalData.firstName} ${currentUser.personalData.lastName}` : '',
    email: currentUser?.email || '',
    message: '',
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    axios
      .post('/mailer/contact', values)
      .then(() => {
        resetForm();
        setSubmitting(false);
        dispatch(setModalContent(contentModal));
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={contactUsSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
          <div className={style.formGrid}>
            <FormikControl control="textInput" label="Name" type="text" name="name" placeholder="Add your name" />
            <FormikControl control="textInput" label="Email" type="email" name="email" placeholder="Add your email" />
          </div>
          <FormikControl
            control="textarea"
            label="Message"
            type="textarea"
            name="message"
            placeholder="Add your message"
          />
          <div className={style.actions}>
            <Button className={style.button} variant="outlined" onClick={history.goBack}>
              Cancel
            </Button>
            <Button className={style.button} variant="contained" color="primary" type="submit" disabled={isSubmitting}>
              Send
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ContactUs;
