import React, { useEffect, useState } from 'react';
import axios from './../../API/configAPI';
import { useHistory } from 'react-router-dom';
import { DOCTOR_CREATE_APPOINTMENTS, DOCTOR_REGISTRATION, DOCTOR_MY_PATIENTS } from '../../constants/locationPath';
import Loader from '../../components/loader/loader';
import { Button, Container, MenuItem, Select } from '@material-ui/core';
import style from './doctorDashboardPage.module.css';
import ViewImage from './../../components/viewImage/viewImage';
import UpdatesFeed from './../../components/updatesFeed/updatesFeed';
import Pagination from '@material-ui/lab/Pagination';
import AppointmentListItem from '../../components/appointmentListItem/appointmentListItem';
import { setUser } from '../../store/authSlice';
import { useDispatch } from 'react-redux';
import SearchBy from './../../components/searchBy/searchBy';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Planner from './../../layouts/planner/planner';
import BootstrapInput from '../../components/bootstrapComponent/bootstrapInput';
import { REQUESTS, UPCOMING, PAST } from './../../constants/appointmentType';
import { CONFIRMED, UNCONFIRMED } from './../../constants/appointmentStatus';

// icon
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const checkNeedOfRegistrationFinish = (doctor) => {
  return !doctor?.personalData || !doctor?.doctorData || !doctor?.offices?.length;
};

const DoctorDashboardPage = () => {
  const COUNT_SHOW_ON_PAGE = 6;
  const KEY_SEARCH_NAME = 'name';
  const KEY_SEARCH_DATE = 'date';
  const sortingAppointments = [
    { displayName: 'Name', value: 'name' },
    { displayName: 'Date', value: 'date' },
    { displayName: 'Duration', value: 'duration' },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:576px)');

  const [loading, setLoading] = useState(false);
  const [doctor, setDoctor] = useState(null);
  const [myPatients, setMyPatients] = useState(null);
  const [activeTab, setActiveTab] = useState(REQUESTS);
  const [page, setPage] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const [totalPagesAppointments, setTotalPagesAppointments] = useState(0);
  const [activeSorting, setActiveSorting] = useState(sortingAppointments[1].value);
  const [direction, setDirection] = useState(true);
  const [typeAppointments, setTypeAppointments] = useState(REQUESTS);

  useEffect(() => {
    const requestsUser = axios.get('auth/me');
    const requestsCard = axios.get('card');
    const requestsMyPatients = axios.get('user/search/patients');

    Promise.all([requestsUser, requestsCard, requestsMyPatients])
      .then(([{ data: doctor }, { data: card }, { data: myPatients }]) => {
        setDoctor(doctor);
        dispatch(setUser(doctor));
        if (checkNeedOfRegistrationFinish(doctor) || !card?.number) {
          history.push(DOCTOR_REGISTRATION);
        }
        setMyPatients(myPatients.total);
      })
      .catch((error) => {});
  }, []);

  const getAppointment = (value, searchBy) => {
    setLoading(true);
    setAppointments([]);
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    const range = activeTab !== REQUESTS ? activeTab : 'all';
    const status = activeTab !== REQUESTS ? CONFIRMED : UNCONFIRMED;
    const name = searchBy === KEY_SEARCH_NAME ? value : '';
    const date = searchBy === KEY_SEARCH_DATE ? value : '';
    const order = direction ? 'ASC' : 'DESC';
    axios
      .get(
        `appointment/search?take=${COUNT_SHOW_ON_PAGE}&skip=${skip}&range=${range}&status=${status}&name=${name}&date=${date}&sortField=${activeSorting}&order=${order}`,
      )
      .then(({ data: { results, total } }) => {
        setAppointments(results);
        setTypeAppointments(activeTab);
        if (total <= COUNT_SHOW_ON_PAGE) {
          setTotalPagesAppointments(0);
        } else {
          setTotalPagesAppointments(Math.ceil(total / COUNT_SHOW_ON_PAGE));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAppointment();
  }, [page, activeTab, activeSorting, direction]);

  const handlePatientsCount = () => {
    history.push(DOCTOR_MY_PATIENTS);
  };

  const handleSearch = (value = '', searchBy = '') => {
    getAppointment(value, searchBy);
  };

  const handleNewAppointment = () => {
    history.push(DOCTOR_CREATE_APPOINTMENTS);
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const handlerChangeTab = (tab) => {
    setPage(1);
    setActiveTab(tab);
  };

  const handlerSorting = (value) => {
    if (value === activeSorting) {
      setDirection(!direction);
    } else {
      setActiveSorting(value);
    }
  };

  const handlerChangeStatus = () => {
    getAppointment();
  };

  return (
    <Container className={style.container}>
      {!doctor && <Loader />}
      {doctor && (
        <div className={style.grid}>
          <Planner doctor={doctor} />
          <div className={style.doctor}>
            <div className={style.doctorInfo}>
              <div className={style.doctorPersonalData}>
                <ViewImage imageId={doctor?.personalData?.avatarId} size="142px" />
                <div className={style.doctorPersonalGrid}>
                  <h4 className={style.doctorName}>
                    Dr.&ensp;
                    <span>{doctor?.personalData?.firstName}</span>
                    &ensp;
                    <span>{doctor?.personalData?.lastName}</span>
                  </h4>
                  <div className={style.doctorSpecialties}>
                    {doctor?.doctorData?.specialities?.map(({ id, speciality }) => (
                      <span key={id} className={style.doctorSpecialty}>
                        {speciality.name}
                      </span>
                    ))}
                  </div>
                </div>
                <div className={style.doctorPatients}>
                  <PeopleOutlineIcon fontSize="small" />
                  <span>My Patients</span>
                  <Button
                    className={style.doctorPatientsCount}
                    variant="contained"
                    color="primary"
                    onClick={handlePatientsCount}
                  >
                    {myPatients}
                  </Button>
                </div>
              </div>
              {/* <UpdatesFeed /> */}
            </div>
            <div className={style.pageWrapper}>
              <h2 className={style.pageTitle}>Appointments</h2>
              <div className={style.actions}>
                <SearchBy handleSearch={handleSearch} searchByValues={[KEY_SEARCH_NAME, KEY_SEARCH_DATE]} />
                <Button className={style.addButton} variant="contained" color="primary" onClick={handleNewAppointment}>
                  + New appointment
                </Button>
              </div>
              <div className={style.appointments}>
                <div className={style.appointmentsTabs}>
                  <Button
                    className={style.appointmentsTabsButton}
                    color={activeTab === REQUESTS ? 'secondary' : 'default'}
                    variant={activeTab === REQUESTS ? 'contained' : 'text'}
                    onClick={() => handlerChangeTab(REQUESTS)}
                  >
                    {REQUESTS}
                  </Button>
                  <Button
                    className={style.appointmentsTabsButton}
                    color={activeTab === UPCOMING ? 'secondary' : 'default'}
                    variant={activeTab === UPCOMING ? 'contained' : 'text'}
                    onClick={() => handlerChangeTab(UPCOMING)}
                  >
                    {UPCOMING}
                  </Button>
                  <Button
                    className={style.appointmentsTabsButton}
                    color={activeTab === PAST ? 'secondary' : 'default'}
                    variant={activeTab === PAST ? 'contained' : 'text'}
                    onClick={() => handlerChangeTab(PAST)}
                  >
                    {PAST}
                  </Button>
                </div>
                <div className={style.sorting}>
                  {sortingAppointments.map(({ displayName, value }, index) => (
                    <span key={index} className={style.sortingBy} onClick={() => handlerSorting(value)}>
                      {displayName}
                      <UnfoldMoreIcon className={style.sortingIcon} />
                    </span>
                  ))}
                </div>
                <div className={style.sortingMobile}>
                  <span className={style.sortingLabel}>Sort by</span>
                  <Select
                    fullWidth
                    className={style.selectList}
                    value={activeSorting}
                    onChange={({ target: { value } }) => handlerSorting(value)}
                    variant="outlined"
                    color="secondary"
                    input={<BootstrapInput />}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {sortingAppointments.map(({ displayName, value }, index) => (
                      <MenuItem key={index} value={value} className={style.selectListItem}>
                        {displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={style.tabsContainer}>
                  {loading && <Loader />}
                  {!loading && appointments.length !== 0 && (
                    <ul className={style.appointmentsList}>
                      {appointments.map((appointment) => (
                        <li key={appointment.id}>
                          <AppointmentListItem
                            type={typeAppointments}
                            appointment={appointment}
                            onChangeStatus={handlerChangeStatus}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {totalPagesAppointments !== 0 && (
                  <div className={style.paginationWrapper}>
                    <Pagination
                      siblingCount={Number(!matches)}
                      count={totalPagesAppointments}
                      color="secondary"
                      onChange={handleChangePagination}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default React.memo(DoctorDashboardPage);
