/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import commonStyle from './../registration.module.css';
import style from './personalData.module.css';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import * as dayjs from 'dayjs';
import FormikControl from './../../formikControl';
import axios from './../../../API/configAPI';
import { doctorPersonalDataSchema } from './../../validationSchema';
import Loader from '../../../components/loader/loader';
import getFormattedPhotoId from '../../../helpers/getFormattedPhotoId';
import getFormattedDate from '../../../helpers/getFormattedDate';
import { FORMAT_PHONE } from '../../../constants/formatValueInput';
import formattedPhoneNumber from '../../../helpers/formattedPhoneNumber';

const PersonalData = ({ nextStep, viewSetting }) => {
  const options = [
    { key: 'Male', value: 'male' },
    { key: 'Female', value: 'female' },
  ];

  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get('auth/me').then(({ data: user }) => {
      setUser(user);
    });
  }, []);

  const initialValues = {
    firstName: user?.personalData?.firstName || '',
    middleName: user?.personalData?.middleName || '',
    lastName: user?.personalData?.lastName || '',
    avatar: getFormattedPhotoId(user?.personalData?.avatarId) || null,
    gender: user?.personalData?.gender || '',
    birth: getFormattedDate(user?.personalData?.birth) || null,
    country: 'Trinidad and Tobago',
    city: user?.personalData?.city || '',
    state: user?.personalData?.state || '',
    zipCode: user?.personalData?.zipCode || '',
    address: user?.personalData?.address || '',
    phone: user?.personalData?.phone || '',
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formattedDate = dayjs(values.birth.toISOString()).format('YYYY-MM-DD');
    const formattedPhone = formattedPhoneNumber(values.phone);

    const requestType = user.personalData?.id ? 'put' : 'post';

    axios[requestType]('personal-data', {
      ...values,
      birth: formattedDate,
      phone: formattedPhone,
      country: '',
    })
      .then(() => {
        resetForm();
        setSubmitting(false);
        nextStep();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {!user && <Loader />}
      {user && (
        <div className={commonStyle.container}>
          <div className={commonStyle.header}>
            <h3 className={commonStyle.title}>Personal data</h3>
          </div>
          <Formik initialValues={initialValues} validationSchema={doctorPersonalDataSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <form autoComplete="off" className={commonStyle.form} onSubmit={handleSubmit}>
                <div className={style.formGridUp}>
                  <FormikControl control="imageUpload" name="avatar" />
                  <div className={style.gridLine}>
                    <FormikControl
                      control="textInput"
                      label="First name"
                      type="text"
                      name="firstName"
                      placeholder="First name"
                    />
                    <FormikControl
                      control="textInput"
                      label="Middle name"
                      type="text"
                      name="middleName"
                      placeholder="Middle name"
                    />
                    <FormikControl
                      control="textInput"
                      label="Last name"
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                    />
                    <FormikControl control="datepicker" label="Date of birth" name="birth" />
                    <div className={style.gridArea}>
                      <FormikControl control="radioButtonGroup" label="Gender" name="gender" options={options} />
                    </div>
                  </div>
                </div>

                <div className={style.gridLine}>
                  <FormikControl
                    control="textInput"
                    label="Country"
                    type="text"
                    name="country"
                    placeholder=""
                    disabled={true}
                  />
                  <FormikControl control="textInput" label="City" type="text" name="city" placeholder="City" />
                  <FormikControl control="textInput" label="State" type="text" name="state" placeholder="State" />
                  <FormikControl
                    control="textInput"
                    label="Zip code"
                    type="text"
                    name="zipCode"
                    placeholder="Zip code"
                  />
                  <FormikControl control="textInput" label="Address" type="text" name="address" placeholder="Address" />
                  <FormikControl
                    control="textInput"
                    label="Phone Number"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    format={FORMAT_PHONE}
                  />
                </div>
                <div className={[commonStyle.actions, viewSetting && commonStyle.actionsSettings].join(' ')}>
                  {!viewSetting && (
                    <Button disabled={true} className={commonStyle.button} variant="outlined">
                      Back
                    </Button>
                  )}
                  <Button
                    className={commonStyle.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {viewSetting ? 'Save' : 'Next Step'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

PersonalData.defaultProps = {
  viewSetting: false,
};

PersonalData.propTypes = {
  nextStep: PropTypes.func.isRequired,
  viewSetting: PropTypes.bool,
};

export default React.memo(PersonalData);
