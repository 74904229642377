import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Single } from '../../layouts/single/single';
import axios from '../../API/configAPI';
import { useDispatch, useSelector } from 'react-redux';
import { DOCTOR_REGISTRATION, PATIENT_REGISTRATION } from '../../constants/locationPath';
import { setModalContent, setModalAnswer } from '../../store/modalSlice';
import { useAuth } from './../../hooks/authHook';
import { DOCTOR_ROLE, PATIENT_ROLE } from './../../constants/userRole';

export const ConfirmEmailPage = () => {
  const REDIRECT_TO_REGISTRATIONS = '[ConfirmEmailPage] Redirect to registrations';
  const answerModal = useSelector((state) => state.modal.answer);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { login } = useAuth();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (answerModal === REDIRECT_TO_REGISTRATIONS) {
      dispatch(setModalAnswer(''));
      login(userData.accessToken, userData.user);
      switch (userData.user.role) {
        case DOCTOR_ROLE:
          history.push(DOCTOR_REGISTRATION);
          break;
        case PATIENT_ROLE:
          history.push(PATIENT_REGISTRATION);
          break;
        default:
          break;
      }
    }
  }, [answerModal]);

  useEffect(() => {
    checkId(id);
  }, []);

  const contentModal = {
    isClosedByOverlay: false,
    title: 'Congratulations',
    description: `
      You have successfully verified your email. 
      Press the button to continue registration.
    `,
    actions: [
      {
        title: 'Continue',
        answerModal: REDIRECT_TO_REGISTRATIONS,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const checkId = (id) => {
    axios.get(`auth/email-verification/${id}`).then(({ data }) => {
      setUserData(data);
      dispatch(setModalContent(contentModal));
    });
  };

  return <Single />;
};
