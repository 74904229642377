import React, { useState, useEffect } from 'react';
import style from './patientMyDoctorsPage.module.css';
import { Button, Container } from '@material-ui/core';
import Loader from '../../components/loader/loader';
import { useHistory } from 'react-router';
import { PATIENT_REQUEST, PROFILE_DOCTORS } from '../../constants/locationPath';
import Pagination from '@material-ui/lab/Pagination';
import ViewImage from '../../components/viewImage/viewImage';
import SearchBy from '../../components/searchBy/searchBy';
import axios from '../../API/configAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LinkToProfile from '../../components/linkToProfile/linkToProfile';
import { DOCTOR_ROLE } from '../../constants/userRole';
import { useSelector } from 'react-redux';

const PatientMyDoctorsPage = () => {
  const COUNT_SHOW_ON_PAGE = 8;
  const history = useHistory();
  const patient = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const matches = useMediaQuery('(max-width:576px)');
  const NAME = 'name';
  const SPECIALITY = 'speciality';

  useEffect(() => {
    getDoctors();
  }, [page]);

  const getDoctors = (searchValue, searchBy) => {
    setIsLoading(true);
    setDoctorList([]);
    const name = searchBy === NAME ? searchValue : '';
    const speciality = searchBy === SPECIALITY ? searchValue : '';
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    axios
      .get(
        `user/search/doctors?patientId=${patient?.id}&take=${COUNT_SHOW_ON_PAGE}&skip=${skip}&name=${name}&speciality=${speciality}`,
      )
      .then(({ data: { results, total } }) => {
        setDoctorList(results);
        if (total <= COUNT_SHOW_ON_PAGE) {
          setTotalPages(0);
        } else {
          setTotalPages(Math.ceil(total / COUNT_SHOW_ON_PAGE));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleSearch = (value, searchBy) => {
    getDoctors(value, searchBy);
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const handlerViewProfile = (id) => {
    history.push(`${PROFILE_DOCTORS}/${id}`);
  };

  const handlerAppointMeeting = (id) => {
    history.push(`${PATIENT_REQUEST}/${id}`);
  };

  const handlerGetAll = () => {
    getDoctors();
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <Container>
          <h3 className={style.title}>My doctors</h3>
          <div className={style.actions}>
            <SearchBy handleSearch={handleSearch} searchByValues={[NAME, SPECIALITY]} />
          </div>
        </Container>
      </div>
      <div className={style.content}>
        <Container className={style.container}>
          {isLoading && <Loader />}
          {!isLoading && doctorList.length !== 0 && (
            <ul className={style.patientList}>
              {doctorList.map(({ id, firstName, lastName, avatarId, specialities }) => (
                <li key={id} className={style.patientListItem}>
                  <ViewImage imageId={avatarId} size="51px" borderRadius="8px" />
                  <div className={style.patientInfo}>
                    <LinkToProfile
                      firstName={firstName}
                      lastName={lastName}
                      role={DOCTOR_ROLE}
                      userId={id}
                      fontSize="large"
                    />
                    <span className={style.patientYearsOld}>{specialities}</span>
                  </div>
                  <div className={style.patientActions}>
                    <Button
                      className={style.button}
                      variant="contained"
                      color="primary"
                      onClick={() => handlerAppointMeeting(id)}
                    >
                      Set Appointment
                    </Button>
                    <Button className={style.button} variant="outlined" onClick={() => handlerViewProfile(id)}>
                      View profile
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!isLoading && doctorList.length === 0 && (
            <div className={style.notFoundBlock}>
              <h3 className={style.notFoundBlockTitle}>Not found</h3>
              <Button className={style.button} variant="contained" color="primary" onClick={handlerGetAll}>
                Get all
              </Button>
            </div>
          )}
          {totalPages !== 0 && (
            <div className={style.patientsPaginationWrapper}>
              <Pagination
                siblingCount={Number(!matches)}
                count={totalPages}
                color="secondary"
                onChange={handleChangePagination}
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default React.memo(PatientMyDoctorsPage);
