import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './addLabValue.module.css';
import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import FormikControl from './../formikControl';
import { createLabValueSchema } from './../validationSchema';
import axios from './../../API/configAPI';
import dayjs from './../../helpers/getDayJsWithTimezone';
import CreateLabCategory from './../createLabCategory/createLabCategory';
import CategorySearch from './../../components/categorySearch/categorySearch';
import getFormattedDate from './../../helpers/getFormattedDate';
import Loader from '../../components/loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';

// icons
import AddIcon from '@material-ui/icons/Add';

const AddLabValue = ({ labValueId, labResultId, handleSave }) => {
  const DELETE = '[AddLabValue] Delete lab value';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [category, setCategory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [labValue, setLabValue] = useState(null);

  const options = [
    { displayName: 'H', value: 'high' },
    { displayName: 'N', value: 'normal' },
    { displayName: 'L', value: 'low' },
  ];

  const optionsUnit = [
    { displayName: '%', value: 'percentage' },
    { displayName: '0.00', value: 'absolute' },
  ];

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Delete lab value',
    description: `
      Do you want to delete lab value?
    `,
    actions: [
      {
        title: 'Cancel',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Ok',
        answerModal: DELETE,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  useEffect(() => {
    if (labValueId) {
      getLabValue(labValueId);
    }
  }, [labValueId]);

  const getLabValue = (id) => {
    setIsLoading(true);
    axios
      .get(`result-value/${id}`)
      .then(({ data: labValue }) => {
        setLabValue(labValue);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (answerModal === DELETE) {
      dispatch(setModalAnswer(''));
      setIsLoading(true);
      axios
        .delete(`result-value/${labValue.id}`)
        .then(({ data }) => {
          setCategory(null);
          setLabValue(null);
          setIsLoading(false);
          handleSave();
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [answerModal]);

  const initialValues = {
    title: labValue?.category?.title || '',
    creationDate: labValue?.creationDate ? getFormattedDate(labValue.creationDate) : new Date(),
    value: labValue?.value || '',
    unit: labValue?.category?.unit || optionsUnit[0].value,
    flag: labValue?.flag || options[1].value,
    reference: labValue?.reference || '',
    description: labValue?.description || '',
    labResultId,
  };

  const resetValues = {
    values: {
      title: '',
      creationDate: new Date(),
      value: '',
      unit: optionsUnit[0].value,
      flag: options[1].value,
      reference: '',
      description: '',
      labResultId,
    },
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formattedValue = {
      category: {
        id: labValue?.category?.id || category?.id,
        title: labValue?.category?.title || category?.title,
        description: labValue?.category?.description || category?.description,
        unit: labValue?.category?.util || category?.unit,
      },
      creationDate: dayjs(values?.creationDate).format('YYYY-MM-DD'),
      value: Number(values?.value),
      flag: values?.flag,
      reference: values?.reference,
      description: values?.description,
      labResultId,
    };

    const requestType = labValue?.id ? 'patch' : 'post';
    const requestUrl = labValue?.id ? `result-value/${labValue?.id}` : 'result-value';
    axios[requestType](requestUrl, formattedValue)
      .then(({ data }) => {
        resetForm(resetValues);
        setCategory(null);
        setLabValue(null);
        setSubmitting(false);
        handleSave();
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const handlerOpenDialogForm = () => {
    setIsOpenDialog(true);
  };

  const handleClose = () => {
    setIsOpenDialog(false);
  };

  const handleSaveCategory = (category) => {
    setIsOpenDialog(false);
  };

  const handlerDelete = () => {
    dispatch(setModalContent(contentModal));
  };

  const selectCategory = (category, optionsForm) => {
    setCategory(category);
    const helperUnit = optionsForm.getFieldHelpers('unit');
    const helperDescription = optionsForm.getFieldHelpers('reference');
    helperUnit.setValue(category.unit);
    helperDescription.setValue(category.description);
  };

  const handlerReset = (optionsForm) => {
    setCategory(null);
    setLabValue(null);
    optionsForm.resetForm(resetValues);
  };

  return (
    <div className={style.wrapper}>
      {isLoading && <Loader />}
      {!isLoading && (
        <Formik initialValues={initialValues} validationSchema={createLabValueSchema} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting, ...optionsForm }) => (
            <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
              <div className={style.formGrid}>
                <div className={style.categoryWrapper}>
                  <CategorySearch
                    disabled={!!labValue?.category?.id}
                    nameField="title"
                    selectCategory={(category) => {
                      selectCategory(category, optionsForm);
                    }}
                  />
                  <Button
                    size="small"
                    className={style.button}
                    variant="contained"
                    color="secondary"
                    onClick={handlerOpenDialogForm}
                  >
                    <AddIcon fontSize="inherit" />
                  </Button>
                </div>
                <FormikControl control="textInput" label="Value" type="text" name="value" placeholder="00.00" />
                <FormikControl control="selectInput" label="Unit" name="unit" options={optionsUnit} disabled />
                <FormikControl control="selectInput" label="Flag" name="flag" options={options} />
                <FormikControl
                  control="textarea"
                  label="Standard range"
                  type="text"
                  name="reference"
                  placeholder="Add range"
                />
                <FormikControl
                  control="textarea"
                  label="Comment"
                  type="text"
                  name="description"
                  placeholder="Add comment"
                />
                <FormikControl control="datepicker" label="Date of analysis" name="creationDate" />
              </div>
              <div className={style.actions}>
                <Button
                  className={style.button}
                  variant="outlined"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => {
                    handlerReset(optionsForm);
                  }}
                >
                  Reset
                </Button>
                <Button
                  className={style.button}
                  variant="contained"
                  color="secondary"
                  disabled={!labValue?.id}
                  onClick={handlerDelete}
                >
                  Delete
                </Button>
                <Button
                  className={style.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {labValue?.category?.id ? 'Edit value' : 'Add value'}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
      <CreateLabCategory isOpen={isOpenDialog} handleClose={handleClose} handleSave={handleSaveCategory} />
    </div>
  );
};

AddLabValue.defaultProps = {
  labValueId: '',
};

AddLabValue.propTypes = {
  labValueId: PropTypes.string,
  labResultId: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default React.memo(AddLabValue);
