import React, { useState, useEffect } from 'react';
import style from './doctorMyPatientsPage.module.css';
import { Button, Container } from '@material-ui/core';
import Loader from '../../components/loader/loader';
import { useHistory } from 'react-router';
import {
  DOCTOR_CREATE_APPOINTMENTS,
  DOCTOR_CREATE_INVOICE,
  DOCTOR_CREATE_PATIENT,
  DOCTOR_PATIENT_PROFILE,
} from '../../constants/locationPath';
import Pagination from '@material-ui/lab/Pagination';
import ViewImage from './../../components/viewImage/viewImage';
import SearchBy from './../../components/searchBy/searchBy';
import axios from './../../API/configAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getYearsToNow } from './../../helpers/getYearsToNow';
import { PATIENT_ROLE } from '../../constants/userRole';
import LinkToProfile from '../../components/linkToProfile/linkToProfile';

const DoctorMyPatientsPage = () => {
  const COUNT_SHOW_ON_PAGE = 8;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const matches = useMediaQuery('(max-width:576px)');

  useEffect(() => {
    getPatient();
  }, [page]);

  const getPatient = (searchValue) => {
    setIsLoading(true);
    setPatientList([]);
    const query = searchValue || '';
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    axios
      .get(`user/search/patients?take=${COUNT_SHOW_ON_PAGE}&skip=${skip}&query=${query}`)
      .then(({ data: { results, total } }) => {
        setPatientList(results);
        if (total <= COUNT_SHOW_ON_PAGE) {
          setTotalPages(0);
        } else {
          setTotalPages(Math.ceil(total / COUNT_SHOW_ON_PAGE));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleAddPatient = () => {
    history.push(DOCTOR_CREATE_PATIENT);
  };

  const handleSearch = (value, searchBy) => {
    getPatient(value);
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const handlerViewProfile = (id) => {
    history.push(`${DOCTOR_PATIENT_PROFILE}/${id}`);
  };

  const handlerAppointMeeting = (id) => {
    history.push(DOCTOR_CREATE_APPOINTMENTS, { patientId: id });
  };

  const handlerSendInvoice = (id) => {
    history.push(`${DOCTOR_CREATE_INVOICE}`, { patientId: id });
  };

  const handlerGetAll = () => {
    getPatient();
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <Container>
          <h3 className={style.title}>My patients</h3>
          <div className={style.actions}>
            <SearchBy handleSearch={handleSearch} />
            <Button className={style.addButton} variant="contained" color="primary" onClick={handleAddPatient}>
              + Add Patient
            </Button>
          </div>
        </Container>
      </div>
      <div className={style.content}>
        <Container className={style.container}>
          {isLoading && <Loader />}
          {!isLoading && patientList.length !== 0 && (
            <ul className={style.patientList}>
              {patientList.map(({ id, firstName, lastName, avatarId, birth }) => (
                <li key={id} className={style.patientListItem}>
                  <ViewImage imageId={avatarId} size="51px" borderRadius="8px" />
                  <div className={style.patientInfo}>
                    <LinkToProfile
                      firstName={firstName}
                      lastName={lastName}
                      role={PATIENT_ROLE}
                      userId={id}
                      fontSize="large"
                    />
                    <span className={style.patientYearsOld}>{getYearsToNow(birth, true)}</span>
                  </div>
                  <div className={style.patientActions}>
                    <Button
                      className={style.button}
                      variant="outlined"
                      color="primary"
                      onClick={() => handlerSendInvoice(id)}
                    >
                      Send invoice
                    </Button>
                    <Button
                      className={style.button}
                      variant="contained"
                      color="primary"
                      onClick={() => handlerAppointMeeting(id)}
                    >
                      Set Appointment
                    </Button>
                    <Button className={style.button} variant="outlined" onClick={() => handlerViewProfile(id)}>
                      View profile
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!isLoading && patientList.length === 0 && (
            <div className={style.notFoundBlock}>
              <h3 className={style.notFoundBlockTitle}>Not found</h3>
              <Button className={style.button} variant="contained" color="primary" onClick={handlerGetAll}>
                Get all
              </Button>
            </div>
          )}
          {totalPages !== 0 && (
            <div className={style.patientsPaginationWrapper}>
              <Pagination
                siblingCount={Number(!matches)}
                count={totalPages}
                color="secondary"
                onChange={handleChangePagination}
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default React.memo(DoctorMyPatientsPage);
