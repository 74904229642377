import React, { useState, useEffect } from 'react';
import './originBigPlanner.css';
import './customBigPlanner.css';
import axios from '../../API/configAPI';
import Loader from '../../components/loader/loader';
import convertTZ from './../../helpers/convertTZ';
import BigPlannerToolbar from './bigPlannerToolbar/bigPlannerToolbar';
import { useHistory } from 'react-router';
import { DOCTOR_CREATE_APPOINTMENTS } from './../../constants/locationPath';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment);

let components = {
  toolbar: BigPlannerToolbar,
};

const BigPlannerPage = () => {
  const [appointments, setAppointments] = useState(null);
  const history = useHistory();

  useEffect(() => {
    axios.get(`appointment/search?range=all&status=confirmed`).then(({ data: { results } }) => {
      const formatted = results.map((event) => {
        return {
          ...event,
          start: convertTZ(event.start),
          end: convertTZ(event.end),
          title: event.fullName,
        };
      });
      setAppointments(formatted);
    });
  }, []);

  const handlerSelectEvent = (event) => {
    history.push(DOCTOR_CREATE_APPOINTMENTS, { patientId: event.userId, appointmentId: event.id });
  };

  return (
    <>
      {!appointments && <Loader />}
      {appointments && (
        <Calendar
          localizer={localizer}
          events={appointments}
          getNow={() => convertTZ(new Date())}
          step={30}
          timeslots={1}
          components={components}
          eventPropGetter={(event, start, end, isSelected) => {
            return { className: `${isSelected ? 'custom-event-selected' : ''} custom-event` };
          }}
          slotPropGetter={() => {
            return {
              className: 'custom-slot',
            };
          }}
          dayPropGetter={() => {
            return {
              className: 'custom-day',
            };
          }}
          onSelectEvent={handlerSelectEvent}
        />
      )}
    </>
  );
};

export default BigPlannerPage;
