const downloadFile = (fileInstants, fileName) => {
  const link = document.createElement('a');
  link.href = fileInstants;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export default downloadFile;
