import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #edeef0',
    fontSize: 16,
    padding: '14px 36px 15px 16px',
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    '&:focus': {
      borderRadius: 8,
      borderColor: '#1b2a4a',
    },
  },
}))(InputBase);

export default BootstrapInput;
