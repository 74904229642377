import React from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import MicIcon from '../../../../icons/micIcon';
import MicOffIcon from '../../../../icons/micOffIcon';

import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

  return (
    <ButtonBase
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
    >
      {isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
    </ButtonBase>
  );
}
