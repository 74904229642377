import React, { useEffect, useState } from 'react';
import style from './doctorCreateInvoicePage.module.css';
import { Container, Button, useMediaQuery } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import axios from '../../API/configAPI';
import { Formik, FieldArray } from 'formik';
import FormikControl from './../../forms/formikControl';
import { doctorCreateInvoiceSchema } from './../../forms/validationSchema';
import dayjs from 'dayjs';
import Loader from './../../components/loader/loader';
import getFormattedDate from './../../helpers/getFormattedDate';
import PatientSearch from '../../components/patientSearch/patientSearch';
import { FORMAT_PHONE, PREFIX } from '../../constants/formatValueInput';
import NumberFormat from 'react-number-format';

const DoctorCreateInvoicePage = () => {
  const history = useHistory();
  const doctor = useSelector((state) => state.auth.user);
  const { state: details } = useLocation();
  const isEdit = Boolean(details);
  const [totalValue, setTotalValue] = useState(details?.total || 0);
  const [servicesValue, setServicesValue] = useState(details?.services || []);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [loading, setLoading] = useState(false);

  const matches = useMediaQuery('(max-width:992px)');

  useEffect(() => {
    setLoading(true);
    axios
      .get('invoice/next-number')
      .then(({ data: num }) => {
        setInvoiceNumber(num);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const counter = servicesValue.reduce((prev, next) => {
      return prev + (Number(next?.subtotal) || 0);
    }, 0);

    setTotalValue(counter);
  }, [servicesValue]);

  const options = [
    { displayName: 'Flat-rate', value: 'flat-rate' },
    { displayName: 'Hourly', value: 'hourly' },
  ];

  const initialValuesService = {
    description: '',
    billingType: options[0].value,
    quantity: '',
    amount: '',
  };

  const getInitValues = () => {
    if (!servicesValue?.length) return [initialValuesService];
    return servicesValue.map((service) => {
      return {
        description: service?.description || '',
        billingType: service?.billingType || options[0].value,
        quantity: service?.quantity || '',
        amount: service?.amount || '',
      };
    });
  };

  const initialValues = {
    number: details?.number || invoiceNumber || '',
    invoiceDate: details?.invoiceDate ? getFormattedDate(details?.invoiceDate) : new Date(),
    dueDate: details?.dueDate ? getFormattedDate(details?.dueDate) : new Date(),
    patientId: '',
    patientEmail: '',
    services: getInitValues(),

    doctorName: `${doctor?.personalData?.firstName} ${doctor?.personalData?.lastName}` || '',
    officeName: doctor?.offices[0]?.name || '',
    address: doctor?.offices[0]?.address || '',
    country: 'Trinidad and Tobago',
    city: doctor?.offices[0]?.city || '',
    state: doctor?.offices[0]?.state || '',
    zipCode: doctor?.offices[0]?.zipCode || '',
    doctorPhone: doctor?.offices[0]?.phone || '',
    doctorEmail: doctor?.offices[0]?.email || '',
  };

  const onSubmit = (values, { setSubmitting }) => {
    const { number, invoiceDate, dueDate, patientId, services } = values;
    const formattedInvoiceDate = dayjs(invoiceDate.toISOString()).format('YYYY-MM-DD');
    const formattedDueDate = dayjs(dueDate.toISOString()).format('YYYY-MM-DD');

    const requestType = details?.id ? 'patch' : 'post';
    const requestUrl = details?.id ? `invoice/${details?.id}` : `invoice`;

    const formattedValues = {
      number,
      invoiceDate: formattedInvoiceDate,
      dueDate: formattedDueDate,
      patientId,
      total: Number(totalValue),
      services: services.map((service, index) => {
        return {
          ...service,
          quantity: Number(servicesValue[index].quantity),
          amount: Number(servicesValue[index].amount),
          subtotal: Number(servicesValue[index].subtotal),
        };
      }),
    };

    axios[requestType](requestUrl, formattedValues)
      .then(() => {
        setSubmitting(false);
        handlerGoBack();
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const handlerGoBack = () => {
    history.goBack();
  };

  const handlerAddService = (arrayHelpers) => {
    arrayHelpers.push(initialValuesService);
  };

  const handlerRemoveService = (arrayHelpers, index) => {
    if (typeof index !== 'number') return;

    const copy = [...servicesValue];
    arrayHelpers.remove(index);
    copy.splice(index, 1);
    setServicesValue(copy);
  };

  const handlerRemoveAll = (arrayHelpers, lengthServicesArray) => {
    const copy = [...servicesValue];

    while (lengthServicesArray) {
      arrayHelpers.remove(lengthServicesArray - 1);
      copy.pop();
      lengthServicesArray -= 1;
    }

    setServicesValue(copy);
    arrayHelpers.push(initialValuesService);
  };

  const handlerChangeServices = (value, nameField, index) => {
    const newServicesValue = {
      ...servicesValue[index],
      quantity: servicesValue[index]?.quantity || '',
      amount: servicesValue[index]?.amount || '',
      subtotal: 0,
    };

    newServicesValue[nameField] = value;

    newServicesValue.subtotal = (Number(newServicesValue?.quantity) || 0) * (Number(newServicesValue?.amount) || 0);
    const copy = [...servicesValue];
    copy.splice(index, 1, newServicesValue);
    setServicesValue(copy);
  };

  return (
    <Container>
      <div className={style.wrapper}>
        <h1 className={style.title}>Create Invoice</h1>
        {loading && <Loader />}
        {!loading && doctor && (
          <Formik initialValues={initialValues} validationSchema={doctorCreateInvoiceSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, values }) => (
              <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
                <div className={style.gridMain}>
                  <FormikControl control="textInput" label="Invoice number" type="text" name="number" />
                  <div className={style.subGrid}>
                    <FormikControl control="datepicker" label="Invoice date" name="invoiceDate" />
                    <FormikControl control="datepicker" label="Due date" name="dueDate" />
                  </div>
                </div>
                <h4 className={style.subtitle}>Doctor's info</h4>
                <div className={style.grid}>
                  <FormikControl
                    control="textInput"
                    label="Doctor's name"
                    type="text"
                    name="doctorName"
                    disabled={true}
                  />
                  <FormikControl
                    control="textInput"
                    label="Office name"
                    type="text"
                    name="officeName"
                    disabled={true}
                  />
                  <FormikControl control="textInput" label="Address" type="text" name="address" disabled={true} />
                  <FormikControl control="textInput" label="City" type="text" name="city" disabled={true} />
                  <FormikControl control="textInput" label="State" type="text" name="state" disabled={true} />
                  <FormikControl control="textInput" label="Zip code" type="text" name="zipCode" disabled={true} />
                  <FormikControl
                    control="textInput"
                    label="Phone number"
                    type="text"
                    name="doctorPhone"
                    format={FORMAT_PHONE}
                    disabled={true}
                  />
                  <FormikControl
                    control="textInput"
                    label="Business email"
                    type="text"
                    name="doctorEmail"
                    disabled={true}
                  />
                </div>
                <h4 className={style.subtitle}>Patient information</h4>
                <div className={style.wrapperPatient}>
                  <PatientSearch disabled={isEdit} namePrimaryField="patientId" nameSecondaryField="patientEmail" />
                </div>
                <h4 className={style.subtitle}>Services</h4>
                <div>
                  {!matches && (
                    <div className={style.gridServices}>
                      <span>Descriptions</span>
                      <span>Billing type</span>
                      <span>Quantity</span>
                      <span>Amount</span>
                      <span>Subtotal</span>
                    </div>
                  )}
                  <div className={style.servicesWrapper}>
                    <FieldArray name="services">
                      {(arrayHelpers) => (
                        <div className={style.gridServicesWrapper}>
                          {values.services.map((service, index) => (
                            <div key={index} className={style.gridServices}>
                              <div className={style.descriptionWrapper}>
                                <FormikControl
                                  control="textInput"
                                  type="text"
                                  name={`services.${index}.description`}
                                  placeholder="Description"
                                  label={matches ? 'Description' : ''}
                                  styleInput={{
                                    paddingLeft: '68px',
                                  }}
                                />
                                <Button
                                  size="small"
                                  className={style.buttonRemoveService}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handlerRemoveService(arrayHelpers, index)}
                                  disabled={!(values.services.length > 1)}
                                >
                                  -
                                </Button>
                              </div>
                              <FormikControl
                                control="selectInput"
                                options={options}
                                name={`services.${index}.billingType`}
                                placeholder="BillingType"
                                label={matches ? 'BillingType' : ''}
                              />
                              <FormikControl
                                control="textInput"
                                type="text"
                                name={`services.${index}.quantity`}
                                placeholder="Quantity"
                                label={matches ? 'Quantity' : ''}
                                onChange={handlerChangeServices}
                                nameField="quantity"
                                index={index}
                              />
                              <FormikControl
                                control="textInput"
                                type="text"
                                name={`services.${index}.amount`}
                                placeholder="Amount"
                                label={matches ? 'Amount' : ''}
                                onChange={handlerChangeServices}
                                nameField="amount"
                                index={index}
                              />
                              <div className={style.subtotal}>
                                {matches && <label>Subtotal</label>}
                                <span>
                                  <NumberFormat
                                    value={servicesValue[index]?.subtotal || 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={PREFIX}
                                  />
                                </span>
                              </div>
                            </div>
                          ))}
                          <div className={style.servicesActions}>
                            <Button
                              onClick={() => handlerAddService(arrayHelpers)}
                              className={style.button}
                              variant="contained"
                              color="secondary"
                            >
                              + Add service
                            </Button>
                            {values.services.length > 1 && (
                              <Button
                                onClick={() => handlerRemoveAll(arrayHelpers, values.services.length)}
                                className={style.button}
                                variant="contained"
                                color="primary"
                              >
                                Remove all
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className={style.wrapperTotal}>
                  <div className={style.total}>
                    <span className={style.labelTotal}>Grand Total</span>
                    <span className={style.counterTotal}>
                      <NumberFormat value={totalValue} displayType={'text'} thousandSeparator={true} prefix={PREFIX} />
                    </span>
                  </div>
                </div>
                <div className={style.actions}>
                  <Button onClick={handlerGoBack} className={style.button} variant="outlined">
                    Back
                  </Button>
                  <Button
                    className={style.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isEdit ? 'Save' : 'Send'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Container>
  );
};

export default React.memo(DoctorCreateInvoicePage);
