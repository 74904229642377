import React from 'react';
import PropTypes from 'prop-types';
import style from './creditCard.module.css';
import { Button, Dialog } from '@material-ui/core';
import { Formik } from 'formik';
import { doctorCardSchema } from '../../forms/validationSchema';
import FormikControl from '../../forms/formikControl';
import { CARD_CVV, CARD_EXPIRIES, CARD_NUMBER } from '../../constants/formatValueInput';
import formattedCardExpiries from '../../helpers/formattedCardExpiries';

const CreditCard = ({ onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  const initialValues = {
    number: '5111111111111111',
    expiries: '10/24',
    cvv: '123',
  };

  const onSubmit = (values) => {
    onClose({ ...values, expiries: formattedCardExpiries(values.expiries) });
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog" open={open} maxWidth={false}>
      <div className={style.wrapper}>
        <span className={style.buttonClose} onClick={handleClose}>
          &#x2715;
        </span>
        <div className={style.header}>
          <h4 className={style.title}> Enter payment card details</h4>
        </div>
        <Formik
          initialValues={process.env.REACT_APP_ENV === 'dev' ? initialValues : null}
          validationSchema={process.env.REACT_APP_ENV === 'dev' ? null : doctorCardSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, resetForm }) => (
            <form autoComplete="off" className={style.form} onSubmit={handleSubmit}>
              <div className={style.formGrid}>
                <FormikControl
                  control="textInput"
                  label="Card number"
                  type="text"
                  name="number"
                  placeholder="***** ***** ***** *****"
                  format={CARD_NUMBER}
                />
                <div className={style.formSubGrid}>
                  <FormikControl
                    control="textInput"
                    label="Expiries"
                    type="text"
                    name="expiries"
                    placeholder="mm/yy"
                    format={CARD_EXPIRIES}
                  />
                  <FormikControl
                    control="textInput"
                    label="CVV"
                    type="text"
                    name="cvv"
                    placeholder="CVV"
                    format={CARD_CVV}
                  />
                </div>
              </div>
              <div className={style.actions}>
                <Button
                  className={style.button}
                  variant="outlined"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button className={style.button} variant="contained" color="primary" type="submit">
                  Pay
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

CreditCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default React.memo(CreditCard);
