import { createSlice } from '@reduxjs/toolkit';

const toolkitSlice = createSlice({
  name: 'schedule',
  initialState: {
    blockedTime: [],
    blockedTimeOfDay: [],
    calendarDate: new Date().toISOString(),
    scheduleOfWeek: {},
    appointmentsOfDay: [],
  },
  reducers: {
    setBlockedTime(state, action) {
      state.blockedTime = action.payload;
    },
    setBlockedTimeOfDay(state, action) {
      state.blockedTimeOfDay = action.payload;
    },
    setCalendarDate(state, action) {
      state.calendarDate = action.payload;
    },
    setScheduleOfWeek(state, action) {
      state.scheduleOfWeek = action.payload;
    },
    setAppointmentsOfDay(state, action) {
      state.appointmentsOfDay = action.payload;
    },
  },
});

export default toolkitSlice.reducer;
export const {
  setBlockedTime,
  setBlockedTimeOfDay,
  setCalendarDate,
  setScheduleOfWeek,
  setAppointmentsOfDay,
} = toolkitSlice.actions;
