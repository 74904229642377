import * as Yup from 'yup';
import valid from 'card-validator';
import * as dayjs from 'dayjs';
import { CONFIRMED, UNCONFIRMED } from './../constants/appointmentStatus';

const required = Yup.string().required('Required');

const email = Yup.string().email('Invalid email address').required('Required');

const password = Yup.string()
  .min(8, 'Should be at least 8 characters')
  .matches(/(?=.*[0-9])/g, 'Should be one number')
  .matches(/(?=.*[a-z])/g, 'Should be one lowercase latin letter')
  .matches(/(?=.*[A-Z])/g, 'Should be one uppercase latin letter')
  .required('Required');

const passwordConfirm = Yup.string()
  .oneOf([Yup.ref('password')], 'Passwords should match')
  .required('Required');

const date = Yup.date().required('Required').nullable();

const phoneRegExp = /1868[0-9]{7}$/;
const phone = Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required');

const chips = Yup.array().min(1, 'Add at least one value').required('Required').nullable();

const photo = Yup.object().required('Required').nullable();

const onlyNumbers = Yup.string().matches(/^[+-]?\d+([.]\d+)?$/, 'Only numbers');

export const signInSchema = Yup.object({
  email,
  password,
});

export const signUpSchema = Yup.object({
  email,
  password,
  passwordConfirm,
});

export const forgotPasswordSchema = Yup.object({
  email,
});

export const restorePasswordSchema = Yup.object({
  password,
  passwordConfirm,
});

export const doctorPersonalDataSchema = Yup.object({
  firstName: required,
  lastName: required,
  gender: required,
  birth: date,
  city: required,
  address: required,
  phone,
});

export const doctorsDataSchema = Yup.object({
  qualifications: chips,
  specialities: chips,
  photo,
  photoId: required,
  licenceId: required,
  registrationId: required,
});

export const codeSchema = Yup.object({
  code: required,
});

export const doctorOfficesSchema = Yup.object({
  offices: Yup.array().of(
    Yup.object({
      name: required,
      address: required,
      country: required,
      city: required,
      phone,
      email,
    }),
  ),
});

export const doctorCardSchema = Yup.object({
  number: Yup.string()
    .test('test-number', 'credit card number is invalid', (value) => valid.number(value).isValid)
    .required(),
  // number: required,
  expiries: Yup.string()
    .test('test-number', 'expiration is invalid', (value) => valid.expirationDate(value).isValid)
    // .matches(/((^1[0-2]{1})|(^0[1-9]{1}))[/][0-9]{2}$/, 'use format mm/yy')
    .required(),
  cvv: Yup.string()
    .test('test-number', 'cvv is invalid', (value) => valid.cvv(value).isValid)
    .required(),
});

const testTime = (start, end) => {
  const startSplit = start.split(':');
  const endSplit = end.split(':');
  return (
    +dayjs().hour(startSplit[0]).minute(startSplit[1]).second(0) <
    +dayjs().hour(endSplit[0]).minute(endSplit[1]).second(0)
  );
};

export const doctorScheduleSchema = Yup.object({
  sunStart: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.sunEnd);
  }),
  sunEnd: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.sunStart, value);
  }),
  monStart: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.monEnd);
  }),
  monEnd: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.monStart, value);
  }),
  tueStart: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.tueEnd);
  }),
  tueEnd: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.tueStart, value);
  }),
  wedStart: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.wedEnd);
  }),
  wedEnd: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.wedStart, value);
  }),
  thuStart: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.thuEnd);
  }),
  thuEnd: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.thuStart, value);
  }),
  friStart: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.friEnd);
  }),
  friEnd: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.friStart, value);
  }),
  satStart: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.satEnd);
  }),
  satEnd: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.satStart, value);
  }),
  appointmentTime: required,
});

export const doctorBlockedTimeSchema = Yup.object({
  start: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.end);
  }),
  end: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.start, value);
  }),
});

export const patientPersonalDataSchema = Yup.object({
  firstName: required,
  lastName: required,
  gender: required,
  birth: date,
  city: required,
  address: required,
  email,
  phone,
  height: onlyNumbers,
  weight: onlyNumbers,
  illnesses: chips,
});

export const restorePasswordSettingsSchema = Yup.object({
  oldPassword: password,
  password,
  passwordConfirm,
});

export const createAppointmentSchema = Yup.object({
  status: Yup.string().oneOf([CONFIRMED]),
  date,
  start: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.end);
  }),
  end: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.start, value);
  }),
  patientId: required,
  email,
});

export const addPatientWithAppointmentSchema = Yup.object({
  status: Yup.string().oneOf([CONFIRMED]),
  date,
  start: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.end);
  }),
  end: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.start, value);
  }),
  firstName: required,
  lastName: required,
  email,
  gender: required,
  birth: date,
  city: required,
  address: required,
  phone,
  illness: chips,
});

export const doctorPrescriptionSchema = Yup.object({
  creationDate: date,
  patientId: required,
  signature: photo,
  supplies: required,
  description: required,
});

export const createLabResultSchema = Yup.object({
  title: required,
  creationDate: required,
  description: required.max(1000, 'Max length 1000 letters'),
});

export const createLabCategorySchema = Yup.object({
  title: required,
  unit: required,
});

export const createLabValueSchema = Yup.object({
  title: required,
  creationDate: date,
  value: onlyNumbers.required('Required').nullable(),
  unit: Yup.string().oneOf(['percentage', 'absolute']),
  flag: Yup.string().oneOf(['high', 'normal', 'low']),
  reference: required,
  description: required,
});

export const createRequestSchema = Yup.object({
  status: Yup.string().oneOf([UNCONFIRMED]),
  start: Yup.string().test('test-number', 'start time must be before end', function (value) {
    return testTime(value, this.parent.end);
  }),
  end: Yup.string().test('test-number', 'end time must be after start', function (value) {
    return testTime(this.parent.start, value);
  }),
  patientId: required,
  email,
});

export const doctorCreateInvoiceSchema = Yup.object({
  number: required,
  invoiceDate: date,
  dueDate: date,
  patientId: required,
  services: Yup.array().of(
    Yup.object({
      description: required,
      billingType: Yup.string().oneOf(['flat-rate', 'hourly']),
      quantity: onlyNumbers.required('Required').nullable(),
      amount: onlyNumbers.required('Required').nullable(),
    }),
  ),
});

export const contactUsSchema = Yup.object({
  name: required,
  email,
  message: required,
});
