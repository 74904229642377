// phone
export const FORMAT_PHONE = '+#-(###)-###-####';

// card
export const CARD_NUMBER = '#### #### #### ####';
export const CARD_EXPIRIES = '##/##';
export const CARD_CVV = '###';

// prefix
export const PREFIX = '$';
