import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './deviceSelectionScreen/deviceSelectionScreen';
import IntroContainer from '../introContainer/introContainer';
import MediaErrorSnackbar from './mediaErrorSnackbar/mediaErrorSnackbar';
import PreflightTest from './preflightTest/preflightTest';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import { useSelector } from 'react-redux';
import MobileTopMenuBar from '../../../components/videoCallComponents/mobileTopMenuBar/mobileTopMenuBar';

export enum Steps {
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  //@ts-ignore
  const user = useSelector((state) => state.auth.user);

  const { getAudioAndVideoTracks } = useVideoContext();

  const { appointmentId } = useParams<{ appointmentId: string }>();

  const [step, setStep] = useState(Steps.deviceSelectionStep);

  const [name, setName] = useState<string>('unknown');
  const [roomName, setRoomName] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (appointmentId) {
      setRoomName(appointmentId);
      if (user?.personalData?.firstName && user?.personalData?.lastName) {
        setName(`${user.personalData.firstName} ${user.personalData.lastName}`);
      }
    }
  }, [user, appointmentId]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
