/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import commonStyle from './../registration.module.css';
import style from './officeDetails.module.css';
import { FieldArray, Formik } from 'formik';
import { doctorOfficesSchema } from './../../validationSchema';
import { Button } from '@material-ui/core';
import FormikControl from '../../formikControl';
import axios from './../../../API/configAPI';
import Loader from '../../../components/loader/loader';
import { FORMAT_PHONE } from '../../../constants/formatValueInput';
import formattedPhoneNumber from '../../../helpers/formattedPhoneNumber';

const OfficeDetails = ({ nextStep, backStep, viewSetting }) => {
  const MAX_OF_OFFICES = 3;
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get('auth/me').then(({ data: user }) => {
      setUser(user);
    });
  }, []);

  const initialValues = {
    name: '',
    address: '',
    country: 'Trinidad and Tobago',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    email: '',
    facebook: '',
    instagram: '',
    youtube: '',
    description: '',
  };

  const getInitValues = (offices) => {
    if (!offices || !offices.length) return [initialValues];
    return offices.map((office) => {
      return {
        id: office.id || '',
        name: office.name || '',
        address: office.address || '',
        country: 'Trinidad and Tobago',
        city: office.city || '',
        state: office.state || '',
        zipCode: office.zipCode || '',
        phone: office.phone || '',
        email: office.email || '',
        facebook: office.facebook || '',
        instagram: office.instagram || '',
        youtube: office.youtube || '',
        description: office.description || '',
      };
    });
  };

  const handlerRemoveOffice = (arrayHelpers, index) => {
    arrayHelpers.pop();
  };

  const handlerAddOffice = (arrayHelpers, index) => {
    arrayHelpers.push(initialValues);
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const requests = values.offices.map((office) => {
      const requestType = office?.id ? 'put' : 'post';
      const requestUrl = office?.id ? `office/${office.id}` : 'office';
      return axios[requestType](requestUrl, {
        ...office,
        country: '',
        phone: formattedPhoneNumber(office.phone),
      });
    });
    Promise.all(requests)
      .then((...responses) => {
        resetForm();
        setSubmitting(false);
        nextStep();
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {!user && <Loader />}
      {user && (
        <div className={commonStyle.container}>
          <Formik
            initialValues={{
              offices: getInitValues(user?.offices),
            }}
            validationSchema={doctorOfficesSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleSubmit, isSubmitting }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <FieldArray name="offices">
                  {(arrayHelpers) => (
                    <div className={style.formList}>
                      {values.offices.map((office, index) => (
                        <div key={index} className={style.formItem}>
                          <div className={commonStyle.header}>
                            <h3 className={commonStyle.title}>Office details</h3>
                          </div>
                          <div className={style.formItemBody}>
                            <div className={style.formGrid}>
                              <FormikControl
                                control="textInput"
                                label="Office name"
                                type="text"
                                name={`offices.${index}.name`}
                                placeholder="Name"
                              />
                              <FormikControl
                                control="textInput"
                                label="Address"
                                type="text"
                                name={`offices.${index}.address`}
                                placeholder="Address"
                              />
                              <FormikControl
                                control="textInput"
                                label="Country"
                                type="text"
                                name={`offices.${index}.country`}
                                placeholder=""
                                disabled={true}
                              />
                              <FormikControl
                                control="textInput"
                                label="City"
                                type="text"
                                name={`offices.${index}.city`}
                                placeholder="City"
                              />
                              <FormikControl
                                control="textInput"
                                label="State"
                                type="text"
                                name={`offices.${index}.state`}
                                placeholder="State"
                              />
                              <FormikControl
                                control="textInput"
                                label="Zip code"
                                type="text"
                                name={`offices.${index}.zipCode`}
                                placeholder="Zip code"
                              />
                              <FormikControl
                                control="textInput"
                                label="Phone"
                                type="text"
                                name={`offices.${index}.phone`}
                                placeholder="Phone"
                                format={FORMAT_PHONE}
                              />
                              <FormikControl
                                control="textInput"
                                label="Business email"
                                type="email"
                                name={`offices.${index}.email`}
                                placeholder="Email"
                              />
                            </div>
                            <div className={style.formSubGrid}>
                              <FormikControl
                                control="textarea"
                                label="Description"
                                type="text"
                                name={`offices.${index}.description`}
                                placeholder="Description"
                              />
                            </div>
                            <h4 className={commonStyle.subtitle}>Social networks</h4>
                            <div className={style.formGrid}>
                              <FormikControl
                                control="textInput"
                                label="Facebook"
                                type="text"
                                name={`offices.${index}.facebook`}
                                placeholder="Add link of your profile"
                              />
                              <FormikControl
                                control="textInput"
                                label="Instagram"
                                type="text"
                                name={`offices.${index}.instagram`}
                                placeholder="Add link of your profile"
                              />
                              <FormikControl
                                control="textInput"
                                label="Youtube"
                                type="text"
                                name={`offices.${index}.youtube`}
                                placeholder="Add link of your profile"
                              />
                            </div>
                            {values.offices.length === index + 1 && (
                              <div className={style.actions}>
                                {false && // TODO: delete this line if need function remove office
                                  index !== 0 && (
                                    <Button
                                      className={style.button}
                                      variant="outlined"
                                      onClick={() => {
                                        handlerRemoveOffice(arrayHelpers, index);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                {index + 1 < MAX_OF_OFFICES && (
                                  <Button
                                    className={style.button}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      handlerAddOffice(arrayHelpers, index);
                                    }}
                                  >
                                    Add more
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
                <div className={[commonStyle.actions, viewSetting && commonStyle.actionsSettings].join(' ')}>
                  {!viewSetting && (
                    <Button className={commonStyle.button} variant="outlined" onClick={backStep}>
                      Back
                    </Button>
                  )}
                  <Button
                    className={commonStyle.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {viewSetting ? 'Save' : 'Next Step'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default OfficeDetails;
