import React from 'react';
import PropTypes from 'prop-types';
import style from './restorePassword.module.css';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import { restorePasswordSettingsSchema } from '../../validationSchema';
import axios from './../../../API/configAPI';
import FormikControl from '../../formikControl';

const RestorePassword = ({ nextStep }) => {
  const initialValues = {
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const { oldPassword, password } = values;
    axios
      .post('auth/change-password', { password, oldPassword })
      .then(() => {
        resetForm();
        setSubmitting(false);
        nextStep('Your password has been successfully changed.');
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2 className={style.title}>Password settings</h2>
      </div>
      <Formik initialValues={initialValues} validationSchema={restorePasswordSettingsSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <FormikControl
              control="passwordInput"
              label="Current password"
              type="password"
              name="oldPassword"
              placeholder="Enter your current password"
            />
            <FormikControl
              control="passwordInput"
              label="New password"
              type="password"
              name="password"
              placeholder="8+ characters"
            />
            <FormikControl
              control="passwordInput"
              label="Confirm Password"
              type="password"
              name="passwordConfirm"
              placeholder="8+ characters"
            />
            <Button
              className={style.button}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

RestorePassword.propTypes = {
  nextStep: PropTypes.func,
};

export default React.memo(RestorePassword);
