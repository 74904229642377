import React, { useState, useEffect } from 'react';
import style from './invoicesPage.module.css';
import { Button, Container } from '@material-ui/core';
import Loader from '../../components/loader/loader';
import { useHistory } from 'react-router';
import { DOCTOR_CREATE_INVOICE } from '../../constants/locationPath';
import Pagination from '@material-ui/lab/Pagination';
import SearchBy from './../../components/searchBy/searchBy';
import axios from './../../API/configAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InvoiceListItem from '../../components/invoiceListItem/invoiceListItem';
import Filter from '../../components/filter/filter';
import { filterSettings, valuesRadio } from '../../components/filter/filterSettings';
import { useSelector } from 'react-redux';
import { DOCTOR_ROLE } from '../../constants/userRole';

const InvoicesPage = () => {
  const COUNT_SHOW_ON_PAGE = 8;
  const KEY_SEARCH_NAME = 'name';
  const KEY_SEARCH_NUMBER = 'number';
  const KEY_SEARCH_DATE = 'date';
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [invoicesList, setInvoicesList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const matches = useMediaQuery('(max-width:576px)');
  const [filterValues, setFilterValues] = useState({
    from: filterSettings[filterSettings.length - 1].from,
    to: filterSettings[filterSettings.length - 1].to,
    filterBy: valuesRadio[0].key,
  });
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getInvoices();
  }, [page, filterValues]);

  const getInvoices = (searchValues, searchByValue) => {
    setIsLoading(true);
    setInvoicesList([]);
    const searchBy = searchByValue && searchValues ? `&${searchByValue}=${searchValues}` : '';
    const skip = page > 1 ? (page - 1) * COUNT_SHOW_ON_PAGE : 0;
    const from = filterValues.from || '';
    const to = filterValues.to || '';
    const filterBy = filterValues.filterBy || '';
    axios
      .get(`invoice?take=${COUNT_SHOW_ON_PAGE}&skip=${skip}&from=${from}&to=${to}&filterBy=${filterBy}${searchBy}`)
      .then(({ data: { results, total } }) => {
        setInvoicesList(results);
        if (total <= COUNT_SHOW_ON_PAGE) {
          setTotalPages(0);
        } else {
          setTotalPages(Math.ceil(total / COUNT_SHOW_ON_PAGE));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleAddInvoice = () => {
    history.push(`${DOCTOR_CREATE_INVOICE}`);
  };

  const handleSearch = (value, searchBy) => {
    getInvoices(value, searchBy);
  };

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const onChangeFilter = (from, to, filterBy) => {
    setFilterValues({ from, to, filterBy });
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <Container>
          <div className={style.actions}>
            <h3 className={style.title}>Invoices</h3>
            {user?.role === DOCTOR_ROLE && (
              <Button className={style.addButton} variant="contained" color="primary" onClick={handleAddInvoice}>
                + Add Invoice
              </Button>
            )}
          </div>
          <div className={style.actions}>
            <SearchBy
              handleSearch={handleSearch}
              searchByValues={[KEY_SEARCH_NAME, KEY_SEARCH_NUMBER, KEY_SEARCH_DATE]}
            />
            <Filter onApplyFilterValues={onChangeFilter} />
          </div>
        </Container>
      </div>
      <div className={style.content}>
        <Container className={style.container}>
          <ul className={style.listLabels}>
            <li className={style.label}>Name</li>
            <li className={style.label}>Number</li>
            <li className={style.label}>Invoice date</li>
            <li className={style.label}>Due date</li>
            <li className={style.label}>Total</li>
            <li className={style.label}>Status</li>
          </ul>
          {isLoading && <Loader />}
          {!isLoading && !!invoicesList.length && (
            <ul className={style.invoicesList}>
              {invoicesList.map((invoiceData) => (
                <li key={invoiceData.id} className={style.invoicesListItem}>
                  <InvoiceListItem invoiceData={invoiceData} updateList={getInvoices} />
                </li>
              ))}
            </ul>
          )}
          {!isLoading && !invoicesList.length && (
            <div className={style.notFoundBlock}>
              <h3 className={style.notFoundBlockTitle}>Not found</h3>
            </div>
          )}
          {!!totalPages && (
            <div className={style.patientsPaginationWrapper}>
              <Pagination
                siblingCount={Number(!matches)}
                count={totalPages}
                color="secondary"
                onChange={handleChangePagination}
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default React.memo(InvoicesPage);
