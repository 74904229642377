import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import * as dayjs from 'dayjs';
import LocalVideoPreview from './localVideoPreview/localVideoPreview';
import { Steps } from '../preJoinScreens';
import ToggleAudioButton from '../../buttons/toggleAudioButton/toggleAudioButton';
import ToggleVideoButton from '../../buttons/toggleVideoButton/toggleVideoButton';
import { useAppState } from '../../../../state';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import axios from '../../../../API/configAPI';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    fontSize: '1.2em',
    height: '4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
    },
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  joinButton: {
    width: '100%',
    margin: '1em 0',
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching, token } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    if (token) {
      connect(token);
    }
  };

  const [appointment, setAppointment] = useState(null);

  useEffect(() => {
    if (roomName) {
      axios.get(`/appointment/details/${roomName}`).then(({ data }) => {
        setAppointment(data);
      });
    }
  }, [roomName]);

  return (
    <>
      <Grid container justify="center" style={{ height: '100%' }}>
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Typography className={classes.gutterBottom}>
            {appointment && //@ts-ignore
              `Appointment with ${appointment.firstName} ${appointment.lastName} (${dayjs(appointment.start).format(
                'YYYY-MM-DD hh:mm',
              )})`}
          </Typography>
          <Grid container direction="row" justify="center">
            <Hidden smDown>
              <ToggleAudioButton disabled={disableButtons} />
              <ToggleVideoButton disabled={disableButtons} />
            </Hidden>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            data-cy-join-now
            onClick={handleJoin}
            disabled={disableButtons}
            className={classes.joinButton}
          >
            Join Now
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
