import React from 'react';

export default function EndCallIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#D13B2A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.2782 20.2218C35.6924 19.636 34.7426 19.636 34.1568 20.2218L28.5 25.8787L22.8431 20.2218C22.2573 19.636 21.3076 19.636 20.7218 20.2218C20.136 20.8076 20.136 21.7573 20.7218 22.3431L26.3787 28L20.7218 33.6568C20.136 34.2426 20.136 35.1924 20.7218 35.7782C21.3076 36.3639 22.2573 36.3639 22.8431 35.7782L28.5 30.1213L34.1568 35.7782C34.7426 36.3639 35.6924 36.3639 36.2782 35.7782C36.8639 35.1924 36.8639 34.2426 36.2782 33.6568L30.6213 28L36.2782 22.3431C36.8639 21.7573 36.8639 20.8076 36.2782 20.2218Z"
        fill="white"
      />
    </svg>
  );
}
