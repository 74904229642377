import React from 'react';
import style from './doctorCreatePatientPage.module.css';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import { DOCTOR_MY_PATIENTS } from '../../constants/locationPath';
import PersonalData from '../../forms/patientRegistration/personalData/personalData';

const DoctorCreatePatientPage = () => {
  const history = useHistory();

  const nextStep = () => {
    history.push(DOCTOR_MY_PATIENTS);
  };

  return (
    <Container className={style.wrapper}>
      <div className={style.container}>
        <h2 className={style.title}>Create Patient</h2>
        <PersonalData nextStep={nextStep} />
      </div>
    </Container>
  );
};

export default React.memo(DoctorCreatePatientPage);
