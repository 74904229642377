import { createSlice } from '@reduxjs/toolkit';

const toolkitSlice = createSlice({
  name: 'imageCache',
  initialState: {},
  reducers: {
    setImageCache(state, action) {
      state[action.payload.id] = action.payload;
    },
  },
});

export default toolkitSlice.reducer;
export const { setImageCache } = toolkitSlice.actions;
