import React from 'react';
import style from './doctorCreateAppointmentsPage.module.css';
import { Container } from '@material-ui/core';
import CreateAppointment from '../../forms/createAppointment/createAppointment';

const DoctorCreateAppointmentsPage = () => {
  return (
    <Container className={style.wrapper}>
      <div className={style.container}>
        <h2 className={style.title}>Create appointment</h2>
        <div className={style.content}>
          <CreateAppointment />
        </div>
      </div>
    </Container>
  );
};

export default DoctorCreateAppointmentsPage;
