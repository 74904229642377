import React, { useState, useEffect } from 'react';
import style from './scheduleTable.module.css';
import Loader from '../../components/loader/loader';
import axios from '../../API/configAPI';
import EditButton from '../../components/editButton/editButton';
import { useHistory } from 'react-router';
import { DOCTOR_CREATE_SCHEDULE } from './../../constants/locationPath';
import { DAYS_OF_WEEK_SHORT_NAME } from '../../constants/daysOfWeek';
import { useDispatch, useSelector } from 'react-redux';
import { setScheduleOfWeek } from './../../store/scheduleSlice';
import { DOCTOR_ROLE } from '../../constants/userRole';
import dayJs from './../../helpers/getDayJsWithTimezone';

const ScheduleTable = ({ scheduleId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const schedule = useSelector((state) => state.schedule.scheduleOfWeek);
  const canEdit = user?.role === DOCTOR_ROLE;

  useEffect(() => {
    if (scheduleId) {
      axios.get(`schedule/${scheduleId}`).then(({ data: schedule }) => {
        dispatch(setScheduleOfWeek(schedule));
      });
    }

    return () => {
      dispatch(setScheduleOfWeek({}));
    };
  }, [scheduleId]);

  const clickEdit = () => {
    history.push(DOCTOR_CREATE_SCHEDULE);
  };

  const getSchedule = (shortDayName) => {
    const start = schedule[shortDayName.toLowerCase() + 'Start'];
    const end = schedule[shortDayName.toLowerCase() + 'End'];
    if (!(start || end)) return;
    const startSplit = start.split(':');
    const endSplit = end.split(':');
    const startDisplay = dayJs().hour(startSplit[0]).minute(startSplit[1]).format('hh:mm a');
    const endDisplay = dayJs().hour(endSplit[0]).minute(endSplit[1]).format('hh:mm a');
    if (start && end) {
      return (
        <>
          <span>{startDisplay}</span>&ensp;-&ensp;<span>{endDisplay}</span>
        </>
      );
    }
  };

  return (
    <>
      {!schedule && <Loader />}
      {schedule && (
        <div className={style.wrapper}>
          <div className={style.contentHeader}>
            <span className={style.subtitle}>Doctor Opening Hours:</span>
            {canEdit && <EditButton handlerClick={clickEdit} />}
          </div>
          <div className={style.table}>
            {DAYS_OF_WEEK_SHORT_NAME.map((shortDayName, index) => (
              <div key={index} className={style.cell}>
                <div className={style.cellHeader}>{shortDayName}</div>
                <div className={style.sellContent}>{schedule && getSchedule(shortDayName)}</div>
              </div>
            ))}
          </div>
          <div className={style.description}>
            <p>*All times Trinidad and Tobago (-4&nbsp;GMT)</p>
            {schedule && schedule.appointmentTime && (
              <p>
                Appointment Duration: <span className={style.mark}>{schedule.appointmentTime.slice(0, 5)}</span>
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(ScheduleTable);
