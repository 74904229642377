/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from '../loader/loader';
import style from './viewImage.module.css';
import axios from './../../API/configAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setImageCache } from '../../store/imageCacheSlice';
import { Modal, Backdrop, Fade } from '@material-ui/core';

// icon
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const ViewImage = ({ imageId, size, borderRadius, isCanDownload }) => {
  const [imageData, setImageData] = useState(null);
  const [isLoadPhoto, setIsLoadPhoto] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const imageCache = useSelector((state) => state.imageCache[imageId]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (imageId === null) {
      return setImageData(null);
    }
    if (imageCache?.encodedFile) {
      return setImageData(imageCache);
    }
    if (imageId && token) {
      setIsLoadPhoto(true);
      axios
        .get(`files/${imageId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data: { encodedFile, id, originalname, mimetype, size } }) => {
          dispatch(
            setImageCache({
              id,
              encodedFile,
              originalname,
              mimetype,
              size,
            }),
          );
          setImageData(encodedFile);
          setIsLoadPhoto(false);
        })
        .catch(() => {
          setIsLoadPhoto(false);
        });
    }
  }, [imageId, token, imageCache]);

  const handleOpen = () => {
    if (isCanDownload) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={[
          style.container,
          !imageData?.encodedFile && !isLoadPhoto && style.empty,
          isCanDownload && style.canDownload,
        ].join(' ')}
        style={{
          width: size,
          height: size,
          borderRadius,
        }}
        onClick={handleOpen}
      >
        {isLoadPhoto && <Loader size="50%" />}
        {imageData?.encodedFile && !isLoadPhoto && (
          <img src={imageData?.encodedFile} alt={imageData?.originalname || 'avatar'} />
        )}
        {!imageData?.encodedFile && !isLoadPhoto && <PersonOutlineIcon className={style.placeholder} />}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={style.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={style.paper}>
            <span className={style.modalClose} onClick={handleClose}>
              &#x2715;
            </span>
            <div className={style.WrapperImage}>
              <img src={imageData?.encodedFile} alt={imageData?.originalname || 'avatar'} />
            </div>
            <div className={style.wrapperDownload}>
              <a
                className={style.download}
                href={imageData?.encodedFile}
                download={imageData?.originalname || 'Photo'}
                target="_blank"
              >
                Download
              </a>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

ViewImage.defaultProps = {
  size: 'inherit',
  borderRadius: '10%',
  isCanDownload: false,
  imageId: null,
};

ViewImage.propTypes = {
  imageId: PropTypes.string,
  size: PropTypes.string,
  borderRadius: PropTypes.string,
  isCanDownload: PropTypes.bool,
};

export default React.memo(ViewImage);
