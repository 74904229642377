import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './filter.module.css';
import { Popover, Button, MenuItem } from '@material-ui/core';
import { filterSettings, valuesRadio } from './filterSettings';

// icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Filter = ({ onApplyFilterValues }) => {
  const [activeSetting, setActiveSetting] = useState(filterSettings[filterSettings.length - 1].settingName);
  const [prevSetting, setPrevSetting] = useState(filterSettings[filterSettings.length - 1].settingName);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [prevValueRadio, setPrevValueRadio] = useState(valuesRadio[0].key);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    setActiveSetting(prevSetting);
    const { from, to } = filterSettings.find(({ settingName }) => settingName === prevSetting);
    onApplyFilterValues(from, to, prevValueRadio);
    handleClose();
  };

  const handleToggleSelect = (settingName) => {
    setPrevSetting(settingName);
  };

  const handlerDate = (key) => {
    setPrevValueRadio(key);
  };

  return (
    <div className={style.wrapper}>
      <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V2.83333H25V0H0ZM9.72222 17H15.2778V14.1667H9.72222V17ZM20.8333 9.91667H4.16667V7.08333H20.8333V9.91667Z"
          fill="#1D2239"
        />
      </svg>
      <span className={style.label}>Filter by:</span>
      <div>
        <button className={style.action} aria-describedby="simple-popover" onClick={handleClick}>
          {activeSetting}
          <KeyboardArrowDownIcon className={[style.actionIcon, open && style.open].join(' ')} />
        </button>
        <Popover
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={style.wrapperMenu}>
            <div className={style.buttonRadio}>
              {valuesRadio.map(({ settingName, key }) => (
                <label key={key} className={style.wrapperRadio}>
                  <input
                    onChange={() => handlerDate(key)}
                    className={style.inputRadio}
                    type="radio"
                    name="date"
                    checked={key === prevValueRadio}
                    value={key}
                  />
                  <span className={style.boxRadio}></span>
                  <span className={style.labelRadio}>{settingName}</span>
                </label>
              ))}
            </div>

            {filterSettings.map(({ settingName }, index) => (
              <MenuItem
                key={index}
                onClick={() => handleToggleSelect(settingName)}
                className={[
                  style.menuItemStyle,
                  style.menuItem,
                  settingName === prevSetting && style.menuItemMark,
                ].join(' ')}
              >
                {settingName}
              </MenuItem>
            ))}

            <div className={style.applyAction}>
              <Button className={style.applyButton} variant="contained" color="secondary" onClick={handleApply}>
                Apply
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

Filter.propTypes = {
  onApplyFilterValues: PropTypes.func.isRequired,
};

export default React.memo(Filter);
