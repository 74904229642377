import React from 'react';

export default function ExpandIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="38" height="38" rx="14" fill="#F0F0F2" fill-opacity="0.3" />
      <path
        d="M26.261 12.5743L21.1695 17.6658L20.333 16.8293L25.424 11.7378H20.937V10.5547H27.4441V17.0618H26.261V12.5743Z"
        fill="white"
      />
      <path
        d="M11.739 25.4257L16.8305 20.3342L17.667 21.1707L12.576 26.2622H17.063V27.4453H10.5559V20.9382H11.739V25.4257Z"
        fill="white"
      />
    </svg>
  );
}
