/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './appointmentListItem.module.css';
import ViewImage from '../viewImage/viewImage';
import { Button, Menu, MenuItem, Popover } from '@material-ui/core';
import { useHistory } from 'react-router';
import {
  DOCTOR_CREATE_APPOINTMENTS,
  DOCTOR_PATIENT_PROFILE,
  PROFILE_DOCTORS,
  VIDEO_CALL,
  PATIENT_REQUEST,
  DOCTOR_CREATE_INVOICE,
} from '../../constants/locationPath';
import dayJs from './../../helpers/getDayJsWithTimezone';
import { useSelector, useDispatch } from 'react-redux';
import { setModalContent, setModalAnswer } from './../../store/modalSlice';
import { DOCTOR_ROLE } from '../../constants/userRole';
import { REQUESTS, UPCOMING, PAST } from './../../constants/appointmentType';
import axios from './../../API/configAPI';
import { CONFIRMED, REJECTED, CANCELED } from './../../constants/appointmentStatus';
import LinkToProfile from '../linkToProfile/linkToProfile';

// icons
import CallIcon from '@material-ui/icons/Call';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const AppointmentListItem = ({ type, appointment, onChangeStatus, isPreview }) => {
  const CANCEL_APPOINTMENT = '[AppointmentListItem] Cancel appointment';
  const dispatch = useDispatch();
  const answerModal = useSelector((state) => state.modal.answer);
  const user = useSelector((state) => state.auth.user);
  const isDoctor = user?.role === DOCTOR_ROLE;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const openPopover = Boolean(anchorElPopover);
  const history = useHistory();

  const pointMenuCancel = { value: 'Cancel', key: 'cancel' };
  const pointMenuProfile = { value: 'Profile', key: 'profile' };
  const pointMenuEdit = { value: 'Edit', key: 'edit' };
  const pointMenuInvoice = { value: 'Send invoice', key: 'invoice' };
  const pointMenuCopyLink = { value: 'Copy link', key: 'link' };

  const options = [];

  if (!isPreview) {
    options.push(pointMenuProfile);
  }

  if (isDoctor) {
    options.push(pointMenuInvoice);
  }

  if (type === UPCOMING && !appointment.location) {
    options.push(pointMenuCopyLink);
  }

  if (type !== PAST) {
    options.push(pointMenuEdit);
    options.push(pointMenuCancel);
  }

  const contentModal = {
    isClosedByOverlay: true,
    title: 'Cancel appointment?',
    description: `
      Do you want to cancel appointment?
    `,
    actions: [
      {
        title: 'No',
        answerModal: '',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        title: 'Yes',
        answerModal: `${CANCEL_APPOINTMENT} ${appointment.id}`,
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const menuProps = {
    style: {
      background: '#FFFFFF',
      border: '1px solid #F7F7F9',
      boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.085)',
      borderRadius: '8px',
      padding: '12px 14px',
    },
  };

  useEffect(() => {
    if (answerModal === `${CANCEL_APPOINTMENT} ${appointment.id}`) {
      dispatch(setModalAnswer(''));
      handlerStatus(CANCELED);
    }
  }, [answerModal]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = (v) => {
    switch (v) {
      case pointMenuProfile.key:
        goToProfile();
        break;
      case pointMenuEdit.key:
        goToEdit();
        break;
      case pointMenuCancel.key:
        handlerCanceled();
        break;
      case pointMenuInvoice.key:
        goToInvoice();
        break;
      case pointMenuCopyLink.key:
        copyLink();
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const goToProfile = () => {
    if (isDoctor) {
      history.push(`${DOCTOR_PATIENT_PROFILE}/${appointment.patientId}`);
    } else {
      history.push(`${PROFILE_DOCTORS}/${appointment.userId}`);
    }
  };

  const goToEdit = () => {
    if (isDoctor) {
      history.push(DOCTOR_CREATE_APPOINTMENTS, { patientId: appointment.patientId, appointmentId: appointment.id });
    } else {
      history.push(`${PATIENT_REQUEST}/${appointment.userId}`, { appointmentId: appointment.id });
    }
  };

  const handlerCanceled = () => {
    dispatch(setModalContent(contentModal));
  };

  const goToInvoice = () => {
    history.push(`${DOCTOR_CREATE_INVOICE}`, { patientId: appointment.patientId });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}${VIDEO_CALL}/${appointment.id}`);
  };

  const getEventDate = () => {
    return dayJs(appointment.start).tz().format('DD MMMM YYYY');
  };

  const getStartEndTime = () => {
    const startTime = dayJs(appointment.start).tz().format('HH:mm A');
    const endTime = dayJs(appointment.end).tz().format('HH:mm A');
    return `${startTime} - ${endTime}`;
  };

  const getDuration = () => {
    const minutesDuration = (dayJs(appointment.end) - dayJs(appointment.start)) / 1000 / 60;
    const minutes = minutesDuration % 60;
    const hours = Math.floor(minutesDuration / 60);
    return `${hours === 0 ? '' : hours}${minutes === 0 ? '' : (hours > 0 ? ':' : '') + minutes}${
      hours > 0 ? 'h' : 'm'
    }`;
  };

  const goToCall = () => {
    window.open(`${VIDEO_CALL}/${appointment.id}`, '_blank');
  };

  const handlerLocation = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const handlerStatus = (status) => {
    axios
      .patch(`appointment/${appointment.id}`, { status })
      .then(({ data }) => {
        onChangeStatus(data);
      })
      .catch((error) => {});
  };

  return (
    <>
      {appointment && (
        <div className={[style.wrapper, isDoctor && style.forDoctor, isPreview && style.forPreview].join(' ')}>
          {!isPreview && (
            <div className={[style.cell, style.personalInfo].join(' ')}>
              <ViewImage imageId={appointment.avatarId} size="52px" borderRadius="10px" />
              <LinkToProfile
                firstName={appointment.firstName}
                lastName={appointment.lastName}
                role={appointment.role}
                userId={appointment.userId}
                className={style.name}
              />
            </div>
          )}
          {!isDoctor && <div className={[style.cell, style.mark].join(' ')}>Doctor</div>}
          <div className={style.cell}>
            <span className={style.icon}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  // eslint-disable-next-line max-len
                  d="M17.8333 3.66667H14.5V2H12.8333V3.66667H7.83333V2H6.16667V3.66667H2.83333C2.3731 3.66667 2 4.03976 2 4.5V17.8333C2 18.2936 2.3731 18.6667 2.83333 18.6667H17.8333C18.2936 18.6667 18.6667 18.2936 18.6667 17.8333V4.5C18.6667 4.03976 18.2936 3.66667 17.8333 3.66667ZM3.66667 10.3333H17V17H3.66667V10.3333ZM12.8333 5.33333H7.83333V7H6.16667V5.33333H3.66667V8.66667H17V5.33333H14.5V7H12.8333V5.33333ZM7 13.6667V12H5.33333V13.6667H7ZM9.5 12H11.1667V13.6667H9.5V12ZM15.3333 13.6667V12H13.6667V13.6667H15.3333Z"
                  fill="#1D2239"
                />
              </svg>
            </span>
            <span>{getEventDate()}</span>
          </div>
          <div className={style.cell}>
            <span className={style.icon}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  // eslint-disable-next-line max-len
                  d="M10.3333 18.6667C5.73083 18.6667 2 14.9358 2 10.3333C2 5.73083 5.73083 2 10.3333 2C14.9358 2 18.6667 5.73083 18.6667 10.3333C18.6667 14.9358 14.9358 18.6667 10.3333 18.6667ZM10.3333 17C14.0152 17 17 14.0152 17 10.3333C17 6.65143 14.0152 3.66667 10.3333 3.66667C6.65143 3.66667 3.66667 6.65143 3.66667 10.3333C3.66667 14.0152 6.65143 17 10.3333 17ZM14.5 10.3333H11.1667V6.16667H9.5V12H14.5V10.3333Z"
                  fill="#1D2239"
                />
              </svg>
            </span>
            <span>{getStartEndTime()}</span>
          </div>
          <div className={style.cell}>
            {type === UPCOMING && (
              <>
                <span className={style.duration}>{getDuration()}</span>
                {!appointment.location && (
                  <Button
                    className={style.circleButton}
                    variant="contained"
                    color="secondary"
                    aria-label="call"
                    onClick={goToCall}
                  >
                    <CallIcon fontSize="small" />
                  </Button>
                )}
                {appointment.location && (
                  <>
                    <Button
                      className={style.circleButton}
                      variant="contained"
                      color="secondary"
                      aria-label="location"
                      onClick={handlerLocation}
                      aria-describedby={appointment.location}
                    >
                      <LocationOnIcon fontSize="small" />
                    </Button>
                    <Popover
                      id={appointment.location}
                      open={openPopover}
                      anchorEl={anchorElPopover}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      PaperProps={menuProps}
                    >
                      <p>{appointment.location}</p>
                    </Popover>
                  </>
                )}
              </>
            )}
            {type === PAST && (
              <>
                <span className={style.duration}>{getDuration()}</span>
              </>
            )}
            {type === REQUESTS && (
              <>
                {isDoctor && (
                  <>
                    <span
                      className={[style.actionButton, style.accept].join(' ')}
                      onClick={() => handlerStatus(CONFIRMED)}
                    >
                      Accept
                    </span>
                    <span
                      className={[style.actionButton, style.reject].join(' ')}
                      onClick={() => handlerStatus(REJECTED)}
                    >
                      Reject
                    </span>
                  </>
                )}
                {!isDoctor && (
                  <>
                    <span className={style.duration}>{getDuration()}</span>
                  </>
                )}
              </>
            )}
            <MoreVertIcon
              className={style.menuButton}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            />
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleCloseMenu}
              PaperProps={menuProps}
            >
              {options.map(({ value, key }) => (
                <MenuItem
                  key={key}
                  onClick={() => handleClose(key)}
                  className={[
                    style.menuItemStyle,
                    style.menuItem,
                    key === pointMenuCancel.key && style.menuItemMark,
                  ].join(' ')}
                >
                  {value}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      )}
    </>
  );
};

AppointmentListItem.defaultProps = {
  onChangeStatus: () => {},
  isPreview: false,
};

AppointmentListItem.propTypes = {
  type: PropTypes.oneOf([REQUESTS, UPCOMING, PAST]).isRequired,
  appointment: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func,
  isPreview: PropTypes.bool,
};

export default React.memo(AppointmentListItem);
