import React from 'react';

export default function SettingsIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="38" height="38" rx="14" fill="#F0F0F2" fill-opacity="0.3" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.23726 16.7367C7.92091 18.2308 7.92091 19.7746 8.23726 21.2686C9.45823 21.1245 10.5241 21.5282 10.8728 22.3697C11.2215 23.2112 10.7529 24.2518 9.78822 25.0129C10.6209 26.293 11.7124 27.3844 12.9924 28.2172C13.7547 27.2514 14.7942 26.7839 15.6357 27.1326C16.4761 27.4802 16.8797 28.5472 16.7367 29.7681C18.2308 30.0845 19.7746 30.0845 21.2686 29.7681C21.1245 28.5472 21.5282 27.4813 22.3697 27.1326C23.2112 26.7839 24.2518 27.2525 25.0129 28.2172C26.293 27.3844 27.3844 26.293 28.2172 25.0129C27.2514 24.2507 26.7839 23.2112 27.1326 22.3697C27.4802 21.5293 28.5472 21.1256 29.7681 21.2686C30.0845 19.7746 30.0845 18.2308 29.7681 16.7367C28.5472 16.8808 27.4813 16.4772 27.1326 15.6357C26.7839 14.7942 27.2525 13.7536 28.2172 12.9924C27.3844 11.7124 26.293 10.6209 25.0129 9.78822C24.2507 10.754 23.2112 11.2215 22.3697 10.8728C21.5293 10.5252 21.1256 9.45823 21.2686 8.23726C19.7746 7.92091 18.2308 7.92091 16.7367 8.23726C16.8808 9.45823 16.4772 10.5241 15.6357 10.8728C14.7942 11.2215 13.7536 10.7529 12.9924 9.78822C11.7124 10.6209 10.6209 11.7124 9.78822 12.9924C10.754 13.7547 11.2215 14.7942 10.8728 15.6357C10.5252 16.4761 9.45823 16.8797 8.23726 16.7367ZM15.7028 19.0027C15.7028 20.8252 17.1802 22.3026 19.0027 22.3026C20.8252 22.3026 22.3026 20.8252 22.3026 19.0027C22.3026 17.1802 20.8252 15.7028 19.0027 15.7028C17.1802 15.7028 15.7028 17.1802 15.7028 19.0027Z"
        fill="white"
      />
    </svg>
  );
}
