import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './imgUpload.module.css';
import imgUpload from './../../../assets/images/imgUpload.png';
import { Modal, Button, Fade, Backdrop } from '@material-ui/core';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import axios from './../../../API/configAPI';
import Loader from '../../../components/loader/loader';

const ImgUpload = ({ name, canRemoveImage, label }) => {
  const initialStateCrop = {
    unit: '%',
    width: 30,
    aspect: 1 / 1,
    x: 35,
    y: 35,
  };
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isCanSave, setIsCanSave] = useState(false);
  // const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageEl, setImageEl] = useState(null);
  const [crop, setCrop] = useState(initialStateCrop);
  const [field, meta, helpers] = useField(name);
  const [baseUrlImage, setBaseUrlImage] = useState('');
  const [photoId, setPhotoId] = useState(field.value?.id);
  const [isLoadPhoto, setIsLoadPhoto] = useState(false);

  useEffect(() => {
    if (croppedImage) {
      const formData = new FormData();
      formData.append('file', croppedImage);
      axios
        .post('files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data: { id } }) => {
          // setCroppedImageUrl(data.url);
          setPhotoId(id);
          helpers.setValue({ id });
        });
    }
  }, [croppedImage]);

  useEffect(() => {
    if (!photoId) return;
    setIsLoadPhoto(true);
    axios
      .get(`files/${photoId}`)
      .then(({ data: { encodedFile } }) => {
        setBaseUrlImage(encodedFile);
        setIsLoadPhoto(false);
      })
      .catch(() => {
        setIsLoadPhoto(false);
      });
  }, [photoId]);

  const onDrop = useCallback((acceptedFiles) => {
    const [firstFile] = acceptedFiles;
    setFileName(firstFile.name);
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setSrc(fileReader.result);
      setIsCanSave(true);
    };
    fileReader.readAsDataURL(firstFile);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    canvas
      .getContext('2d')
      .drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );

    const reader = new FileReader();
    return canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        // setCroppedImageUrl(reader.result);
        const arr = reader.result.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        setCroppedImage(new File([u8arr], fileName, { type: mime }));
      };
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCrop(initialStateCrop);
    setSrc(null);
    setImageEl(null);
    setIsCanSave(false);
    setFileName('');
  };

  const handleSave = () => {
    getCroppedImg(imageEl, crop, fileName);
  };

  const load = (image) => {
    setImageEl(image);
  };

  const handleRemoveImage = () => {
    setPhotoId('');
    helpers.setValue(null);
    setBaseUrlImage('');
  };

  return (
    <div className={style.wrapper}>
      {isLoadPhoto && (
        <div className={style.loadImageWrapper}>
          <Loader />
        </div>
      )}
      {baseUrlImage && !isLoadPhoto && (
        <div className={style.addedImageWrapper} onClick={handleOpen}>
          <div className={style.overlayAddedImage}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M10.748 0.234229L9.0504 1.93183H9.0512L12.4448 5.32543L14.1424 3.62863C14.4547 3.31623 14.4547 2.80983 14.1424 2.49743L11.8792 0.234229C11.5668 -0.0780764 11.0604 -0.0780764 10.748 0.234229ZM14.4 12.7766H4.9944H4.9936L11.3136 6.45743L7.92 3.06223L0 10.9822V14.3766H14.4V12.7766Z"
                fill="white"
              />
            </svg>
            <p className={style.overlayAddedImageText}>Edit photo</p>
          </div>
          <img src={baseUrlImage} alt="cropImage" />
        </div>
      )}
      {!baseUrlImage && !isLoadPhoto && (
        <div
          className={[style.addImageWrapper, meta.touched && meta.error && style.errorMark].join(' ')}
          onClick={handleOpen}
        >
          <img className={style.addImage} src={imgUpload} alt="upload" />
          {label ? label : 'Upload image'}
          {meta.touched && meta.error && <span className={style.errorMessage}>{meta.error}</span>}
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={style.modalOverlay}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={style.modalWindow}>
            <span className={style.modalClose} onClick={handleClose}>
              &#x2715;
            </span>
            <div className={style.modalHeader}>
              <h3 className={style.modalTitle}>Add Photo</h3>
            </div>
            <div className={style.modalBody}>
              {fileName && <p className={style.modalDescription}>{fileName}</p>}
              {src && (
                <ReactCrop
                  className={style.cropWrapper}
                  src={src}
                  crop={crop}
                  onChange={(newCrop) => setCrop(newCrop)}
                  onImageLoaded={load}
                />
              )}
              {!src && (
                <div className={[style.dragZone, isDragActive && style.dragOn].join(' ')} {...getRootProps()}>
                  <input {...getInputProps()} accept=".jpg, .jpeg, .png, image/jpg, image/jpeg, image/png" />
                  <img className={style.adviceImg} src={imgUpload} alt="upload" />
                  <p className={style.advice}>Drag and drop a photo you want to upload</p>
                  <p className={style.advice}>or</p>
                  <Button className={style.smallButton} variant="contained" color="primary">
                    Browse
                  </Button>
                </div>
              )}
            </div>
            <div className={style.modalFooter}>
              <Button className={style.button} variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className={style.modalButton}
                disabled={!isCanSave}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSave();
                  handleClose();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      {baseUrlImage && !isLoadPhoto && canRemoveImage && (
        <span className={style.imageRemoveButton} onClick={handleRemoveImage}>
          &#x2715;
        </span>
      )}
    </div>
  );
};

ImgUpload.defaultProps = {
  canRemoveImage: false,
  label: '',
};

ImgUpload.propTypes = {
  name: PropTypes.string.isRequired,
  canRemoveImage: PropTypes.bool,
  label: PropTypes.string,
};

export default React.memo(ImgUpload);
