import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import Menu from '../menuBar/menu/menu';
import axios from '../../../API/configAPI';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    height: '100px',
    backgroundColor: '#1D2239',
    zIndex: 2,
  },
  containerChatOpen: {
    right: '30%',
    [theme.breakpoints.down('sm')]: {
      right: 0,
    },
  },
  continerTimer: {
    background: 'transparent',
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 3,
    padding: '25px',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
  },
  containerMenu: {
    background: 'transparent',
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 3,
    padding: '25px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      top: 25,
    },
  },
  text: {
    textTransform: 'capitalize',
    fontSize: '16px',
  },
  button: {
    width: '40px',
    height: '40px',
    margin: '0 10px',
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();

  const { chatOpen } = useAppState();

  const [time, setTime] = React.useState(dayjs()); // Save the current date to be able to trigger an update

  const [appointment, setAppointment] = useState(null);
  const { appointmentId } = useParams<{ appointmentId: string }>();

  useEffect(() => {
    if (appointmentId) {
      axios.get(`/appointment/details/${appointmentId}`).then(({ data }) => {
        setAppointment(data);
      });
    }
  }, [appointmentId]);

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setTime(dayjs());
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  return (
    <div className={[classes.container, chatOpen ? classes.containerChatOpen : ''].join(' ')}>
      <Grid container alignItems="center" justify="center" className={classes.continerTimer}>
        {appointment && (
          <Typography variant="subtitle1" className={classes.text}>
            {
              //@ts-ignore
              `${appointment.role} | ${appointment.firstName} ${appointment.lastName} | ${time.format('HH:mm:ss')}`
            }
          </Typography>
        )}
      </Grid>
      <Grid container alignItems="center" justify="flex-end" className={classes.containerMenu}>
        <div>
          <Menu buttonClassName={classes.button} />
        </div>
      </Grid>
    </div>
  );
}
