import React from 'react';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

import ErrorDialog from '../../components/videoCallComponents/errorDialog/errorDialog';
import PreJoinScreens from '../../components/videoCallComponents/preJoinScreens/preJoinScreens';
import UnsupportedBrowserWarning from '../../components/videoCallComponents/unsupportedBrowserWarning/unsupportedBrowserWarning';
import { VideoProvider } from '../../components/videoCallComponents/videoProvider';
import { useAppState } from '../../state';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useHeight from '../../hooks/useHeight/useHeight';
import ReconnectingNotification from '../../components/videoCallComponents/reconnectingNotification/reconnectingNotification';
import MobileTopMenuBar from '../../components/videoCallComponents/mobileTopMenuBar/mobileTopMenuBar';
import Room from '../../components/videoCallComponents/room/room';
import MenuBar from '../../components/videoCallComponents/menuBar/menuBar';
import ConversationsApp from '../../components/chat';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <VideoCall />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

const VideoCall = () => {
  const { chatOpen } = useAppState();
  const roomState = useRoomState();
  const classes = useStyles();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <div style={{ display: 'flex' }}>
      <MobileTopMenuBar />
      {roomState !== 'disconnected' && <MenuBar />}
      <div className={chatOpen ? classes.displayNone : classes.container}>
        <Container style={{ height }}>
          {roomState === 'disconnected' ? (
            <Main>
              <PreJoinScreens />
            </Main>
          ) : (
              <Main>
                <ReconnectingNotification />
                <Room />
              </Main>
            )}
        </Container>
      </div>
      <ConversationsApp />
    </div>
  );
};

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  background: 'white',
  position: 'relative',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      minWidth: '70%'
    },
    displayNone: {
      flexGrow: 1,
      minWidth: '70%',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    }
  }),
);

export default VideoApp;
