import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import style from './signature.module.css';
import { useField } from 'formik';
import { Modal, Button, Fade } from '@material-ui/core';
import signature from './../../../assets/images/signature.png';
import axios from './../../../API/configAPI';
import Loader from '../../../components/loader/loader';
import SignatureCanvas from 'react-signature-canvas';

const Signature = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  const [isLoading, setIsLoading] = useState(false);
  const [signatureId, setSignatureId] = useState(field.value?.id);
  const [isEmpty, setIsEmpty] = useState(true);
  const [open, setOpen] = useState(false);
  const [baseUrlImage, setBaseUrlImage] = useState('');
  const [signatureImage, setSignatureImage] = useState(null);
  const refContainer = useRef(null);

  useEffect(() => {
    if (!signatureId) return;
    setIsLoading(true);
    axios
      .get(`files/${signatureId}`)
      .then(({ data: { encodedFile } }) => {
        setBaseUrlImage(encodedFile);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [signatureId]);

  useEffect(() => {
    if (signatureImage) {
      const formData = new FormData();
      formData.append('file', signatureImage);
      axios
        .post('files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data: { id } }) => {
          setSignatureId(id);
          helpers.setValue({ id });
        });
    }
  }, [signatureImage]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    refContainer.current.off();
    setOpen(false);
    setIsEmpty(true);
  };

  const handleSave = () => {
    if (!refContainer.current.isEmpty()) {
      getFileSignature();
    }
  };

  const handlerOnEnd = () => {
    setIsEmpty(refContainer.current.isEmpty());
  };

  const getFileSignature = () => {
    const canvas = refContainer.current.getCanvas();
    const reader = new FileReader();
    return canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const arr = reader.result.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        setSignatureImage(new File([u8arr], 'signature.png', { type: mime }));
      };
    });
  };

  const handleRemoveImage = () => {
    setSignatureId('');
    helpers.setValue(null);
    setSignatureImage(null);
    setBaseUrlImage('');
  };

  return (
    <div className={style.wrapper}>
      {isLoading && (
        <div className={style.loadImageWrapper}>
          <Loader />
        </div>
      )}
      {baseUrlImage && !isLoading && (
        <div className={style.addedImageWrapper}>
          <img src={baseUrlImage} alt="cropImage" />
          <span className={style.imageRemoveButton} onClick={handleRemoveImage}>
            &#x2715;
          </span>
        </div>
      )}
      {!baseUrlImage && !isLoading && (
        <div
          className={[style.addImageWrapper, meta.touched && meta.error && style.errorMark].join(' ')}
          onClick={handleOpen}
        >
          <img className={style.addImage} src={signature} alt="signature" />
          Upload signature
          {meta.touched && meta.error && <span className={style.errorMessage}>{meta.error}</span>}
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={style.modalOverlay}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={style.modalWindow}>
            <span className={style.modalClose} onClick={handleClose}>
              &#x2715;
            </span>
            <div className={style.modalHeader}>
              <h3 className={style.modalTitle}>Add signature</h3>
            </div>
            <div className={style.modalBody}>
              <SignatureCanvas
                ref={refContainer}
                penColor="#1d2239"
                canvasProps={{
                  className: `${style.canvasSignature}`,
                }}
                onEnd={handlerOnEnd}
              />
            </div>
            <div className={style.modalFooter}>
              <Button className={style.button} variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className={style.modalButton}
                disabled={isEmpty}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSave();
                  handleClose();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

Signature.propTypes = {
  name: PropTypes.string.isRequired,
};

export default React.memo(Signature);
