import React from 'react';
import PropTypes from 'prop-types';
import style from './labValueListItem.module.css';
import LabFlag from '../labFlag/labFlag';
import dayjs from './../../helpers/getDayJsWithTimezone';

const LabValueListItem = ({ labValueItem }) => {
  const {
    id,
    category: { title, unit },
    value,
    flag,
    reference,
    description,
    creationDate,
  } = labValueItem;

  const getFlagValue = (flagValue) => {
    return flagValue.charAt(0).toUpperCase();
  };

  return (
    <ul className={style.labValueWrapper}>
      <li className={style.labGridCell}>
        <div className={style.labTitleCell}>Comp</div>
        <span className={style.labName}>{title}</span>
      </li>
      <li className={style.labGridCell}>
        <div className={style.labTitleCell}>Value</div>
        <span title={value} className={style.labQty}>
          {value}
        </span>
      </li>
      <li className={style.labGridCell}>
        <div className={style.labTitleCell}>Unit</div>
        <span className={style.labUnit}>{unit === 'percentage' ? '%' : '0.00'}</span>
      </li>
      <li className={style.labGridCell}>
        <div className={style.labTitleCell}>Flag</div>
        <LabFlag flag={getFlagValue(flag)} />
      </li>
      <li className={style.labGridCell}>
        <div className={style.labTitleCell}>Range</div>
        <span className={style.labReference}>{reference}</span>
      </li>
      <li className={style.labGridCell}>
        <div className={style.labTitleCell}>Com</div>
        <span className={style.labDescription}>{description}</span>
      </li>
      <li className={style.labGridCell}>
        <div className={style.labTitleCell}>Date</div>
        <span className={style.labDate}>{dayjs(creationDate).format('DD.MM.YYYY')}</span>
      </li>
    </ul>
  );
};

LabValueListItem.propTypes = {
  labValueItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    category: PropTypes.shape({
      title: PropTypes.string.isRequired,
      unit: PropTypes.string.isRequired,
    }),
    value: PropTypes.number.isRequired,
    flag: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
  }),
};

export default React.memo(LabValueListItem);
